import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import './assets/css/style.css'
import './assets/css/components.css'
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Switch, withRouter,Redirect } from "react-router-dom";
import Login from './Componet/Login/Login'
import Header from './Componet/Header/Header';
import { ToastContainer } from 'react-toastify';
import PrivetRoute from './Commoncomponet/PrivetRoute';
import Deshboard from './Componet/Deshboard/Deshboard';
import AddUser from './Componet/User/AddUser';
import AddGsm from './Componet/GSM/AddGsm';
import AddAccount from './Componet/Profiles/AddProfiles/AddAccount';
import User from './Componet/User/User';
import Account from './Componet/Profiles/Account';
import Ga from './Componet/Profiles/Ga';
import Circle from './Componet/Profiles/Circle';
import Division from './Componet/Profiles/Division';
import Subdevision from './Componet/Profiles/Subdevision';
import Area from './Componet/Profiles/Area';
import AddGa from './Componet/Profiles/AddProfiles/AddGa';
import AddCircle from './Componet/Profiles/AddProfiles/AddCircle';
import AddDivision from './Componet/Profiles/AddProfiles/AddDivision';
import AddSubdivison from './Componet/Profiles/AddProfiles/AddSubdevision';
import AddArea from './Componet/Profiles/AddProfiles/AddArea';
import All from './Componet/Modem/All';
import Firmware from './Componet/Modem/Firmware';
import Hardwarev from './Componet/Modem/Hardwarev';
import Make from './Componet/Modem/Make';
import ModelNumber from './Componet/Modem/ModelNumber';
import SKU from './Componet/Modem/SKU';
import Type from './Componet/Modem/Type';
import AddFirmware from './Componet/Modem/AddModem/AddFirmware';
import AddHardware from './Componet/Modem/AddModem/AddHardware';
import AddMake from './Componet/Modem/AddModem/AddMake';
import AddmodelNumber from './Componet/Modem/AddModem/AddmodelNumber';
import Addsku from './Componet/Modem/AddModem/Addsku';
import AddType from './Componet/Modem/AddModem/AddType';
import AddAll from './Componet/Modem/AddModem/AddAll';
import Allmeter from './Componet/Meter/All'
import Firmwaremeter from './Componet/Meter/Firmware';
import Hardwarevmeter from './Componet/Meter/Hardware';
import Makemeter from './Componet/Meter/Make';
import ModelNumbermeter from './Componet/Meter/MeterNumber';
import Typemeter from './Componet/Meter/Type';
import Softwaremeter from './Componet/Meter/Software';
import Categorymeter from './Componet/Meter/Category';
import AddCategory from './Componet/Meter/Addmeter/AddCategory';
import AddSoftwaremeter from './Componet/Meter/Addmeter/AddSoftware';
import AddTypemeter from './Componet/Meter/Addmeter/Addtype';
import AddModelNumbermeter from './Componet/Meter/Addmeter/Addmetrenumber';
import AddMakemeter from './Componet/Meter/Addmeter/Addmake';
import AddHardwarevmeter from './Componet/Meter/Addmeter/AddHardware';
import AddFirmwaremeter from './Componet/Meter/Addmeter/AddFirmware';
import AddAllmeter from './Componet/Meter/Addmeter/Addall'
import AddScriptcodeId from './Componet/Scriptcodeid/AddScriptcodeId';
import Scriptcodeid from './Componet/Scriptcodeid/Scriptcodeid';
import Roles from './Componet/Roles/Roles';
import Gsm from './Componet/GSM/Gsm';
import GSM_deshboard from './Componet/GSM/GSM_deshboard';
import AddRoles from './Componet/Roles/AddRoles';
import Class from './Componet/Meter/Class';
import AddClass from './Componet/Meter/Addmeter/AddClass'
import Lora from './Componet/Lora/Lora';
import AddLora from './Componet/Lora/AddLora';
import ServiceProfile from './Componet/ServiceProfile/ServiceProfile';
import AddserviceProfile from './Componet/ServiceProfile/AddserviceProfile';
import Lora_daeshboard from './Componet/Lora/Lora_deshboard';
import Reports, { ReportHistory } from './Componet/Reports/Reports';
import Userprofiles from './Componet/Userprofiles/Userprofile';
import moment from 'moment';
import Tags from './Componet/Tags/Tags';
import Addtags from './Componet/Tags/Addtags';
import GSMmap from './Componet/Mapview/GSMmap';
import Gsm_buffer from './Componet/Gsm_buffer/Gsm_buffer';
import CreateTable from './Componet/CreateTable/CreateTable';
import Tasks from './Componet/Tasks/Tasks';
import ReleaseNotes from './Componet/ReleaseNotes/ReleaseNotes';
import AddReleaseNotes from './Componet/ReleaseNotes/AddReleaseNotes';
import ScripList from './Componet/ScripList/ScripList';
import Mobileapp from './Componet/GSM/Mobileapp/Mobileapp';
import Wimesh from './Componet/GSM/Wimesh/Wimesh';
import Polling from './Componet/GSM/polling/polling';
import AddModemDetails from './Componet/GSM/AddModemDetails';
import AddModemDetailsLora from './Componet/Lora/AddModemDetails';
import ServerComm from './Componet/Modem/ServerComm';
import AddServerComm from './Componet/Modem/AddModem/AddServerComm';
import Lora_group_dashboard from './Componet/Deshboard/Lora/Lora_dashboard'
import LorawanReports from './Componet/Reports/LorawanReports';
import Integrations from './Componet/Integration/Integrations';
import MsSqlIntegration from './Componet/Integration/MSSQL/MsSql_integration'
import MainTaskPage from './Componet/Task2/MainTaskPage';
// import MainTaskPageTest from './Componet/Task1/MainTaskPage';
import ApiConsole from './Componet/API/ApiConsole';
import ApiList from './Componet/API/ApiList';
import ShowApi from './Componet/API/ShowApi';
import LorawanMap from './Componet/Mapview/LorawanMap';
import ApiHistory from './Componet/API/ApiHistory';
import ShowDbInfo from './Componet/Admin/ShowDbInfo';
import AttachmentList from './Componet/Admin/AttachmentList';
import Allmechanicalmeter from './Componet/MechanicalMeter/All'
import mechanicalmeterClass from './Componet/MechanicalMeter/Class'
import mechanicalmeterType from './Componet/MechanicalMeter/Type'
import mechanicalmeterManufaturer from './Componet/MechanicalMeter/Manufaturer'
import mechanicalmeterBrand from './Componet/MechanicalMeter/Brand'
import mechanicalmeterModalNumber from './Componet/MechanicalMeter/ModalNumber'
import mechanicalmeterCategory from './Componet/MechanicalMeter/Category'
import Add_All from './Componet/MechanicalMeter/AddMechanicalMeter/Add_All';
import Add_class from './Componet/MechanicalMeter/AddMechanicalMeter/Add_class';
import Add_Type from './Componet/MechanicalMeter/AddMechanicalMeter/Add_Type';
import Add_Brand from './Componet/MechanicalMeter/AddMechanicalMeter/Add_Brand';
import Add_Manufacturer from './Componet/MechanicalMeter/AddMechanicalMeter/Add_Manufacturer';
import Add_ModalNumber from './Componet/MechanicalMeter/AddMechanicalMeter/Add_ModalNumber';
import Add_Category from './Componet/MechanicalMeter/AddMechanicalMeter/Add_Category';
import Group from './Componet/UsersGroup.js/Group';
import Notify from './Componet/Notification/Notify';
import AddNotifyReceiver from './Componet/Notification/AddNotifyReceiver';
import LoginWithOTP from './Componet/Login/LoginWithOTP';
import WebTabs from './Componet/WebTabs/WebTabs';
import GatewayList from './Componet/Admin/GatewayList';
import BadDataAnalytice from './Componet/DataAnalytice/BadDataAnalytice';
// import ResourceManagement from './Componet/ResourceManagement/ResourceManagement';
import GSTMAll from './Componet/GSM_Smart_Meter/All';
import AddGsmSmartMeterMake from './Componet/GSM_Smart_Meter/Addmeter/Addmake';
import AddGsmSmartMetertype from './Componet/GSM_Smart_Meter/Addmeter/Addtype';
import AddGsmSmartMeterDriver from './Componet/GSM_Smart_Meter/Addmeter/Addall';
import AddGsmSmartMeterClass from './Componet/GSM_Smart_Meter/Addmeter/AddClass';
import AddGsmSmartMeterCategory from './Componet/GSM_Smart_Meter/Addmeter/AddCategory';
import AddGsmSmartMeterModelNumber from './Componet/GSM_Smart_Meter/Addmeter/Addmetrenumber';
import AddGsmSmartMeterFirmaware from './Componet/GSM_Smart_Meter/Addmeter/AddFirmware';
import AddGsmSmartMeterHWProtocol from './Componet/GSM_Smart_Meter/Addmeter/AddHardware';
import AddGsmSmartMeterSWProtocol from './Componet/GSM_Smart_Meter/Addmeter/AddSoftware';
import GSMSmartMeterConsumer from './Componet/GSMSmartMeterConsumer/GSMSmartMeterConsumer';
import AddGSTMConsumer from './Componet/GSMSmartMeterConsumer/AddGSTMConsumer';
import GSMSmartMeterdeshboard from './Componet/GSMSmartMeterConsumer/GSMSmartMeterdeshboard';
import GSMSmartMeterDashboard from './Componet/Deshboard/GSMSmartMeterDashboard';
import DashboardDbinfo from './Componet/Admin/DashboardDbinfo';
import GSM_Main_Dashboard from './Componet/Deshboard/GSM/GSM_Main_Dashboard';
import { LoraAnalytics } from './Componet/Deshboard/Lora/LoraAnalytics';
import Lora_Main_Dashboard from './Componet/Deshboard/Lora/Lora_Main_Dashboard';
import { AllConsumer } from './Componet/AllConsumer/AllConsumer';
import GsmReportsIndex from './Componet/Reports/GsmReportsIndex';
import DataAnalytice from './Componet/DataAnalytice/DataAnalytice';
import GSMSmartMeterReport from './Componet/Reports/GSMSmartMeterReport';
import DownloadGSMReport from './Componet/Reports/DownloadGSMReport';

function App(props) {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: " %d seconds ago",
      ss: "%d seconds ago",
      m: "%d min ago",
      mm: "%d min ago",
      h: "an hours ago",
      hh: "%d hours ago",
      d: "a day ago",
      dd: "%d days ago",
      w: "a week ago",
      ww: "%d weeks ago",
      M: "a month ago",
      MM: "%d months ago",
      y: "a year ago",
      yy: "%d years ago"
    }
  });
  moment.relativeTimeThreshold('m', 60)
  moment.relativeTimeThreshold('s', 60)
	const userInfo=JSON.parse(window.localStorage.getItem('user_info'))||{}

 const [manuPermission,setmanuPermission]=useState([])
 const [isRouteLoading,setIsRouteLoading]=useState(true)

 const onHandalSwitch = (key) => {
  if(Object.keys(manuPermission||{}).length){
      var obj_flag=false
  const updateData = (items) => items?.map(item => {
      if (item?.id === key&&item?.view==true) {
          obj_flag=true
          return item.view
      }
      if (item.children) {
          return updateData(item.children) 
      }
      return item.view;
  });
      updateData(manuPermission)
 return obj_flag
}
};

useEffect(()=>{
  console.log(manuPermission)
},[manuPermission])
  return (
    <>
      {props.history.location.pathname === '/'|| props.history.location.pathname ==='/login' || props.history.location.pathname === '/gsm-map-view' || props.history.location.pathname === '/lorawan-map-view'||props.history.location.pathname ==='/mail-report-download' ? "" : <Header setmanuPermission={(info)=>setmanuPermission(info)} manuPermission={manuPermission} setIsRouteLoading={setIsRouteLoading}/>}
     <Switch>
        {/* Login With OTP  */}
        <Route exact path="/login" component={withRouter(LoginWithOTP)} />

        <Route exact path="/" component={withRouter(Login)} />
        <Route path="/mail-report-download" component={withRouter(DownloadGSMReport)} exact />
        {/* dashboard */}
        <PrivetRoute path="/all-dashboard" Component={withRouter(Deshboard)} exact />
       
        {!isRouteLoading||userInfo?.ROLE=='ROLE1650614500708'?<Switch>
        { (onHandalSwitch('MENU11_2')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path="/add-users" Component={withRouter(AddUser)} exact />}
        {(onHandalSwitch('MENU11_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/user" Component={withRouter(User)} exact />}
        {/* GSM */}
        { (onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/add-gsm-modem" Component={withRouter(AddGsm)} exact />}
        {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/All-GSM' Component={withRouter(Gsm)} exact />}
        {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/view_existing_consumer_gsm/:GSMID' Component={withRouter(GSM_deshboard)} exact />}
        {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path='/AddModemDetails' Component={withRouter(AddModemDetails)} exact />}
        {/* GSM */}
        {(onHandalSwitch('MENU3_1')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path="/All-Consumer-List" Component={withRouter(AllConsumer)} exact />}
        {/* Lorawan */}
        {(onHandalSwitch('MENU3_3')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/All-Lorawan' Component={withRouter(Lora)} exact />}
        {(onHandalSwitch('MENU3_3')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/add-Lorawan-modem" Component={withRouter(AddLora)} exact />}
        {(onHandalSwitch('MENU3_3')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/view_existing_consumer_lorawan/:LoraID' Component={Lora_daeshboard} exact />}
        {(onHandalSwitch('MENU3_3')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/AddModemDetailsInLorawan' Component={withRouter(AddModemDetailsLora)} exact />}

        {/* Profile */}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/add-account" Component={withRouter(AddAccount)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/add-ga/:accountId" Component={withRouter(AddGa)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path="/add-circle/:accountId/:gaId" Component={withRouter(AddCircle)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/add-division/:accountId/:gaId/:circleId" Component={withRouter(AddDivision)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/add-subdivision/:accountId/:gaId/:circleId/:divisionId" Component={withRouter(AddSubdivison)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/add-Area/:accountId/:gaId/:circleId/:divisionId/:subdivisionId" Component={withRouter(AddArea)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/account" Component={withRouter(Account)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path="/ga/:accountId" Component={withRouter(Ga)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/circle/:accountId/:gaId" Component={withRouter(Circle)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/division/:accountId/:gaId/:circleId" Component={withRouter(Division)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/subdivision/:accountId/:gaId/:circleId/:divisionId" Component={withRouter(Subdevision)} exact />}
        {(onHandalSwitch('MENU2')||userInfo?.ROLE=='ROLE1650614500708')&&  <PrivetRoute path="/area/:accountId/:gaId/:circleId/:divisionId/:subdivisionId" Component={withRouter(Area)} exact />}
        {/* Modem */}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/allmodem" Component={withRouter(All)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/modemfirmware" Component={withRouter(Firmware)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/modemhardwarev" Component={withRouter(Hardwarev)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/modemmake" Component={withRouter(Make)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/modemmodelnumber" Component={withRouter(ModelNumber)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/modemsku" Component={withRouter(SKU)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/modemtype" Component={withRouter(Type)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/server_communication_protocol" Component={withRouter(ServerComm)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/add-allmodem" Component={withRouter(AddAll)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/add-firmware" Component={withRouter(AddFirmware)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/add-Hardware" Component={withRouter(AddHardware)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/add-make" Component={withRouter(AddMake)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/add-modelNumber" Component={withRouter(AddmodelNumber)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/add-sku" Component={withRouter(Addsku)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/add-server-communication-protocol" Component={withRouter(AddServerComm)} exact />}                       
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/add-type" Component={withRouter(AddType)} exact />}
        {/* EVC */}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/allEVC" Component={withRouter(Allmeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/EVCfirmware" Component={withRouter(Firmwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/EVChardwarev" Component={withRouter(Hardwarevmeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/EVCmake" Component={withRouter(Makemeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/EVCmodelnumber" Component={withRouter(ModelNumbermeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/EVCtype" Component={withRouter(Typemeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/EVCsoftware" Component={withRouter(Softwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/EVCcategory" Component={withRouter(Categorymeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path="/EVCclass" Component={withRouter(Class)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-allEVC' Component={withRouter(AddAllmeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-EVCcategory' Component={withRouter(AddCategory)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-EVCclass' Component={withRouter(AddClass)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-EVCsoftware' Component={withRouter(AddSoftwaremeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-EVCtype' Component={withRouter(AddTypemeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-EVCmodelnumber' Component={withRouter(AddModelNumbermeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-metermake' Component={withRouter(AddMakemeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-EVChardwarev' Component={withRouter(AddHardwarevmeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-EVCfirmware' Component={withRouter(AddFirmwaremeter)} exact />}

        {/* Mechanical Meter */}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/MechanicalMeter' Component={withRouter(Allmechanicalmeter)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/MechanicalMeterclass' Component={withRouter(mechanicalmeterClass)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/MechanicalMeterType' Component={withRouter(mechanicalmeterType)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/MechanicalMeterBrand' Component={withRouter(mechanicalmeterBrand)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/MechanicalMeterManufacturer' Component={withRouter(mechanicalmeterManufaturer)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/MechanicalMeterModalNo' Component={withRouter(mechanicalmeterModalNumber)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/MechanicalMeterCategory' Component={withRouter(mechanicalmeterCategory)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-MechanicalMeter' Component={withRouter(Add_All)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-MechanicalMeterclass' Component={withRouter(Add_class)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-MechanicalMeterType' Component={withRouter(Add_Type)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-MechanicalMeterBrand' Component={withRouter(Add_Brand)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-MechanicalMeterManufacturer' Component={withRouter(Add_Manufacturer)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-MechanicalMeterModalNo' Component={withRouter(Add_ModalNumber)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-MechanicalMeterCategory' Component={withRouter(Add_Category)} exact />}

{/* GSM Smart Meter */}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/GSM-Smart-Meter-Driver' Component={withRouter(GSTMAll)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-make' Component={withRouter(AddGsmSmartMeterMake)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-type' Component={withRouter(AddGsmSmartMetertype)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-driver' Component={withRouter(AddGsmSmartMeterDriver)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-class' Component={withRouter(AddGsmSmartMeterClass)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-category' Component={withRouter(AddGsmSmartMeterCategory)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-model-number' Component={withRouter(AddGsmSmartMeterModelNumber)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-firmware' Component={withRouter(AddGsmSmartMeterFirmaware)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-hardware-protocol' Component={withRouter(AddGsmSmartMeterHWProtocol)} exact />}
{userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/Add-GSM-Smart-Meter-shoftware-protocol' Component={withRouter(AddGsmSmartMeterSWProtocol)} exact />}

        {/* scripcodeid */}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-scriptcodeid' Component={withRouter(AddScriptcodeId)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/scripcodeid' Component={withRouter(Scriptcodeid)} exact />}
        {/* Roles */}
        {(onHandalSwitch('MENU11_2')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path='/Roles' Component={withRouter(Roles)} exact />}
       {(onHandalSwitch('MENU11_2')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path='/add-Roles' Component={withRouter(AddRoles)} exact />}
        {/* Service Profile */}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/service_provider' Component={withRouter(ServiceProfile)} exact />}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/add-service_provider' Component={withRouter(AddserviceProfile)} exact /> }
        {/* GSM_Deshboard */}
        {(onHandalSwitch('MENU1_2')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path='/gsm_dashboard' Component={withRouter(GSM_Main_Dashboard)} exact />}
        {/* Lora Dashboard */}
        {(onHandalSwitch('MENU1_3')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/Lora_dashboard' Component={withRouter(Lora_Main_Dashboard)} exact />}
        {(onHandalSwitch('MENU1_3')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/LoraAnalytics' Component={withRouter(LoraAnalytics)} exact />}

        {/*Reports */}
        {/* {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/reports' Component={withRouter(Reports)} exact />} */}
        {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/reports' Component={withRouter(GsmReportsIndex)} exact />}
        {(onHandalSwitch('MENU3_3')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/Lorawan-reports' Component={withRouter(LorawanReports)} exact />}

        {/* {(onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/reportHistory' Component={withRouter(ReportHistory)} exact />} */}
        {/* Profile */}
        <PrivetRoute path='/profiles' Component={withRouter(Userprofiles)} exact />
        {/* Tags */}
        {(onHandalSwitch('MENU6')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/tags' Component={withRouter(Tags)} exact />}
        {(onHandalSwitch('MENU6')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/add-tags' Component={withRouter(Addtags)} exact />}
        {/* map */}
        {(onHandalSwitch('MENU1_2')||onHandalSwitch('MENU3_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/gsm-map-view' Component={withRouter(GSMmap)} exact />}
        {(onHandalSwitch('MENU1_3')||onHandalSwitch('MENU3_3')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/lorawan-map-view' Component={withRouter(LorawanMap)} exact />}
        {/* Buffer */}
        {/* <PrivetRoute path='/gsm-buffer' Component={withRouter(Gsm_buffer)} exact /> */}
        {/* Create Table */}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/create-table' Component={withRouter(CreateTable)} exact />}
        {/* Tasks */}
        <PrivetRoute path="/tasks" Component={withRouter(MainTaskPage)} exact />
         {/* Tasks-test */}
         {/* <PrivetRoute path="/Tasks-test" Component={withRouter(MainTaskPageTest)} exact /> */}
        {/* Release Notes */}
        {(onHandalSwitch('MENU8')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path="/release-notes" Component={withRouter(ReleaseNotes)} exact />}
        {(onHandalSwitch('MENU8')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/add-realese-notes' Component={withRouter(AddReleaseNotes)}></PrivetRoute>}
        {/* Script List */}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/scriptlist' Component={withRouter(ScripList)}></PrivetRoute>}
        {/* Mobile app */}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/mobileapp' Component={withRouter(Mobileapp)}></PrivetRoute>}
        {/* Wi-Mesh */}
        {/* <PrivetRoute path='/wimesh' Component={withRouter(Wimesh)}></PrivetRoute> */}
        {/* Polling */}
        {/* <PrivetRoute path='/polling' Component={withRouter(Polling)}></PrivetRoute> */}
        {/* integration */}
        {(onHandalSwitch('MENU7_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/integration' Component={withRouter(Integrations)}></PrivetRoute>}
        {(onHandalSwitch('MENU7_2')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/integrations/mssql-integrations/:MSID' Component={withRouter(MsSqlIntegration)}></PrivetRoute>}

        {/* API */}
        {(onHandalSwitch('MENU7_1')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/api/api-console' Component={withRouter(ApiConsole)}></PrivetRoute>}
        {(onHandalSwitch('MENU7_1')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/api/documentationList' Component={withRouter(ApiList)}></PrivetRoute>}
        {(onHandalSwitch('MENU7_1')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/api/documentation' Component={withRouter(ShowApi)}></PrivetRoute>}
        {(onHandalSwitch('MENU7_1')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path='/api/api-history/:UNIQUE_ID' Component={withRouter(ApiHistory)}></PrivetRoute>}

        {/* database all table */}

        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/admin/dbDetails' Component={withRouter(DashboardDbinfo)}></PrivetRoute>}
        {userInfo?.ROLE=='ROLE1650614500708'&&<PrivetRoute path='/admin/AttachmentList' Component={withRouter(AttachmentList)}></PrivetRoute>}

      {/* users group */}
      {(onHandalSwitch('MENU11_3')||userInfo?.ROLE=='ROLE1650614500708')&& <PrivetRoute path='/UsersGroup' Component={withRouter(Group)}></PrivetRoute>}

      {/* Notification */}
      {(onHandalSwitch('MENU5')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/Notification' Component={withRouter(Notify)}></PrivetRoute>}
      {(onHandalSwitch('MENU5')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/AddNotifyReceiver' Component={withRouter(AddNotifyReceiver)}></PrivetRoute>}
{/* Web Tabs */}
{(onHandalSwitch('MENU9')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/Web-Tabs' Component={withRouter(WebTabs)}></PrivetRoute>}
{/* Gatewaylist */}
{(userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/admin/GatewayList' Component={withRouter(GatewayList)}></PrivetRoute>}
{/* bad data analytice */}
{(onHandalSwitch('MENU10')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/BadDataAnalytics' Component={withRouter(DataAnalytice)} />}
{/* bad data analytice */}
{/* <PrivetRoute path='/resources-management/resources' Component={withRouter(ResourceManagement)} /> */}

{/* gsm smart meter Consumer */}
{(onHandalSwitch('MENU3_4')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/All-GSM-Smart-Meter-Consumer' Component={withRouter(GSMSmartMeterConsumer)} />}
{(onHandalSwitch('MENU3_4')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/Add-GSM-Smart-Meter-Consumer' Component={withRouter(AddGSTMConsumer)} />}
{(onHandalSwitch('MENU3_4')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/GSM-Smart-Meter-Dashboard' Component={withRouter(GSMSmartMeterDashboard)} />}
{(onHandalSwitch('MENU3_4')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/view_existing_consumer_gsm_smart_meter/:GSMID' Component={withRouter(GSMSmartMeterdeshboard)} />}
{(onHandalSwitch('MENU3_4')||userInfo?.ROLE=='ROLE1650614500708')&&<PrivetRoute path='/gsm-smart-meter-report' Component={withRouter(GSMSmartMeterReport)} />}
        <Redirect from="*" to="/" />
        </Switch>:<div className="main-content">
						<div class="loader"></div>
					</div>}
      </Switch>
      <ToastContainer position={"top-right"} autoClose={3000} limit={4} />
    </>
  );
}

export default withRouter(App);
