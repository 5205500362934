import React, { useState } from 'react';
import Cron from './lib';
import { HEADER } from './lib';

const cronOptions = {
  headers: [HEADER.DAILY,HEADER.WEEKLY,HEADER.MONTHLY],
};

function CronIndex({onChange,value,showResultText,showResultCron,options}) {
  const [state, setState] = useState({});

  return (
    <>
        <Cron
          onChange={(e, text,type) => {
            onChange(e, text,type)
          }}
          value={value}
          showResultText={showResultText}
          showResultCron={showResultCron}
          options={cronOptions}
        />
     </>
  );
}

export default CronIndex;