import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { getAccount, getAllMeter, getAreaById, getCircleById, getDivisionById, getGaById, getMeterDashGSMBrtRtcDriffChart, getMeterDashGSMBrtRtcDriffChartSelectByDate, getMeterDashGSMChart, getMeterDashGSMInstantaneousChart, getMeterDashGSMInstantaneousChartBySelectDate, getMeterGSMChartByDateSlection, getProfilNameByAccountId, getRolesById, getStartAndEndDateByTable, getSubdevisionById, gsm_monitor_day_wise_graph_details, MeterDailyDataScripUpdate, MonitoringScriptStatus, onGetLetestTime, getMeterDashGSMTemperCount, onGetConsumerIncolumeName, getAllGSMConsumptionChartByDate, getAllGSMConsumptionChartBySelectDate, UpdateDashboardAddCustomCard, getGsmCustomDashboadList, getSaleSummaryTableData, getAllGSMHourlyConsumptionChartByDate, MeterHourlyDataScripUpdate, HourlyMonitoringScriptStatus, onGetHourlyLetestTime, getMeterGSMDataReceptionDateWise, UserDashboardCardFilterStore } from "../../../Commoncomponet/Utils";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	InfoWindow
} from "react-google-maps"
import mapicon from '../../../assets/img/triangle-green.svg'
import mapRedicon from '../../../assets/img/triangle-red.svg'
import { red } from "@mui/material/colors";
import { padding } from "@mui/system";
import { DatePicker, Modal, Popover, Space, Spin, TreeSelect } from "antd";
import '../Dashboard.css'
import Select from 'react-select';
import { BootstrapTooltip, exportCSVFile, exportExcelDownload, socket } from "../../../Commoncomponet/common";
import loaderGif from '../../../assets/img/loading_chart.gif'
import jQuery from 'jquery'
import { useRef } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import $ from 'jquery'
import { Bar, getElementsAtEvent, getElementAtEvent, getDatasetAtEvent, Pie, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useMemo } from "react";
import makeAnimated from 'react-select/animated';
import { Info } from "react-feather";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import card_json from './GSM_Card.json'
import exports from '../../../assets/img/export.png'
import HTMLReactParser from "html-react-parser";
import MultiInput from "../../../Commoncomponet/MultiInput/MultiInput";
import html2canvas from "html2canvas";
import DownloadGraphImageComponet from "../DownloadGraphImageComponet";
import { jsPDF } from "jspdf";
import { FaClock } from "react-icons/fa";
import { PiBatteryVerticalHighLight, PiBatteryVerticalLowLight } from "react-icons/pi";
import { TbClockExclamation } from "react-icons/tb";
import { LuAlertTriangle } from "react-icons/lu";
import { TbAlertTriangleOff } from "react-icons/tb";
import { IoMdTime } from "react-icons/io";
const { SHOW_PARENT } = TreeSelect;
window.Swal = swal;
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	zoomPlugin, ChartDataLabels,
	Decimation, TimeScale
);


export default function GSM_meter_dashboard(props) {
	const { AllCategory, DashTabDetailById, activeTab, tringerMeter, settringerMeter, setProfile, Profile, AccounData, GaData, CircleData, DivisionData, SubdevisionData, AreaData, AllCategoryJson, onGetFilterDaywise, FilterDay, setFilterDay, setisRefDownload, isRefDownload, isDownloadBtnShow } = props
	const chartRef = useRef();
	const chartRefINS = useRef();
	const chartConsumptionRef = useRef();
	const chartQRef = useRef();
	const chartQMINRef = useRef();
	const chartQAVGRef = useRef();
	const pieDailydata = useRef();
	const pieDailyConsumptiondata = useRef();
	const dailyConsmptionCategory = useRef();
	const HourlyConsumptionRef = useRef();
	const IMGCARD21 = useRef();
	const IMGCARD22 = useRef();
	const IMGCARD23 = useRef();
	const IMGCARD24 = useRef();
	const IMGCARD25 = useRef();
	const IMGCARD26 = useRef();
	const IMGCARD27 = useRef();
	const IMGCARD28 = useRef();
	const IMGCARD29 = useRef();
	const IMGCARD30 = useRef();
	const IMGCARD31 = useRef();
	const GSMDATList = ['DAILY_SUCCESS_LIST', 'DAILY_FAIL_LIST', 'INACTIVE_CONS_LIST', 'UNDER_MAINTANANCE_CONS_LIST', 'PENDING_CONFIGURATION_CONS_LIST']
	const GSMDATAINSList = ['INSTANTANEOUS_SUCCESS_LIST', 'INSTANTANEOUS_FAIL_LIST']
	// const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" });

	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const [permision, setpermision] = useState({});
	const [AllGSMChartData, setAllGSMChartData] = useState({ daily_success: [], daily_fail: [], SRNO: [] });
	const [GSMChartData, setGSMChartData] = useState({ daily_success: [], daily_fail: [], SRNO: [] });
	const [CateDataRece, setCateDataRece] = useState({});
	const [InstantaneousChartData, setInstantaneousChartData] = useState({ daily_success: [], daily_fail: [], SRNO: [] })
	const [loader, setLoader] = useState(false);
	const [dateInLong, setDateInLong] = useState([]);
	const [expireDate, setExpireDate] = useState({})
	const [isExpandedModalOpen, setisExpandedModalOpen] = useState(false)
	const [expandedModelHeader, setExpandedModelHeader] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [ModelId, setModelId] = useState('')
	const [TableData, setTableDataInApi] = useState([])
	const [ColorText, setColorText] = useState('')
	const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
	const [ismodel, setModel] = useState(false)
	const [reportType, setReportType] = useState('')
	const [ReportLoader, setReportLoader] = useState(false)
	const [ModalLoad, setModalLoad] = useState(false)
	const [BATRTC, setBATRTC] = useState({})
	const [BATRTCLoader, setBATRTCLoader] = useState(false)
	const [varSetting, setvarSetting] = useState({ BAT: 5, RTC: 10 })
	const [ModemData, setModemData] = useState([])
	const [GenrateBtn, setGenrateBtn] = useState(true)
	const [TAMPER_COUNT, setTAMPER_COUNT] = useState({ count: 0 })
	const [isConsumptionModal, setisConsumptionModal] = useState(false)
	const [ConsumptionDate, setConsumptionDate] = useState(moment().add(-1, 'days').format('DD-MM-YYYY'))
	const [ConsumptionDateanality, setConsumptionDateanality] = useState(moment().add(-1, 'days').format('DD-MM-YYYY'))
	const [DailyDataDate, setDailyDataDate] = useState(moment().format('DD-MM-YYYY'))
	const [ConsumptionChartData, setConsumptionChartData] = useState({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [] })
	const [ConsumptionPie, setConsumptionPie] = useState({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [] })
	const [ConsumptionPieanality, setConsumptionPieanality] = useState({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [] })
	const [QChartData, setQChartData] = useState({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [] })
	const [HourConsumptionData, setHourConsumptionData] = useState({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [] })
	const [ValueCharCon, setValueCharCon] = useState({ isGreter: 1000, Between1: { to: 100, from: 1000 }, Between2: { to: 0, from: 100 } })
	const [Modal_card_name, setModal_card_name] = useState('')
	const [isDashModal, setisDashModal] = useState(false);
	const [card_id, setcard_id] = useState('')
	const [DashTabs, setDashTabs] = useState([])
	const [DashLoader, setDashLoader] = useState(false)
	const [saleData, setSaleData] = useState({})
	const [saleDate, setsaleDate] = useState(moment().add(-1, 'days').format('DD-MM-YYYY'))
	const [chartType, setchartType] = useState({})
	const [TreeSelection, setTreeSelection] = useState([])
	const [DataReceDate, setDataReceDate] = useState(moment().format('DD-MM-YYYY'))
	const [CategoryData, setCategoryData] = useState({})
	const [CategoryName, setCategoryName] = useState({ name: [], ids: [], tree_cat: [] })
	const [CategorySearch, setCategorySearch] = useState({ name: [], ids: [], tree_cat: [] })
	const [dataRecepLoader, setdataRecepLoader] = useState(false)
	const [CardBar, setCardBar] = useState([])
	const [CardFilter, setCardFilter] = useState({})
	const [DefaultShowBar, setDefaultShowBar] = useState({})
	const [dataForChartJs, setdataForChartJs] = useState({
		labels: dateInLong.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "DailyData Success",
				data: AllGSMChartData?.daily_success,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
				borderColor: 'rgb(0, 104, 138)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "DailyData Fail",
				data: AllGSMChartData?.daily_fail,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
				borderColor: 'rgb(51, 181, 229)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "Inactive",
				data: AllGSMChartData?.inactive_count,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
				borderColor: 'rgb(255, 69, 96)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "Under Maintanance",
				data: AllGSMChartData?.undermantan_count,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 143, 251)',
				borderRadius: 5,
				borderColor: 'rgb(0, 143, 251)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "Pending Configuration",
				data: AllGSMChartData?.pendding_count,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(254, 176, 25)',
				borderRadius: 5,
				borderColor: 'rgb(254, 176, 25)',
				pointRadius: 5,
				pointHoverRadius: 7
			}
		],
	});

	const [ConsumptionChartJs, setConsumptionChartJs] = useState({
		labels: dateInLong.map((item) => (moment(item, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY (ddd)'))),
		datasets: [
			{
				label: "Consumption",
				data: ConsumptionChartData?.CONSUMPTION,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
				borderColor: 'rgb(0, 104, 138)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "0 Consumption (Count)",
				data: ConsumptionChartData?.CONSUMPTION0,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
				borderColor: 'rgb(51, 181, 229)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "Null Data (Count)",
				data: ConsumptionChartData?.CONSUMPTION_NULL,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
				borderColor: 'rgb(255, 69, 96)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
		]
	})

	const [dataForINS, setdataForINS] = useState({
		labels: InstantaneousChartData?.date?.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "Instantaneous Success",
				data: InstantaneousChartData?.instantaneous_success,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
				borderColor: 'rgb(0, 104, 138)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "Instantaneous Fail",
				data: InstantaneousChartData?.instantaneous_fail,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
				borderColor: 'rgb(51, 181, 229)',
				pointRadius: 5,
				pointHoverRadius: 7
			}
		],
	})

	const [QMAXChartJs, setQMAXChartJs] = useState({
		labels: dateInLong.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "QMIN",
				data: QChartData?.QMIN_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
				borderColor: 'rgb(0, 104, 138)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "0 QMIN (Count)",
				data: QChartData?.QMIN_0_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
				borderColor: 'rgb(51, 181, 229)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "Null Data IN QMIN (Count)",
				data: QChartData?.QMIN_NULL_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
				borderColor: 'rgb(255, 69, 96)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
		]
	})

	const [QAVGChartJs, setQAVGChartJs] = useState({
		labels: dateInLong.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [

			{
				label: "QAVG",
				data: QChartData?.QAVG_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
				borderColor: 'rgb(0, 104, 138)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "0 QAVG (Count)",
				data: QChartData?.QAVG_0_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
				borderColor: 'rgb(51, 181, 229)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			{
				label: "Null Data IN QAVG (Count)",
				data: QChartData?.QAVG_NULL_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
				borderColor: 'rgb(255, 69, 96)',
				pointRadius: 5,
				pointHoverRadius: 7
			}
		]
	})

	const handleCancelIsmodal = () => {
		setModel(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setTableDataInApi([])
	};
	const onHandaleViewGSM = (id) => {
		window.open(`/view_existing_consumer_gsm/${id}`, '_blank')
	}

	const onLoadCosutomDashboardList = async (info) => {

		const data = await getGsmCustomDashboadList()
		if (data.success == true) {
			const dashtab = data?.data?.data?.filter((item) => (info?.CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id) || userInfo?.ROLE == 'ROLE1650614500708'))
			setDashTabs(dashtab)
		} else {
			console.log('something went wrong')
		}
	}
	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({
				...obj,
				ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
				ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
				ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
				ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
				ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
				ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
				ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
			});
			const permissionViewCard = JSON.parse(obj?.ROLES_OTHER_PERMISSIONS)
			const infoRolesObj = { ROLES: userInfo?.ROLE, Profile: { CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0).CATEGORY0_view, CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1).CATEGORY1_view, CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2).CATEGORY2_view, CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3).CATEGORY3_view, CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4).CATEGORY4_view, CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5).CATEGORY5_view } }
			const view_json = JSON.parse(DashTabDetailById?.view_json || '{}')

			onLoadCosutomDashboardList(permissionViewCard)
			if (activeTab != 'Custom Dashboard' || view_json[card_json[21]?.U_ID]?.display == true) {
				const day_number = FilterDay?.filter(item => item?.card_id == card_json[21]?.U_ID)[0]?.day_preference || 7
				ongetAllGSMChartByDate({ ...infoRolesObj, day_number: activeTab == 'Custom Dashboard' ? day_number : 7 })
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[22]?.U_ID]?.display == true) {
				const day_number = FilterDay?.filter(item => item?.card_id == card_json[22]?.U_ID)[0]?.day_preference || 7
				ongetAllGSMInstantaneousChartByDate({ ...infoRolesObj, day_number: activeTab == 'Custom Dashboard' ? day_number : 7 })
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[19]?.U_ID]?.display == true || view_json[card_json[18]?.U_ID]?.display == true) {
				ongetMeterDashGSMBrtRtcDriffChart(infoRolesObj, varSetting?.BAT, varSetting?.RTC)
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[20]?.U_ID]?.display == true) {
				ongetMeterDashGSMTamperCount(infoRolesObj)
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[23]?.U_ID]?.display == true) {
				const day_number = FilterDay?.filter(item => item?.card_id == card_json[23]?.U_ID)[0]?.day_preference || 7
				ongetAllGSMConsumptionChartByDate({ ...infoRolesObj, day_number: activeTab == 'Custom Dashboard' ? day_number : 7 })
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[24]?.U_ID]?.display == true) {
				onLoadDailyData(infoRolesObj)
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[26]?.U_ID]?.display == true) {
				onLoadConsumptionData(infoRolesObj)
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[25]?.U_ID]?.display == true) {
				onLoadConsumptionDataPieChart(infoRolesObj)
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[30]?.U_ID]?.display == true) {
				ongetSaleSummaryTableData({ ...infoRolesObj, start: moment(moment(saleDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY'), 'DD-MM-YYYY').add(-15, 'day').format('DD-MM-YYYY'), end: moment(saleDate, "DD-MM-YYYY").add(1, 'day').format('DD-MM-YYYY') })
			}
			if (activeTab != 'Custom Dashboard' || view_json[card_json[31]?.U_ID]?.display == true) {
				await ongetAllGSMHourlyConsumptionChart(infoRolesObj)
			}
			props?.setisDownloadBtnShow(true)

		}
	}

	const ongetSaleSummaryTableData = async (info) => {
		setSaleData({})
		const data = await getSaleSummaryTableData({ ...info })
		if (data.success === true) {
			setSaleData(data?.data?.data)
		} else {
			console.log('somthing went wrong')
		}
	}

	const ongetAllGSMHourlyConsumptionChart = async (info) => {
		setHourConsumptionData({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [], loader: true })
		const data = await getAllGSMHourlyConsumptionChartByDate({ ...info })
		if (data.success === true) {
			const ChartArray = data.data.data;

			setHourConsumptionData({ CONSUMPTION: ChartArray.map((item) => (item.CONSUMPTION)), CONSUMPTION0: ChartArray.map((item) => (item.CONSUMPTION0)), CONSUMPTION_NULL: ChartArray.map((item) => (item.CONSUMPTION_NULL)), date: ChartArray.map((item) => ([item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY hh:mm:ss A') : item.CREATED_AT])), SRNO: ChartArray.map((item) => (item.SRNO)), loader: false })
		} else {
			setHourConsumptionData({ ...HourConsumptionData, date: [], loader: false })
			console.log('somthing went wrong')
		}
	}

	const ongetRolesUserData = async () => {
		const data = await getAllMeter()
		if (data.success === true) {
			setModemData(data.data.data)
		} else {
			console.log('somthing went wrong')
		}
	}

	useEffect(() => {
		jQuery.event.special.touchstart = {
			setup: function (_, ns, handle) {
				if (ns.includes("noPreventDefault")) {
					this.addEventListener("touchstart", handle, { passive: false });
				} else {
					this.addEventListener("touchstart", handle, { passive: true });
				}
			}
		};
		ongetRolesUserData()
		onHandaleRoles()
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		})
	}, []);

	useEffect(() => {
		console.log(permision)
		if (Object.keys(permision || {}).length && Object.keys(AllCategoryJson || {}).length) {
			const tree_obj = AllCategoryJson
			var obj_array = []
			var obj_DateReception = []
			var caregory_tree = []
			tree_obj?.category0?.map((cat0, id0) => {
				var obj0 = {
					value: cat0.UNIQUE_ID,
					title: <b>{cat0?.CATEGORY0}</b>,
					name: cat0?.CATEGORY0,
					children: []
				}
				if (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => items === cat0.UNIQUE_ID).length > 0 || userInfo?.ROLE === 'ROLE1650614500708') {
					tree_obj?.category1?.map((cat1, id1) => {
						if (permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => items === cat1.CATEGORY1_UNIQUE_ID).length > 0 || userInfo?.ROLE === 'ROLE1650614500708') {
							var obj1 = {
								value: cat1.CATEGORY1_UNIQUE_ID,
								title: cat1?.CATEGORY1_NAME,
								name: cat1?.CATEGORY1_NAME,
								children: []
							}
							if (cat1.CATEGORY0 == cat0?.UNIQUE_ID) {
								tree_obj?.category2?.map((cat2, id2) => {
									if (permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => items === cat2.CATEGORY2_UNIQUE_ID).length > 0 || userInfo?.ROLE === 'ROLE1650614500708') {
										var obj2 = {
											value: cat2.CATEGORY2_UNIQUE_ID,
											title: cat2?.CATEGORY2_NAME,
											name: cat2?.CATEGORY2_NAME,
											children: []
										}
										if (cat2.CATEGORY1 == cat1?.CATEGORY1_UNIQUE_ID) {
											tree_obj?.category3?.map((cat3, id3) => {
												if (permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => items === cat3.CATEGORY3_UNIQUE_ID).length > 0 || userInfo?.ROLE === 'ROLE1650614500708') {
													var obj3 = {
														value: cat3.CATEGORY3_UNIQUE_ID,
														title: cat3?.CATEGORY3_NAME,
														name: cat3?.CATEGORY3_NAME,
														children: []
													}
													if (cat3.CATEGORY2 == cat2?.CATEGORY2_UNIQUE_ID) {
														tree_obj?.category4?.map((cat4, id4) => {
															if (permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => items === cat4.CATEGORY4_UNIQUE_ID).length > 0 || userInfo?.ROLE === 'ROLE1650614500708') {
																var obj4 = {
																	value: cat4.CATEGORY4_UNIQUE_ID,
																	title: cat4?.CATEGORY4_NAME,
																	name: cat4?.CATEGORY4_NAME,
																	children: []
																}
																if (cat4.CATEGORY3 == cat3?.CATEGORY3_UNIQUE_ID) {
																	tree_obj?.category5?.map((cat5, id5) => {
																		if (permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => items === cat5.UNIQUE_ID).length > 0 || userInfo?.ROLE === 'ROLE1650614500708') {
																			var obj5 = {
																				value: cat5.UNIQUE_ID,
																				title: cat5?.NAME,
																				name: cat5?.NAME,
																				children: []
																			}
																			if (cat5.CATEGORY4 == cat4?.CATEGORY4_UNIQUE_ID) {

																				obj4.children.push(obj5)
																			}
																		}
																	})
																	obj3.children.push(obj4)
																}
															}
														})
														obj2.children.push(obj3)
													}
												}
											})

											obj1.children.push(obj2)
										}
									}
								})
								obj0.children.push(obj1)

							}
						}
					})
					obj_array.push(obj0)
				}


			})

			setTreeSelection(obj_array)
			var str_cat = []
			function extractNamesFromTree(data, tree_cat = []) {
				return data.map((item) => {
					const { value, name, children } = item;
					const currentTreeCat = [...tree_cat, name];
					if (children && children.length > 0) {
						return extractNamesFromTree(children, currentTreeCat);
					} else {
						return childrenValues.push({ value, name: name, tree_cat: currentTreeCat });
					}
				});
			}
			var childrenValues = []
			// Iterate through the data and find the last child value for each parent
			const result = extractNamesFromTree(obj_array);
			console.log(childrenValues)
			setCategoryName({ name: childrenValues?.map(item => item?.name), ids: childrenValues?.map(item => item?.value), tree_cat: childrenValues?.map(item => item?.tree_cat) })
			setCategorySearch({ name: childrenValues?.map(item => item?.name), ids: childrenValues?.map(item => item?.value), tree_cat: childrenValues?.map(item => item?.tree_cat) })

			var view_json = JSON.parse(DashTabDetailById?.view_json || '{}')
			if (activeTab != 'Custom Dashboard' || view_json[card_json[32]?.U_ID]?.display == true) {
				ongetDataReceptionAnalyticsData({ start: moment(DataReceDate, "DD-MM-YYYY").format("YYYY-MM-DD"), ROLES: userInfo?.ROLE, serach: true, ins_no: Profile.ins_no, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, ids: childrenValues?.map(item => item?.value) })
			}
		}


	}, [AllCategoryJson, permision])
	const ongetAllGSMChartByDate = async (info) => {
		setAllGSMChartData({ daily_success: [], daily_fail: [], SRNO: [] })

		setLoader(true);
		setLoader(false);
		const data = await getMeterDashGSMChart(info || {});
		if (data.success === true) {
			const ChartArray = data.data.data;
			const obj = { daily_success: ChartArray.map((item) => (item?.daily_success || 0)), daily_fail: ChartArray.map((item) => (item?.daily_fail || 0)), inactive_count: ChartArray.map((item) => (item?.inactive_count || 0)), undermantan_count: ChartArray.map((item) => (item?.undermantan_count || 0)), pendding_count: ChartArray.map((item) => (item?.pendding_count || 0)), SRNO: ChartArray.map((item) => (item?.SRNO)) }
			setAllGSMChartData(obj)
			const DateArray = ChartArray.map((item) => ([item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT]))
			setDateInLong(DateArray);
			const view_json = JSON.parse(DashTabDetailById?.view_json || '{}')

			setdataForChartJs({
				labels: DateArray.map((item, id) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`, obj?.daily_success[id] + obj?.daily_fail[id] + obj?.inactive_count[id] + obj?.undermantan_count[id] + obj?.pendding_count[id]])),
				datasets: [
					(view_json[card_json[21]?.U_ID]?.bar_pemission?.includes("DailyData Success") || activeTab != 'Custom Dashboard') && {
						label: "DailyData Success",
						data: obj?.daily_success,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(0, 104, 138)',
						borderRadius: 5,
						borderColor: 'rgb(0, 104, 138)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[21]?.U_ID]?.bar_pemission?.includes("DailyData Fail") || activeTab != 'Custom Dashboard') && {
						label: "DailyData Fail",
						data: obj?.daily_fail,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(51, 181, 229)',
						borderRadius: 5,
						borderColor: 'rgb(51, 181, 229)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[21]?.U_ID]?.bar_pemission?.includes("Inactive") || activeTab != 'Custom Dashboard') && {
						label: "Inactive",
						data: obj?.inactive_count,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(255, 69, 96)',
						borderRadius: 5,
						borderColor: 'rgb(255, 69, 96)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[21]?.U_ID]?.bar_pemission?.includes("Under Maintanance") || activeTab != 'Custom Dashboard') && {
						label: "Under Maintanance",
						data: obj?.undermantan_count,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(0, 143, 251)',
						borderRadius: 5,
						borderColor: 'rgb(0, 143, 251)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[21]?.U_ID]?.bar_pemission?.includes("Pending Configuration") || activeTab != 'Custom Dashboard') && {
						label: "Pending Configuration",
						data: obj?.pendding_count,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(254, 176, 25)',
						borderRadius: 5,
						borderColor: 'rgb(254, 176, 25)',
						pointRadius: 5,
						pointHoverRadius: 7
					}
				],
			})

		} else {
			console.log("something went wrong");
			setLoader(false);
		}
	};

	const onLoadDailyData = async (info) => {
		setGSMChartData({ daily_success: [], daily_fail: [], SRNO: [] })
		const data = await getMeterGSMChartByDateSlection({ start: moment(DailyDataDate, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(DailyDataDate, "DD-MM-YYYY").format("YYYY-MM-DD"), ...info })
		if (data.success == true) {
			const ChartArray = data.data.data;
			const DateArray = ChartArray.map((item) => ([item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT]))
			const obj = { daily_success: ChartArray.map((item) => (item?.daily_success || 0)), daily_fail: ChartArray.map((item) => (item?.daily_fail || 0)), inactive_count: ChartArray.map((item) => (item?.inactive_count || 0)), undermantan_count: ChartArray.map((item) => (item?.undermantan_count || 0)), pendding_count: ChartArray.map((item) => (item?.pendding_count || 0)), SRNO: ChartArray.map((item) => (item?.SRNO)), date: DateArray }

			setGSMChartData(obj)
		} else {

		}
	}

	const ongetMeterDashGSMBrtRtcDriffChart = async (info, bat_val, RTC_value) => {
		setBATRTCLoader(true)
		const data = await getMeterDashGSMBrtRtcDriffChart({ ...info || {}, bat_diff_val: bat_val, RTC_diff_val: RTC_value })
		if (data.success == true) {
			setBATRTC(data?.data?.data[0])
			setBATRTCLoader(false)
		} else {
			setBATRTCLoader(false)
		}
	}

	const ongetMeterDashGSMTamperCount = async (info, bat_val, RTC_value) => {
		const data = await getMeterDashGSMTemperCount({ ...info || {} })
		if (data.success == true) {
			setTAMPER_COUNT(data?.data?.data)
		} else {
		}
	}

	const ongetAllGSMInstantaneousChartByDate = async (info) => {
		setInstantaneousChartData({ daily_success: [], daily_fail: [], SRNO: [] })

		const data = await getMeterDashGSMInstantaneousChart(info || {});
		if (data.success === true) {
			const ChartArray = data.data.data;
			const DateArray = ChartArray.map((item) => ([item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT]))
			const obj = { instantaneous_success: ChartArray.map((item) => (item?.instantaneous_success || 0)), instantaneous_fail: ChartArray.map((item) => (item?.instantaneous_fail || 0)), SRNO: ChartArray.map((item) => (item?.SRNO)), date: DateArray }
			const view_json = JSON.parse(DashTabDetailById?.view_json || '{}')

			setInstantaneousChartData(obj)
			console.log(obj)
			setdataForINS({
				labels: DateArray?.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
				datasets: [
					(view_json[card_json[22]?.U_ID]?.bar_pemission?.includes("Instantaneous Success") || activeTab != 'Custom Dashboard') && {
						label: "Instantaneous Success",
						data: obj?.instantaneous_success,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(0, 104, 138)',
						borderRadius: 5,
						borderColor: 'rgb(0, 104, 138)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[22]?.U_ID]?.bar_pemission?.includes("Instantaneous Fail") || activeTab != 'Custom Dashboard') && {
						label: "Instantaneous Fail",
						data: obj?.instantaneous_fail,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(51, 181, 229)',
						borderRadius: 5,
						borderColor: 'rgb(51, 181, 229)',
						pointRadius: 5,
						pointHoverRadius: 7
					}
				],
			})

		} else {
			console.log("something went wrong");

		}
	};

	const ongetDataReceptionAnalyticsData = async (info) => {
		setCateDataRece({})
		setdataRecepLoader(true)
		const data = await getMeterGSMDataReceptionDateWise({ ...info })
		if (data.success == true) {
			if (CategoryName?.name?.length > 0) {
				setCategorySearch({ name: CategoryName?.name, ids: CategoryName?.ids, tree_cat: CategoryName?.tree_cat })
			}
			const ChartArray = data.data.data;

			setCateDataRece(ChartArray)
			setdataRecepLoader(false)
		} else {
			setdataRecepLoader(false)
		}
	};

	const ongetAllGSMConsumptionChartByDate = async (info) => {
		setConsumptionChartData({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [] })
		setQChartData({ SRNO: [], date: [] })
		const data = await getAllGSMConsumptionChartByDate(info || {});
		if (data.success === true) {
			const ChartArray = data.data.data;
			const DateArray = ChartArray.map((item) => ([item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT]))
			const obj = { CONSUMPTION: ChartArray.map((item) => (parseFloat(item?.CONSUMPTION).toFixed(2) || 0)), CONSUMPTION0: ChartArray.map((item) => (item?.CONSUMPTION0)), CONSUMPTION_NULL: ChartArray.map((item) => (item?.CONSUMPTION_NULL || 0)), CONS_more1000: ChartArray.map((item) => (item?.CONS_more1000)), CONS_between1000100: ChartArray.map((item) => (item?.CONS_between1000100)), CONS_between100: ChartArray.map((item) => (item?.CONS_between100)), SRNO: ChartArray.map((item) => (item?.SRNO)), date: DateArray }
			const Qobj = { QMAX_COUNT: ChartArray.map((item) => (item?.QMAX_COUNT || 0)), QMAX_NULL_COUNT: ChartArray.map((item) => (item?.QMAX_NULL_COUNT || 0)), QMAX_0_COUNT: ChartArray.map((item) => (item?.QMAX_0_COUNT || 0)), QMIN_COUNT: ChartArray.map((item) => (item?.QMIN_COUNT || 0)), QMIN_NULL_COUNT: ChartArray.map((item) => (item?.QMIN_NULL_COUNT || 0)), QMIN_0_COUNT: ChartArray.map((item) => (item?.QMIN_0_COUNT || 0)), QAVG_COUNT: ChartArray.map((item) => (item?.QAVG_COUNT || 0)), QAVG_NULL_COUNT: ChartArray.map((item) => (item?.QAVG_NULL_COUNT || 0)), QAVG_0_COUNT: ChartArray.map((item) => (item?.QAVG_0_COUNT || 0)), SRNO: ChartArray.map((item) => (item?.SRNO)), date: DateArray }
			setConsumptionChartData(obj)
			setQChartData(Qobj)
			const view_json = JSON.parse(DashTabDetailById?.view_json || '{}')
			setConsumptionChartJs({
				labels: DateArray.map((item) => (moment(item, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY (ddd)'))),
				datasets: [
					(view_json[card_json[23]?.U_ID]?.bar_pemission?.includes("Consumption") || activeTab != 'Custom Dashboard') && {
						label: "Consumption",
						data: obj?.CONSUMPTION,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(0, 104, 138)',
						borderRadius: 5,
						borderColor: 'rgb(0, 104, 138)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[23]?.U_ID]?.bar_pemission?.includes("0 Consumption") || activeTab != 'Custom Dashboard') && {
						label: "0 Consumption (Count)",
						data: obj?.CONSUMPTION0,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(51, 181, 229)',
						borderRadius: 5,
						borderColor: 'rgb(51, 181, 229)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[23]?.U_ID]?.bar_pemission?.includes("Null Data") || activeTab != 'Custom Dashboard') && {
						label: "Null Data (Count)",
						data: obj?.CONSUMPTION_NULL,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(255, 69, 96)',
						borderRadius: 5,
						borderColor: 'rgb(255, 69, 96)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
				]
			})
			setQMAXChartJs({
				labels: DateArray.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
				datasets: [
					(view_json[card_json[28]?.U_ID]?.bar_pemission?.includes("QMIN") || activeTab != 'Custom Dashboard') && {
						label: "QMIN",
						data: Qobj?.QMIN_COUNT,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(0, 104, 138)',
						borderRadius: 5,
						borderColor: 'rgb(0, 104, 138)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[28]?.U_ID]?.bar_pemission?.includes("0 QMIN") || activeTab != 'Custom Dashboard') && {
						label: "0 QMIN (Count)",
						data: Qobj?.QMIN_0_COUNT,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(51, 181, 229)',
						borderRadius: 5,
						borderColor: 'rgb(51, 181, 229)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[28]?.U_ID]?.bar_pemission?.includes("Null Data IN QMIN") || activeTab != 'Custom Dashboard') && {
						label: "Null Data IN QMIN (Count)",
						data: Qobj?.QMIN_NULL_COUNT,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(255, 69, 96)',
						borderRadius: 5,
						borderColor: 'rgb(255, 69, 96)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
				]
			})
			setQAVGChartJs({
				labels: DateArray.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
				datasets: [

					(view_json[card_json[29]?.U_ID]?.bar_pemission?.includes("QAVG") || activeTab != 'Custom Dashboard') && {
						label: "QAVG",
						data: Qobj?.QAVG_COUNT,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(0, 104, 138)',
						borderRadius: 5,
						borderColor: 'rgb(0, 104, 138)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[29]?.U_ID]?.bar_pemission?.includes("0 QAVG") || activeTab != 'Custom Dashboard') && {
						label: "0 QAVG (Count)",
						data: Qobj?.QAVG_0_COUNT,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(51, 181, 229)',
						borderRadius: 5,
						borderColor: 'rgb(51, 181, 229)',
						pointRadius: 5,
						pointHoverRadius: 7
					},
					(view_json[card_json[29]?.U_ID]?.bar_pemission?.includes("Null Data IN QAVG") || activeTab != 'Custom Dashboard') && {
						label: "Null Data IN QAVG (Count)",
						data: Qobj?.QAVG_NULL_COUNT,
						barPercentage: 1,
						categoryPercentage: 0.6,
						backgroundColor: 'rgb(255, 69, 96)',
						borderRadius: 5,
						borderColor: 'rgb(255, 69, 96)',
						pointRadius: 5,
						pointHoverRadius: 7
					}
				]
			})
		} else {
			console.log("something went wrong");

		}
	};

	const onLoadConsumptionData = async (info) => {
		setConsumptionPie({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [] })
		const data = await getAllGSMConsumptionChartBySelectDate({ start: moment(ConsumptionDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), end: moment(ConsumptionDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), ...info, ValueCharCon })
		if (data.success === true) {
			const ChartArray = data.data.data;
			const obj = { CONSUMPTION: ChartArray.map((item) => (parseFloat(item?.CONSUMPTION).toFixed(2))), CONSUMPTION0: ChartArray.map((item) => (item?.CONSUMPTION0)), CONSUMPTION_NULL: ChartArray.map((item) => (item?.CONSUMPTION_NULL)), QMAX_COUNT: ChartArray.map((item) => (item?.QMAX_COUNT)), QMAX_NULL_COUNT: ChartArray.map((item) => (item?.QMAX_NULL_COUNT)), QMAX_0_COUNT: ChartArray.map((item) => (item?.QMAX_0_COUNT)), QMIN_COUNT: ChartArray.map((item) => (item?.QMIN_COUNT)), QMIN_NULL_COUNT: ChartArray.map((item) => (item?.QMIN_NULL_COUNT)), QMIN_0_COUNT: ChartArray.map((item) => (item?.QMIN_0_COUNT)), QAVG_COUNT: ChartArray.map((item) => (item?.QAVG_COUNT)), QAVG_NULL_COUNT: ChartArray.map((item) => (item?.QAVG_NULL_COUNT)), QAVG_0_COUNT: ChartArray.map((item) => (item?.QAVG_0_COUNT)), SRNO: ChartArray.map((item) => (item?.SRNO)), CONS_more1000: ChartArray.map((item) => (item?.CONS_more1000)), CONS_between1000100: ChartArray.map((item) => (item?.CONS_between1000100)), CONS_between100: ChartArray.map((item) => (item?.CONS_between100)), CONS_more1000_con: ChartArray.map((item) => (parseFloat(item?.CONS_more1000_con).toFixed(2))), CONS_between1000100_con: ChartArray.map((item) => (parseFloat(item?.CONS_between1000100_con).toFixed(2))), CONS_between100_con: ChartArray.map((item) => (parseFloat(item?.CONS_between100_con).toFixed(2))) }
			setConsumptionPie({ ...obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT)) })
			setChartLoader(false)
		} else {
			console.log('something went wrong')
			setChartLoader(false)
		}
	}

	const onLoadConsumptionDataPieChart = async (info) => {
		setConsumptionPieanality({ CONSUMPTION: [], CONSUMPTION0: [], CONSUMPTION_NULL: [], SRNO: [] })
		const data = await getAllGSMConsumptionChartBySelectDate({ start: moment(ConsumptionDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), end: moment(ConsumptionDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), ...info })
		if (data.success === true) {
			const ChartArray = data.data.data;
			const obj = { CONSUMPTION: ChartArray.map((item) => (parseFloat(item?.CONSUMPTION).toFixed(2))), CONSUMPTION0: ChartArray.map((item) => (item?.CONSUMPTION0)), CONSUMPTION_NULL: ChartArray.map((item) => (item?.CONSUMPTION_NULL)), QMAX_COUNT: ChartArray.map((item) => (item?.QMAX_COUNT)), QMAX_NULL_COUNT: ChartArray.map((item) => (item?.QMAX_NULL_COUNT)), QMAX_0_COUNT: ChartArray.map((item) => (item?.QMAX_0_COUNT)), QMIN_COUNT: ChartArray.map((item) => (item?.QMIN_COUNT)), QMIN_NULL_COUNT: ChartArray.map((item) => (item?.QMIN_NULL_COUNT)), QMIN_0_COUNT: ChartArray.map((item) => (item?.QMIN_0_COUNT)), QAVG_COUNT: ChartArray.map((item) => (item?.QAVG_COUNT)), QAVG_NULL_COUNT: ChartArray.map((item) => (item?.QAVG_NULL_COUNT)), QAVG_0_COUNT: ChartArray.map((item) => (item?.QAVG_0_COUNT)), SRNO: ChartArray.map((item) => (item?.SRNO)), CONS_more1000: ChartArray.map((item) => (item?.CONS_more1000)), CONS_between1000100: ChartArray.map((item) => (item?.CONS_between1000100)), CONS_between100: ChartArray.map((item) => (item?.CONS_between100)), CONS_more1000_con: ChartArray.map((item) => (parseFloat(item?.CONS_more1000_con).toFixed(2))), CONS_between1000100_con: ChartArray.map((item) => (parseFloat(item?.CONS_between1000100_con).toFixed(2))), CONS_between100_con: ChartArray.map((item) => (parseFloat(item?.CONS_between100_con).toFixed(2))) }
			setConsumptionPieanality({ ...obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT)) })
		} else {
			console.log('something went wrong')
		}
	}

	const [NameObj, setNameObj] = useState({})
	const ongetProfilNameByAccountId = async (id) => {
		const data = await getProfilNameByAccountId({ ID: id })
		if (data.success === true) {
			setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
		} else {
			console.log('something went wrong')
		}
	}

	const onChangesProfileWiseSerch = (info) => {
		const view_json = JSON.parse(DashTabDetailById?.view_json || '{}')

		if (activeTab != 'Custom Dashboard' || view_json[card_json[21]?.U_ID]?.display == true) {
			const day_number = FilterDay?.filter(item => item?.card_id == card_json[21]?.U_ID)[0]?.day_preference || 7
			ongetAllGSMChartByDate({ ...info, day_number: activeTab == 'Custom Dashboard' ? day_number : 7 })
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[22]?.U_ID]?.display == true) {
			const day_number = FilterDay?.filter(item => item?.card_id == card_json[22]?.U_ID)[0]?.day_preference || 7
			ongetAllGSMInstantaneousChartByDate({ ...info, day_number: activeTab == 'Custom Dashboard' ? day_number : 7 })
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[19]?.U_ID]?.display == true) {

			ongetMeterDashGSMBrtRtcDriffChart(info, varSetting?.BAT, varSetting?.RTC)
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[20]?.U_ID]?.display == true) {
			ongetMeterDashGSMTamperCount(info)
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[23]?.U_ID]?.display == true) {
			const day_number = FilterDay?.filter(item => item?.card_id == card_json[23]?.U_ID)[0]?.day_preference || 7
			ongetAllGSMConsumptionChartByDate({ ...info, day_number: activeTab == 'Custom Dashboard' ? day_number : 7 })
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[24]?.U_ID]?.display == true) {
			onLoadDailyData(info)
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[26]?.U_ID]?.display == true) {
			onLoadConsumptionData(info)
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[25]?.U_ID]?.display == true) {
			onLoadConsumptionDataPieChart(info)
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[30]?.U_ID]?.display == true) {
			ongetSaleSummaryTableData({ ...info, start: moment(moment(saleDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY'), 'DD-MM-YYYY').add(-15, 'day').format('DD-MM-YYYY'), end: moment(saleDate, "DD-MM-YYYY").add(1, 'day').format('DD-MM-YYYY') })
		}
		if (activeTab != 'Custom Dashboard' || view_json[card_json[31]?.U_ID]?.display == true) {
			ongetAllGSMHourlyConsumptionChart(info)
		}

	}

	const onChangesCardWiseFilter = (value, UID, isfilter, day_no) => {
		let info = {}
		if (isfilter === false) {
			info = { CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } }
		} else {
			const array_parent = findParent(TreeSelection, value) || []
			setCardFilter({ ...CardFilter, [UID]: { account: array_parent[0] || '', ga: array_parent[1] || '', circle: array_parent[2] || '', division: array_parent[3] || '', subdivision: array_parent[4] || '', area: array_parent[5] || '' } })
			const view_json = JSON.parse(DashTabDetailById?.view_json || '{}')
			info = { CATEGORY0: array_parent[0] || '', CATEGORY1: array_parent[1] || '', CATEGORY2: array_parent[2] || '', CATEGORY3: array_parent[3] || '', CATEGORY4: array_parent[4] || '', CATEGORY5: array_parent[5] || '', ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } }
		}
		if (card_json[21]?.U_ID == UID) {
			let day_number = 7
			if (isfilter) {
				day_number = FilterDay?.filter(item => item?.card_id == card_json[21]?.U_ID)[0]?.day_preference || 7
			} else {
				day_number = day_no
			}
			ongetAllGSMChartByDate({ ...info, day_number: day_number })
		}
		if (card_json[22]?.U_ID == UID) {
			let day_number = 7
			if (isfilter) {
				day_number = FilterDay?.filter(item => item?.card_id == card_json[21]?.U_ID)[0]?.day_preference || 7
			} else {
				day_number = day_no
			}
			ongetAllGSMInstantaneousChartByDate({ ...info, day_number: day_number })
		}
		if (card_json[19]?.U_ID == UID) {
			ongetMeterDashGSMBrtRtcDriffChart(info, varSetting?.BAT, varSetting?.RTC)
		}
		if (card_json[20]?.U_ID == UID) {
			ongetMeterDashGSMTamperCount(info)
		}
		if (card_json[23]?.U_ID == UID) {
			let day_number = 7
			if (isfilter) {
				day_number = FilterDay?.filter(item => item?.card_id == card_json[21]?.U_ID)[0]?.day_preference || 7
			} else {
				day_number = day_no
			}
			ongetAllGSMConsumptionChartByDate({ ...info, day_number: day_number })
		}
		if (card_json[24]?.U_ID == UID) {
			onLoadDailyData(info)
		}
		if (card_json[26]?.U_ID == UID) {
			onLoadConsumptionData(info)
		}
		if (card_json[25]?.U_ID == UID) {
			onLoadConsumptionDataPieChart(info)
		}
		if (card_json[30]?.U_ID == UID) {
			ongetSaleSummaryTableData({ ...info, start: moment(moment(saleDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY'), 'DD-MM-YYYY').add(-15, 'day').format('DD-MM-YYYY'), end: moment(saleDate, "DD-MM-YYYY").add(1, 'day').format('DD-MM-YYYY') })
		}
		if (card_json[31]?.U_ID == UID) {
			ongetAllGSMHourlyConsumptionChart(info)
		}

	}

	const onProfileWiseSearchClick = () => {
		onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
	}

	useEffect(() => {
		if (tringerMeter == true && activeTab == 'Custom Dashboard') {
			onProfileWiseSearchClick()
			settringerMeter(false)
		}
	}, [tringerMeter])

	const onHandalSearchClick = () => {
		ongetMeterDashGSMBrtRtcDriffChart({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } }, varSetting?.BAT, varSetting?.RTC);
	}



	const dataForChartJsPie = {
		labels: ["DailyData Success", "DailyData Fail", "Inactive", "Under Maintanance", "Pending Configuration"],
		datasets: [
			{
				label: "DailyData Success",
				data: [GSMChartData?.daily_success?.at(-1), GSMChartData?.daily_fail?.at(-1), GSMChartData?.inactive_count?.at(-1), GSMChartData?.undermantan_count?.at(-1), GSMChartData?.pendding_count?.at(-1)],
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: ['rgb(0, 104, 138)', 'rgb(51, 181, 229)', 'rgb(255, 69, 96)', 'rgb(0, 143, 251)', 'rgb(254, 176, 25)'],
				borderRadius: 5,
			}
		],
	};
	const optionsForChartJS = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {

				ticks: {
					maxRotation: 90,

				}
			}
		},
		animation: false,
		// animation: {
		// 	duration: 1000,
		// 	easing: 'easeInBounce',
		// 	onComplete: () => {
		// 		delayed = true;
		// 	},
		// 	delay: (context) => {
		// 		let delay = 0;
		// 		if (context.type === 'data' && context.mode === 'default' && !delayed) {
		// 			delay = context.dataIndex * 300 + context.datasetIndex * 100;
		// 		}
		// 		return delay;
		// 	},
		// },

		plugins: {
			legend: {
				position: 'bottom',
				fullSize: false,
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded',
					padding: 5,
					font: function (context) {
						var w = context.chart.width;
						return {
							size: w < 512 ? 8 : 11,

						};
					},
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};
	const ChartJsConsumptionCategory = {
		labels: [ConsumptionDate],
		datasets: [
			{
				label: `consumption more than ${ValueCharCon?.isGreter} SCM`,
				data: [ConsumptionPie?.CONS_more1000_con?.at(-1)],
				barPercentage: 0.6,
				categoryPercentage: 0.4,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
			},
			{
				label: `consumption between ${ValueCharCon?.Between1?.from} to ${ValueCharCon?.Between1?.to} SCM`,
				data: [ConsumptionPie?.CONS_between1000100_con?.at(-1)],
				barPercentage: 0.6,
				categoryPercentage: 0.4,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
			},
			{
				label: `consumption between ${ValueCharCon?.Between2?.from} to <${ValueCharCon?.Between2?.to} Scm`,
				data: [ConsumptionPie?.CONS_between100_con?.at(-1)],
				barPercentage: 0.6,
				categoryPercentage: 0.4,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
			}
		]
	}


	const ConsumptionHourlyChartJs = {
		labels: HourConsumptionData?.date?.map((item) => (moment(item, 'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY hh:mm:ss A (ddd)'))),
		datasets: [
			{
				label: "Consumption",
				data: HourConsumptionData?.CONSUMPTION,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
				borderColor: 'rgb(0, 104, 138)',
				pointRadius: 5,
				pointHoverRadius: 7
			}
		]
	}

	const ConsumptionChartJsPie = {
		labels: ['Consumption', '0 Consumption (Count)', "Null Data (Count)"],
		datasets: [
			{
				label: "Consumption",
				data: [ConsumptionPieanality?.CONSUMPTION?.at(-1), ConsumptionPieanality?.CONSUMPTION0?.at(-1), ConsumptionPieanality?.CONSUMPTION_NULL.at(-1)],
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: ['rgb(0, 104, 138)', 'rgb(51, 181, 229)', 'rgb(255, 69, 96)'],
				borderRadius: 5,
			}
		]
	}
	const ConanalitChartJsPie = {
		labels: [`consumption more than ${ValueCharCon?.isGreter} SCM`, `consumption between ${ValueCharCon?.Between1?.from}to ${ValueCharCon?.Between1?.to} SCM`, `consumption between ${ValueCharCon?.Between2?.from} to <${ValueCharCon?.Between2?.to} Scm`, `zero consumption`],
		datasets: [
			{
				label: "",
				data: [ConsumptionPie?.CONS_more1000?.at(-1), ConsumptionPie?.CONS_between1000100?.at(-1), ConsumptionPie?.CONS_between100?.at(-1), ConsumptionPie?.CONSUMPTION0?.at(-1)],
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: ['rgb(0, 104, 138)', 'rgb(51, 181, 229)', 'rgb(255, 69, 96)', 'rgb(254 176 25)'],
				borderRadius: 5,
			}
		]
	}
	const optionsForChartJSPie = {
		responsive: true,
		maintainAspectRatio: false,

		animation: false,

		plugins: {
			legend: {
				position: 'bottom',
				fullSize: false,
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded',
					padding: 20,
					font: function (context) {
						var w = context.chart.width;
						return {
							size: w < 512 ? 8 : 11,

						};
					},
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20,
			}
		},
	};
	const QChartJs = {
		labels: dateInLong.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "QMAX",
				data: QChartData?.QMAX_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
				borderColor: 'rgb(0, 104, 138)',
				pointRadius: 5,
				pointHoverRadius: 7
			},
			// {
			// 	label: "0 QMAX (Count)",
			// 	data: QChartData?.QMAX_0_COUNT,
			// 	barPercentage: 1,
			// 	categoryPercentage: 0.6,
			// 	backgroundColor: 'rgb(51, 181, 229)',
			// 	borderRadius: 5,
			// },
			// {
			// 	label: "Null Data IN QMAX (Count)",
			// 	data: QChartData?.QMAX_NULL_COUNT,
			// 	barPercentage: 1,
			// 	categoryPercentage:0.6,
			// 	backgroundColor: 'rgb(255, 69, 96)',
			// 	borderRadius: 5,
			// }
		]
	}



	const optionsINS = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {

				ticks: {
					maxRotation: 90,

				}
			}
		},
		animation: false,
		// animation: {
		// 	duration: 1000,
		// 	easing: 'easeInBounce',
		// 	onComplete: () => {
		// 		delayed = true;
		// 	},
		// 	delay: (context) => {
		// 		let delay = 0;
		// 		if (context.type === 'data' && context.mode === 'default' && !delayed) {
		// 			delay = context.dataIndex * 300 + context.datasetIndex * 100;
		// 		}
		// 		return delay;
		// 	},
		// },

		plugins: {
			legend: {
				position: 'bottom',
				fullSize: false,
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded',
					padding: 5,
					font: function (context) {
						var w = context.chart.width;
						return {
							size: w < 512 ? 8 : 11,

						};
					},
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};
	const onClick = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartRef.current, event)[0] || {};
		// console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATList[datasetIndex]
		if (DATA_point) {
			onHandalGsmChart(GSMDATList[datasetIndex], AllGSMChartData.SRNO[index], false, dateInLong[index], 'gsm_monitor_day_wise_graph', card_json[21]?.U_ID)
		}
	}

	const onClickDailyPie = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(pieDailydata.current, event)[0] || {};
		console.log(datasetIndex, index)
		console.log((getElementAtEvent(pieDailydata.current, event)[0]));
		const DATA_point = GSMDATList[datasetIndex]
		if (DATA_point) {
			onHandalGsmChart(GSMDATList[index], GSMChartData.SRNO[datasetIndex], false, DailyDataDate, 'gsm_monitor_day_wise_graph', card_json[24]?.U_ID)
		}
	}

	const onClickIns = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartRefINS.current, event)[0] || {};
		// console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		if (DATA_point) {
			onHandalGsmChart(GSMDATAINSList[datasetIndex], InstantaneousChartData.SRNO[index], false, InstantaneousChartData?.date[index], 'gsm_instantaneous_monitor_graph', card_json[22]?.U_ID)
		}
	}

	const onClickEpand = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(expandChartRef.current, event)[0] || {};
		// console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATList[datasetIndex]
		if (DATA_point) {

			onHandalGsmChart(GSMDATList[datasetIndex], expandedChartData?.total?.SRNO[index], false, expandedChartData?.date[index], 'gsm_monitor_day_wise_graph', card_json[21]?.U_ID)

		}
	}

	const onClickINSEpand = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(expandChartRef.current, event)[0] || {};
		// console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		if (DATA_point) {
			onHandalGsmChart(GSMDATAINSList[datasetIndex], expandedChartINSData?.total?.SRNO[index], false, expandedChartINSData?.date[index], 'gsm_instantaneous_monitor_graph', card_json[22]?.U_ID)
		}
	}

	const onClickBATpand = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(expandChartRef.current, event)[0] || {};
		console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		if (DATA_point) {
			if (datasetIndex == 0) {
				onHandalGsmChartBATRTC('INSTANTANEOUS_SUCCESS_LIST', expandedChartINSData?.total?.SRNO[index], false, expandedChartINSData?.date[index], 'gsm_instantaneous_monitor_graph', `Battery Percentage(>${varSetting?.BAT})`, datasetIndex, card_json[18]?.U_ID)
			} else {
				onHandalGsmChartBATRTC('INSTANTANEOUS_SUCCESS_LIST', expandedChartINSData?.total?.SRNO[index], false, expandedChartINSData?.date[index], 'gsm_instantaneous_monitor_graph', `Battery Percentage(<${varSetting?.BAT})`, datasetIndex, card_json[18]?.U_ID)
			}
		}
	}

	const onClickRTCpand = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(expandChartRef.current, event)[0] || {};
		console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		if (DATA_point) {
			if (datasetIndex == 0) {
				onHandalGsmChartRTC('INSTANTANEOUS_SUCCESS_LIST', expandedChartINSData?.total?.SRNO[index], false, expandedChartINSData?.date[index], 'gsm_instantaneous_monitor_graph', `RTC Time(<${varSetting?.RTC})`, datasetIndex, card_json[19]?.U_ID)
			} else {
				onHandalGsmChartRTC('INSTANTANEOUS_SUCCESS_LIST', expandedChartINSData?.total?.SRNO[index], false, expandedChartINSData?.date[index], 'gsm_instantaneous_monitor_graph', `RTC Time(>${varSetting?.RTC})`, datasetIndex, card_json[19]?.U_ID)
			}
		}
	}

	const onClickConsumption = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(expandChartRef.current, event)[0] || {};
		console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		if (datasetIndex == 0) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', expandedChartData?.total?.SRNO[index], false, moment(expandedChartData?.date[index], 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'), 'gsm_monitor_day_wise_graph', `Consumption`, datasetIndex, card_json[23]?.U_ID)
		} else if (datasetIndex == 1) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', expandedChartData?.total?.SRNO[index], false, moment(expandedChartData?.date[index], 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'), 'gsm_monitor_day_wise_graph', `0 Consumption`, datasetIndex, card_json[23]?.U_ID)
		} else if (datasetIndex == 2) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', expandedChartData?.total?.SRNO[index], false, moment(expandedChartData?.date[index], 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'), 'gsm_monitor_day_wise_graph', `Null Data Count`, datasetIndex, card_json[23]?.U_ID)
		}
	}

	const onClickStatiConsumption = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartConsumptionRef.current, event)[0] || {};
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		console.log(datasetIndex, index, DATA_point)
		if (datasetIndex == 0) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionChartData?.SRNO[index], false, moment(ConsumptionChartData?.date[index], 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'), 'gsm_monitor_day_wise_graph', `Consumption`, datasetIndex, card_json[23]?.U_ID)
		} else if (datasetIndex == 1) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionChartData?.SRNO[index], false, moment(ConsumptionChartData?.date[index], 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'), 'gsm_monitor_day_wise_graph', `0 Consumption`, datasetIndex, card_json[23]?.U_ID)
		} else if (datasetIndex == 2) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionChartData?.SRNO[index], false, moment(ConsumptionChartData?.date[index], 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'), 'gsm_monitor_day_wise_graph', `Null Data Count`, datasetIndex, card_json[23]?.U_ID)
		}
	}

	const onClickStaticHourlyConsumption = (event) => {
		const { datasetIndex, index } = getElementAtEvent(HourlyConsumptionRef.current, event)[0] || {};
		const DATA_point = GSMDATAINSList[datasetIndex]
		console.log(datasetIndex, index, DATA_point)
		if (datasetIndex == 0) {
			onHandalGsmHourlyChartConsumption('HOURLY_SUCCESS_LIST', HourConsumptionData?.SRNO[index], false, moment(HourConsumptionData?.date[index], 'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY hh:mm:ss A (ddd)'), 'gsm_monitor_hourly_wise_graph', `Consumption`, datasetIndex, card_json[31]?.U_ID)
		}
	}

	const onClickdailyConsmptionCategory = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(dailyConsmptionCategory.current, event)[0] || {};
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		console.log(datasetIndex, index, DATA_point)
		if (index == 0) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionPie?.SRNO[datasetIndex], false, ConsumptionDate, 'gsm_monitor_day_wise_graph', `consumption more than ${ValueCharCon?.isGreter} SCM`, 3, card_json[26]?.U_ID)
		} else if (index == 1) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionPie?.SRNO[datasetIndex], false, ConsumptionDate, 'gsm_monitor_day_wise_graph', `consumption between ${ValueCharCon?.Between1?.from}to ${ValueCharCon?.Between1?.to} SCM`, 4, card_json[26]?.U_ID)
		} else if (index == 2) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionPie?.SRNO[datasetIndex], false, ConsumptionDate, 'gsm_monitor_day_wise_graph', `consumption between ${ValueCharCon?.Between2?.from} to <${ValueCharCon?.Between2?.to} Scm`, 5, card_json[26]?.U_ID)
		} else if (index == 3) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionPie?.SRNO[datasetIndex], false, ConsumptionDate, 'gsm_monitor_day_wise_graph', `zero consumption`, 1, card_json[26]?.U_ID)
		}
	}

	const onClickDailyConsumptionPie = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(pieDailyConsumptiondata.current, event)[0] || {};
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		console.log(datasetIndex, index, DATA_point)
		if (index == 0) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionPieanality?.SRNO[datasetIndex], false, ConsumptionDateanality, 'gsm_monitor_day_wise_graph', `Consumption`, index, card_json[25]?.U_ID)
		} else if (index == 1) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionPieanality?.SRNO[datasetIndex], false, ConsumptionDateanality, 'gsm_monitor_day_wise_graph', `0 Consumption`, index, card_json[25]?.U_ID)
		} else if (index == 2) {
			onHandalGsmChartConsumption('DAILY_SUCCESS_LIST', ConsumptionPieanality?.SRNO[datasetIndex], false, ConsumptionDateanality, 'gsm_monitor_day_wise_graph', `Null Data Count`, index, card_json[25]?.U_ID)
		}
	}


	const onClickStatiCQ = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartQRef.current, event)[0] || {};
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		console.log(datasetIndex, index, DATA_point)
		if (datasetIndex == 0) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `QMAX`, datasetIndex, card_json[27]?.U_ID)
		} else if (datasetIndex == 1) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `0 QMAX`, datasetIndex, card_json[27]?.U_ID)
		} else if (datasetIndex == 2) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `Null Data In QMAX Count`, datasetIndex, card_json[27]?.U_ID)
		}
	}

	const onClickStatiCQMIN = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartQMINRef.current, event)[0] || {};
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		console.log(datasetIndex, index, DATA_point)
		if (datasetIndex == 0) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `QMIN`, 3, card_json[28]?.U_ID)
		} else if (datasetIndex == 1) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `0 QMIN`, 4, card_json[0]?.U_ID, card_json[28]?.U_ID)
		} else if (datasetIndex == 2) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `Null Data In QMIN Count`, 5, card_json[0]?.U_ID, card_json[28]?.U_ID)
		}
	}

	const onClickStatiCQAVG = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartQAVGRef.current, event)[0] || {};
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATAINSList[datasetIndex]
		console.log(datasetIndex, index, DATA_point)
		if (datasetIndex == 0) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `QAVG`, 6, card_json[29]?.U_ID)
		} else if (datasetIndex == 1) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `0 QAVG`, 7, card_json[29]?.U_ID)
		} else if (datasetIndex == 2) {
			onHandalGsmChartQ('DAILY_SUCCESS_LIST', QChartData?.SRNO[index], false, QChartData?.date[index], 'gsm_monitor_day_wise_graph', `Null Data In QAVG Count`, 8, card_json[19]?.U_ID)
		}
	}

	const onHandalGsmChartQ = async (col, srno, changeTable, date, tableName, title, datasetIndex, CARD_ID) => {
		setIsModalVisible(true)
		setModalLoad(true)
		setModelId(title + `(${date})`)
		let obj = {}
		if (Object.keys(CardFilter[CARD_ID] || {}).length) {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: CardFilter[CARD_ID]?.account, CATEGORY1: CardFilter[CARD_ID]?.ga, CATEGORY2: CardFilter[CARD_ID]?.circle, CATEGORY3: CardFilter[CARD_ID]?.division, CATEGORY4: CardFilter[CARD_ID]?.subdivision, CATEGORY5: CardFilter[CARD_ID]?.area, ROLES: userInfo?.ROLE, serach: true, ins_no: Profile.ins_no, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		} else {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, ins_no: Profile.ins_no, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		}
		const data = await gsm_monitor_day_wise_graph_details(obj)
		if (data.success === true) {
			var Arraydata = []
			console.log('datasetIndex', datasetIndex)
			JSON.parse(data.data.data[0][col] || '[]').map((item) => {

				if (datasetIndex == 0) {
					if (item?.Q_MAX_COUNT) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CONSUMPTION: item?.QMAX, Q_MAX_COUNT: item?.Q_MAX_COUNT, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 1) {
					if (item?.QMAX == 0) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CONSUMPTION: item?.QMAX, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 2) {
					if (item?.QMAX == null || item?.QMAX == undefined) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 3) {
					console.log(item?.QMIN)
					if (item?.QMIN != null && item?.QMIN != undefined && item?.QMIN != 0) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CONSUMPTION: item?.QMIN, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 4) {
					if (item?.QMIN == 0) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CONSUMPTION: item?.QMIN, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 5) {
					if (item?.QMIN == null || item?.QMIN == undefined) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 6) {
					if (item?.QAVG != null && item?.QAVG != undefined && item?.QAVG != 0) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CONSUMPTION: item?.QAVG, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 7) {
					if (item?.QAVG == 0) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CONSUMPTION: item?.QAVG, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 8) {
					if (item?.QAVG == null || item?.QAVG == undefined) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				}
			})
			console.log(Arraydata);
			setTableDataInApi(Arraydata)
			setModalLoad(false)
		} else {
			console.log('something went wrong')
			setModalLoad(false)
		}
	}

	const onHandalGsmChartConsumption = async (col, srno, changeTable, date, tableName, title, datasetIndex, CARD_ID) => {
		setIsModalVisible(true)
		setModalLoad(true)
		setModelId(title + `(${date})`)
		let obj = {}
		if (Object.keys(CardFilter[CARD_ID] || {}).length) {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: CardFilter[CARD_ID].account, CATEGORY1: CardFilter[CARD_ID].ga, CATEGORY2: CardFilter[CARD_ID].circle, CATEGORY3: CardFilter[CARD_ID].division, CATEGORY4: CardFilter[CARD_ID].subdivision, CATEGORY5: CardFilter[CARD_ID].area, ROLES: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		} else {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ins_no: Profile.ins_no, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		}
		const data = await gsm_monitor_day_wise_graph_details(obj)
		if (data.success === true) {
			var Arraydata = []
			JSON.parse(data.data.data[0][col] || '[]').map((item) => {

				if (datasetIndex == 0) {
					if (item?.CONSUMPTION != null && item?.CONSUMPTION != undefined && item?.CONSUMPTION != '0') {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CONSUMPTION: item?.CONSUMPTION, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 1) {
					if (item?.CONSUMPTION == '0') {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 3) {
					if (parseFloat(item.CONSUMPTION) > ValueCharCon.isGreter) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 4) {
					if (parseFloat(item.CONSUMPTION) < ValueCharCon.Between1.from && parseFloat(item.CONSUMPTION) > ValueCharCon.Between1.to) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else if (datasetIndex == 5) {
					if (parseFloat(item.CONSUMPTION) < ValueCharCon.Between2.from && parseFloat(item.CONSUMPTION) > ValueCharCon.Between2.to) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else {
					if (item?.CONSUMPTION == null || item?.CONSUMPTION == undefined) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				}
				if (item.CONSUMPTION !== null && item.CONSUMPTION !== undefined) {


				}
			})
			console.log(Arraydata);
			setTableDataInApi(Arraydata)
			setModalLoad(false)
		} else {
			console.log('something went wrong')
			setModalLoad(false)
		}
	}
	const onHandalGsmHourlyChartConsumption = async (col, srno, changeTable, date, tableName, title, datasetIndex, CARD_ID) => {
		setIsModalVisible(true)
		setModalLoad(true)
		setModelId(title + `(${date})`)
		let obj = {}
		if (Object.keys(CardFilter[CARD_ID] || {}).length) {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: CardFilter[CARD_ID]?.account, CATEGORY1: CardFilter[CARD_ID]?.ga, CATEGORY2: CardFilter[CARD_ID]?.circle, CATEGORY3: CardFilter[CARD_ID]?.division, CATEGORY4: CardFilter[CARD_ID]?.subdivision, CATEGORY5: CardFilter[CARD_ID]?.area, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		} else {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ins_no: Profile.ins_no, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		}
		const data = await gsm_monitor_day_wise_graph_details(obj)
		if (data.success === true) {
			var Arraydata = []
			JSON.parse(data.data.data[0][col] || '[]').map((item) => {

				if (datasetIndex == 0) {
					Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CONSUMPTION: item?.CONSUMPTION, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
				}
			})
			console.log(Arraydata);
			setTableDataInApi(Arraydata)
			setModalLoad(false)
		} else {
			console.log('something went wrong')
			setModalLoad(false)
		}
	}
	const onHandalGsmChartRTC = async (col, srno, changeTable, date, tableName, title, datasetIndex, CARD_ID) => {
		setIsModalVisible(true)
		setModalLoad(true)
		setModelId(title)
		let obj = {}
		if (Object.keys(CardFilter[CARD_ID] || {}).length) {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: CardFilter[CARD_ID]?.account, CATEGORY1: CardFilter[CARD_ID]?.ga, CATEGORY2: CardFilter[CARD_ID]?.circle, CATEGORY3: CardFilter[CARD_ID]?.division, CATEGORY4: CardFilter[CARD_ID]?.subdivision, CATEGORY5: CardFilter[CARD_ID]?.area, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		} else {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		}
		const data = await gsm_monitor_day_wise_graph_details(obj)
		if (data.success === true) {

			var Arraydata = []
			JSON.parse(data.data.data[0][col] || '[]').map((item) => {

				if (item.RTC_TIME_DIFF !== null && item.RTC_TIME_DIFF !== undefined) {

					if ((Math.floor(item.RTC_TIME_DIFF % 3600 / 60)) > varSetting?.RTC && datasetIndex == 1) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					} else if ((Math.floor(item.RTC_TIME_DIFF % 3600 / 60)) < varSetting?.RTC && datasetIndex == 0) {

						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				}
			})
			console.log(Arraydata);
			setTableDataInApi(Arraydata)
			setModalLoad(false)
		} else {
			console.log('something went wrong')
			setModalLoad(false)
		}
	}
	const onHandalGsmChartBATRTC = async (col, srno, changeTable, date, tableName, title, datasetIndex, CARD_ID) => {
		setIsModalVisible(true)
		setModalLoad(true)
		setModelId(title)
		let obj = {}
		if (Object.keys(CardFilter[CARD_ID] || {}).length) {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: CardFilter[CARD_ID]?.account, CATEGORY1: CardFilter[CARD_ID]?.ga, CATEGORY2: CardFilter[CARD_ID]?.circle, CATEGORY3: CardFilter[CARD_ID]?.division, CATEGORY4: CardFilter[CARD_ID]?.subdivision, CATEGORY5: CardFilter[CARD_ID]?.area, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		} else {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		}
		const data = await gsm_monitor_day_wise_graph_details(obj)
		if (data.success === true) {

			var Arraydata = []
			JSON.parse(data.data.data[0][col] || '[]').map((item) => {
				var meter_bat_days = item.SCRIPTDECODE_ID && ModemData.filter((met) => (met.METER_UNIQUE_ID == (item.SCRIPTDECODE_ID.split('_')[1]))).length > 0 ? ModemData.filter((met) => (met.METER_UNIQUE_ID == (item.SCRIPTDECODE_ID.split('_')[1])))[0].BAT_DAYS || 0 : 0
				if (item.BATTERY_DAYS !== null && item.BATTERY_DAYS !== undefined) {

					if (((parseInt(item.BATTERY_DAYS) * 100) / meter_bat_days) > varSetting?.BAT && datasetIndex == 0) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					} else if (((parseInt(item.BATTERY_DAYS) * 100) / meter_bat_days) < varSetting?.BAT && datasetIndex == 1) {
						Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
					}
				} else {
					if (item.BATTERY_PERCENTAGE !== null && item.BATTERY_PERCENTAGE !== undefined) {

						if (item.BATTERY_PERCENTAGE > varSetting?.BAT && datasetIndex == 0) {

							Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
						} else if (item.BATTERY_PERCENTAGE < varSetting?.BAT && datasetIndex == 1) {

							Arraydata.push({ ...item, UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })
						}
					}
				}
			})
			console.log(Arraydata);
			setTableDataInApi(Arraydata)
			setModalLoad(false)
		} else {
			console.log('something went wrong')
			setModalLoad(false)
		}
	}

	const onHandalGsmChart = async (col, srno, changeTable, date, tableName, CARD_ID) => {
		setIsModalVisible(true)
		setModalLoad(true)
		setModelId(`${col}` + `(${date})`)
		let obj = {}
		if (Object.keys(CardFilter[CARD_ID] || {}).length) {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: CardFilter[CARD_ID]?.account, CATEGORY1: CardFilter[CARD_ID]?.ga, CATEGORY2: CardFilter[CARD_ID]?.circle, CATEGORY3: CardFilter[CARD_ID]?.division, CATEGORY4: CardFilter[CARD_ID]?.subdivision, CATEGORY5: CardFilter[CARD_ID]?.area, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		} else {
			obj = { colName: col, SRNO: srno, changeTable: changeTable, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, tableName: tableName }
		}
		const data = await gsm_monitor_day_wise_graph_details(obj)
		if (data.success === true) {
			setTableDataInApi(JSON.parse(data.data.data[0][col] || '[]').map((item) => ({ UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, CAT0_ID: item?.Profile?.CATEGORY0, CAT1_ID: item?.Profile?.CATEGORY1, CAT2_ID: item?.Profile?.CATEGORY2, CAT3_ID: item?.Profile?.CATEGORY3, CAT4_ID: item?.Profile?.CATEGORY4, CAT5_ID: item?.Profile?.CATEGORY5, SCRIPTDECODE_ID: item?.SCRIPTDECODE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER })))
			setModalLoad(false)
		} else {
			console.log('something went wrong')
			setModalLoad(false)
		}
	}

	const GetConsumerIncolumeName = async (isbetween, col, colname, value) => {
		setModelId(`${colname}`)
		setModalLoad(true)
		setIsModalVisible(true)

		console.log(Profile);
		const data = await onGetConsumerIncolumeName({ isbetween: isbetween, colName: col, value: value, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, ins_no: Profile.ins_no, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
		if (data.success === true) {
			setTableDataInApi(data.data.data)

			setModalLoad(false)


		} else {
			console.log('somthing went wrong')
			setModalLoad(false)

		}
	}
	const onExpandButton = (tableName, col, header) => {
		if (header == 'Installation Date Wise Chart') {
			setExpireDate({ start_date: '', endDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000) })
			setisExpandedModalOpen(true)
		}
		else {
			ongetStartAndEndDateByTable(tableName, col)
		}
		// onGetTableNameWiseChartDataBy15Date()
		setExpandedModelHeader(header)
	}

	const onExpandConsumptionButton = (tableName, col, header) => {
		ongetConsumptionStartAndEndDateByTable(tableName, col)
		// onGetTableNameWiseChartDataBy15Date()
		setExpandedModelHeader(header)
	}
	const ongetConsumptionStartAndEndDateByTable = async (tableName, col) => {
		console.log("Wanted to check if this is calling or not")
		document.body.style.cursor = 'went'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {

			console.log("Expiry Dates == ", data.data)
			setExpireDate(data.data)
			setisConsumptionModal(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something went wrong")
			document.body.style.cursor = 'default'
		}
	}
	const ongetStartAndEndDateByTable = async (tableName, col) => {
		console.log("Wanted to check if this is calling or not")
		document.body.style.cursor = 'went'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {

			console.log("Expiry Dates == ", data.data)
			setExpireDate(data.data)
			setisExpandedModalOpen(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something went wrong")
			document.body.style.cursor = 'default'
		}
	}
	var [CommandJson, setCommandJson] = useState({})
	var [CommandmsgJson, setCommandmsgJson] = useState({})
	var [HourlymsgJson, setHourlymsgJson] = useState({})
	var [Status, setStatus] = useState('')
	var [HourlyStatus, setHourlyStatus] = useState('')
	useEffect(() => {
		socket.on('mqttdailydatamonitorresponse', (massges) => {
			console.log(massges);
			setCommandmsgJson(massges)
		})
		socket.on('mqttHourlydatamonitorresponse', (massges) => {
			console.log(massges);
			setHourlymsgJson(massges)
		})
		onMonitoringScriptStatus()
		onHourlyMonitoringScriptStatus()
	}, []);
	function getSecondsTodayBydate(ts) {
		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
	}

	const onMonitoringScriptStatus = async () => {
		const data = await MonitoringScriptStatus()
		if (data.success == true) {
			if (data.data.status == 'IDLE') {
				setStatus('')
				onLetestTime()
			} else if (data.data.status == 'RUNNING') {
				setStatus('Pending')
			} else {
				setStatus(data.data.status)

			}
		} else {

		}
	}
	const onHourlyMonitoringScriptStatus = async () => {
		const data = await HourlyMonitoringScriptStatus()
		if (data.success == true) {
			if (data.data.status == 'IDLE') {
				setHourlyStatus('')
				onHourlyLetestTime()
			} else if (data.data.status == 'RUNNING') {
				setHourlyStatus('Pending')
			} else {
				setHourlyStatus(data.data.status)
			}
		} else {

		}
	}
	const onMeterDailyDataScripUpdate = async () => {
		swal.fire({
			title: "Do you want to run 'Daily Script'?",
			text: "",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data = await MeterDailyDataScripUpdate()
				if (data.data.status == 'RUNNING') {
					swal.fire('', 'Script Started', 'success')
				} else {
					swal.fire('', data.data.status, 'error')
				}
			}
		})
	}

	const onMeterHourlyDataScripUpdate = async () => {
		swal.fire({
			title: "Do you want to run 'Hourly Script'?",
			text: "",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data = await MeterHourlyDataScripUpdate()
				if (data.success == true) {
					if (data.data.status == 'RUNNING') {
						swal.fire('', 'Script Started', 'success')
					} else {
						swal.fire('', data.data.status, 'error')
					}
				} else {

				}
			}
		})
	}

	const onLetestTime = async () => {
		const data = await onGetLetestTime()
		if (data.success) {
			setStatus(data?.data?.data[0]?.ENTERYTS)
		} else {

		}
	}
	const onHourlyLetestTime = async () => {
		const data = await onGetHourlyLetestTime()
		if (data.success) {
			setHourlyStatus(data?.data?.data[0]?.ENTERYTS)
		} else {

		}
	}
	const [expandedChartData, setexpandedChartData] = useState({ total: { daily_success: [], daily_fail: [], SRNO: [] }, date: [] })
	const [dates, setDates] = useState({ start: '', end: '' })
	const [ChartLoader, setChartLoader] = useState(false)
	const [expandedChartINSData, setexpandedChartINSData] = useState({ total: { instantaneous_success: [], instantaneous_fail: [], SRNO: [] }, date: [] })


	const onGetexInstantData = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		if (expandedModelHeader == 'GSM EVC/MC Meter Daily Data Count') {
			const data = await getMeterGSMChartByDateSlection({ start: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
			if (data.success === true) {
				const ChartArray = data.data.data;
				const obj = { daily_success: ChartArray.map((item) => (item?.daily_success || 0)), daily_fail: ChartArray.map((item) => (item?.daily_fail || 0)), inactive_count: ChartArray.map((item) => (item?.inactive_count || 0)), undermantan_count: ChartArray.map((item) => (item?.undermantan_count || 0)), pendding_count: ChartArray.map((item) => (item?.pendding_count || 0)), SRNO: ChartArray.map((item) => (item?.SRNO)) }
				setexpandedChartData({ total: obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT)) })
				setChartLoader(false)
			} else {
				console.log('something went wrong')
				setChartLoader(false)
			}
		} else if (expandedModelHeader == 'Battery Percentage') {
			const data = await getMeterDashGSMBrtRtcDriffChartSelectByDate({ start: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, bat_diff_val: varSetting?.BAT, RTC_diff_val: varSetting?.RTC })
			if (data.success === true) {
				const ChartArray = data.data.data;
				const obj = { instantaneous_success: ChartArray.map((item) => (item?.BAT_SUCCESS || 0)), instantaneous_fail: ChartArray.map((item) => (item?.BAT_FAIL || 0)), SRNO: ChartArray.map((item) => (item?.SRNO)) }
				setexpandedChartINSData({ total: obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT)) })
				setChartLoader(false)
			} else {
				console.log('something went wrong')
				setChartLoader(false)
			}
		} else if (expandedModelHeader == 'RTC Time Difference') {
			const data = await getMeterDashGSMBrtRtcDriffChartSelectByDate({ start: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, bat_diff_val: varSetting?.BAT, RTC_diff_val: varSetting?.RTC })
			if (data.success === true) {
				const ChartArray = data.data.data;
				const obj = { instantaneous_success: ChartArray.map((item) => (item?.RTC_SUCCESS || 0)), instantaneous_fail: ChartArray.map((item) => (item?.RTC_FAIL || 0)), SRNO: ChartArray.map((item) => (item?.SRNO)) }
				setexpandedChartINSData({ total: obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT)) })
				setChartLoader(false)
			} else {
				console.log('something went wrong')
				setChartLoader(false)
			}
		} else {
			const data = await getMeterDashGSMInstantaneousChartBySelectDate({ start: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
			if (data.success === true) {
				const ChartArray = data.data.data;
				const obj = { instantaneous_success: ChartArray.map((item) => (item?.instantaneous_success || 0)), instantaneous_fail: ChartArray.map((item) => (item?.instantaneous_fail || 0)), SRNO: ChartArray.map((item) => (item?.SRNO)) }
				setexpandedChartINSData({ total: obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT)) })
				setChartLoader(false)
			} else {
				console.log('something went wrong')
				setChartLoader(false)
			}
		}
	}

	const onGetexConsumptionData = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		const data = await getAllGSMConsumptionChartBySelectDate({ start: expandedModelHeader == 'GSM EVC/MC Meter Catogarized Consumption' ? moment(dates.start, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD") : moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), end: expandedModelHeader == 'GSM EVC/MC Meter Catogarized Consumption' ? moment(dates.end, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD") : moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ins_no: Profile.ins_no, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
		if (data.success === true) {
			const ChartArray = data.data.data;
			const obj = { CONSUMPTION: ChartArray.map((item) => (item?.CONSUMPTION)), CONSUMPTION0: ChartArray.map((item) => (item?.CONSUMPTION0)), CONSUMPTION_NULL: ChartArray.map((item) => (item?.CONSUMPTION_NULL)), QMAX_COUNT: ChartArray.map((item) => (item?.QMAX_COUNT)), QMAX_NULL_COUNT: ChartArray.map((item) => (item?.QMAX_NULL_COUNT)), QMAX_0_COUNT: ChartArray.map((item) => (item?.QMAX_0_COUNT)), QMIN_COUNT: ChartArray.map((item) => (item?.QMIN_COUNT)), QMIN_NULL_COUNT: ChartArray.map((item) => (item?.QMIN_NULL_COUNT)), QMIN_0_COUNT: ChartArray.map((item) => (item?.QMIN_0_COUNT)), QAVG_COUNT: ChartArray.map((item) => (item?.QAVG_COUNT)), QAVG_NULL_COUNT: ChartArray.map((item) => (item?.QAVG_NULL_COUNT)), QAVG_0_COUNT: ChartArray.map((item) => (item?.QAVG_0_COUNT)), SRNO: ChartArray.map((item) => (item?.SRNO)), CONS_more1000: ChartArray.map((item) => (item?.CONS_more1000)), CONS_between1000100: ChartArray.map((item) => (item?.CONS_between1000100)), CONS_between100: ChartArray.map((item) => (item?.CONS_between100)) }
			setexpandedChartData({ total: obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT)) })
			setChartLoader(false)
		} else {
			console.log('something went wrong')
			setChartLoader(false)
		}

	}

	const onGetexHourlyConsumptionData = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY HH').format('YYYY-MM-DD HH'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY HH').format('YYYY-MM-DD HH'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		const data = await getAllGSMHourlyConsumptionChartByDate({ type: 'expand', start_date: moment(dates.start, 'DD-MM-YYYY HH').format('YYYY-MM-DD HH'), end_date: moment(dates.end, 'DD-MM-YYYY HH').format('YYYY-MM-DD HH'), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
		if (data.success === true) {
			const ChartArray = data.data.data;
			const obj = { CONSUMPTION: ChartArray.map((item) => (item?.CONSUMPTION)), CONSUMPTION0: ChartArray.map((item) => (item?.CONSUMPTION0)), CONSUMPTION_NULL: ChartArray.map((item) => (item?.CONSUMPTION_NULL)), QMAX_COUNT: ChartArray.map((item) => (item?.QMAX_COUNT)), QMAX_NULL_COUNT: ChartArray.map((item) => (item?.QMAX_NULL_COUNT)), QMAX_0_COUNT: ChartArray.map((item) => (item?.QMAX_0_COUNT)), QMIN_COUNT: ChartArray.map((item) => (item?.QMIN_COUNT)), QMIN_NULL_COUNT: ChartArray.map((item) => (item?.QMIN_NULL_COUNT)), QMIN_0_COUNT: ChartArray.map((item) => (item?.QMIN_0_COUNT)), QAVG_COUNT: ChartArray.map((item) => (item?.QAVG_COUNT)), QAVG_NULL_COUNT: ChartArray.map((item) => (item?.QAVG_NULL_COUNT)), QAVG_0_COUNT: ChartArray.map((item) => (item?.QAVG_0_COUNT)), SRNO: ChartArray.map((item) => (item?.SRNO)), CONS_more1000: ChartArray.map((item) => (item?.CONS_more1000)), CONS_between1000100: ChartArray.map((item) => (item?.CONS_between1000100)), CONS_between100: ChartArray.map((item) => (item?.CONS_between100)) }
			setexpandedChartData({ total: obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY hh:mm:ss A') : item.CREATED_AT)) })
			setChartLoader(false)
		} else {
			console.log('something went wrong')
			setChartLoader(false)
		}

	}

	// useEffect(() => {
	// 	console.log(CommandmsgJson, CommandJson);
	// 	if (CommandmsgJson?.REFRESH_STATUS == 'START' && CommandJson?.MSGID == CommandmsgJson?.MSGID) {
	// 		swal.fire('', 'Script Started', 'success')
	// 	} else if (CommandmsgJson?.REFRESH_STATUS == "SUCCESS" && CommandJson?.MSGID == CommandmsgJson?.MSGID) {
	// 		swal.fire('', CommandmsgJson?.REFRESH_STATUS, 'success')
	// 	} else if (CommandJson?.MSGID && CommandJson?.MSGID == CommandmsgJson?.MSGID) {
	// 		if (CommandmsgJson?.REFRESH_STATUS == 'IDLE') {
	// 			setStatus('')
	// 			onLetestTime()
	// 		} else if (CommandmsgJson?.REFRESH_STATUS == 'RUNNING') {
	// 			setStatus('Pending')
	// 		} else {
	// 			swal.fire('', CommandmsgJson?.REFRESH_STATUS, 'error')
	// 		}
	// 	}
	// }, [CommandmsgJson])
	// useEffect(() => {
	// 	console.log(HourlymsgJson, CommandJson);
	// 	if (HourlymsgJson?.REFRESH_STATUS == 'START' && CommandJson?.MSGID == HourlymsgJson?.MSGID) {
	// 		swal.fire('', 'Script Started', 'success')
	// 	} else if (HourlymsgJson?.REFRESH_STATUS == "SUCCESS" && CommandJson?.MSGID == HourlymsgJson?.MSGID) {
	// 		swal.fire('', HourlymsgJson?.REFRESH_STATUS, 'success')
	// 	} else if (CommandJson?.MSGID && CommandJson?.MSGID == HourlymsgJson?.MSGID) {
	// 		if (HourlymsgJson?.REFRESH_STATUS == 'IDLE') {
	// 			setHourlyStatus('')
	// 			onHourlyLetestTime()
	// 		} else if (HourlymsgJson?.REFRESH_STATUS == 'RUNNING') {
	// 			setHourlyStatus('Pending')
	// 		} else {
	// 			swal.fire('', HourlymsgJson?.REFRESH_STATUS, 'error')
	// 		}
	// 	}
	// }, [HourlymsgJson])
	const exInstantData = {
		labels: expandedChartData?.date.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "DailyData Success",
				data: expandedChartData?.total?.daily_success,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "DailyData Fail",
				data: expandedChartData?.total?.daily_fail,
				barPercentage: 1.0,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
			},
			{
				label: "Inactive",
				data: expandedChartData?.total?.inactive_count,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
			},
			{
				label: "Under Maintanance",
				data: expandedChartData?.total?.undermantan_count,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 143, 251)',
				borderRadius: 5,
			},
			{
				label: "Pending Configuration",
				data: expandedChartData?.total?.pendding_count,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(254, 176, 25)',
				borderRadius: 5,
			}
		],

	};

	const exConsumeptionAnalitys = {
		labels: ['consumption more than 1000 SCM', 'consumption between 1000 to 100 SCM', "consumption between 100 to <0 Scm", "zero consumption"],
		datasets: [
			{
				label: "",
				data: [expandedChartData?.total?.CONS_more1000?.at(-1), expandedChartData?.total?.CONS_between1000100?.at(-1), expandedChartData?.total?.CONS_between100?.at(-1), expandedChartData?.total?.CONSUMPTION0?.at(-1)],
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: ['rgb(0, 104, 138)', 'rgb(51, 181, 229)', 'rgb(255, 69, 96)', 'rgb(254 176 25)'],
				borderRadius: 5,
			}
		],
	};

	const exConsumptionData = {
		labels: expandedChartData?.date.map((item) => (moment(item, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY (ddd)'))),
		datasets: [
			{
				label: "Consumption",
				data: expandedChartData?.total?.CONSUMPTION,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "0 Consumption (Count)",
				data: expandedChartData?.total?.CONSUMPTION0,
				barPercentage: 1.0,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
			},
			{
				label: "Null Data (Count)",
				data: expandedChartData?.total?.CONSUMPTION_NULL,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
			}
		],

	};

	const exConsumptionHourlyData = {
		labels: expandedChartData?.date.map((item) => (moment(item, 'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY hh:mm:ss A (ddd)'))),
		datasets: [
			{
				label: "Consumption",
				data: expandedChartData?.total?.CONSUMPTION,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			}
		],

	};

	const exQMAXData = {
		labels: expandedChartData?.date.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "QMAX",
				data: expandedChartData?.total?.QMAX_COUNT,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			// {
			// 	label: "0 QMAX (Count)",
			// 	data: expandedChartData?.total?.QMAX_0_COUNT,
			// 	barPercentage: 1.0,
			// 	backgroundColor: 'rgb(51, 181, 229)',
			// 	borderRadius: 5,
			// },
			// {
			// 	label: "Null Data IN QMAX (Count)",
			// 	data: expandedChartData?.total?.QMAX_NULL_COUNT,
			// 	barPercentage: 1,
			// 	categoryPercentage:0.6,
			// 	backgroundColor: 'rgb(255, 69, 96)',
			// 	borderRadius: 5,
			// }
		],

	};

	const exQMINData = {
		labels: expandedChartData?.date.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "QMIN",
				data: expandedChartData?.total?.QMIN_COUNT,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "0 QMIN (Count)",
				data: expandedChartData?.total?.QMIN_0_COUNT,
				barPercentage: 1.0,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
			},
			{
				label: "Null Data IN QMIN (Count)",
				data: expandedChartData?.total?.QMIN_NULL_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
			}
		],

	};

	const exQAVGData = {
		labels: expandedChartData?.date.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "QAVG",
				data: expandedChartData?.total?.QAVG_COUNT,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "0 QAVG (Count)",
				data: expandedChartData?.total?.QAVG_0_COUNT,
				barPercentage: 1.0,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
			},
			{
				label: "Null Data IN QAVG (Count)",
				data: expandedChartData?.total?.QAVG_NULL_COUNT,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255, 69, 96)',
				borderRadius: 5,
			}
		],

	};

	const optionsExpanded = useMemo(() => ({
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {
					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},
				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			// decimation: {
			// 	enabled: true,
			// 	algorithm: 'lttb',
			// 	samples: 800,
			// 	threshold: 800
			// },
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					// onZoomComplete: ({
					// 	chart
					// }) => {
					// 	const xMin = chart.scales.x.getLabelForValue(chart.scales.x.min);
					// 	const xMax = chart.scales.x.getLabelForValue(chart.scales.x.max);
					// 	const yMin = chart.scales.y.min;
					// 	const yMax = chart.scales.y.max;
					// 	if (chart.scales.x._valueRange === chart.data.labels.length) {
					// 		setzoomOutEnable(false)
					// 		console.log("disable Zoom Out")
					// 	} else {
					// 		setzoomOutEnable(true)
					// 	}
					// 	if (chart.scales.x.min === chart.scales.x.max) {
					// 		setzoomInEnable(false)
					// 		console.log("disable Zoom in")
					// 	}
					// 	else {
					// 		setzoomInEnable(true)
					// 	}
					// 	// console.log("x values min max == ", chart.scales.x.min, chart.scales.x.max)
					// 	// console.log("Zoom === ", xMin, xMax, yMin, yMax)
					// },
					wheel: {
						enabled: true,
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context?.chart?.width;
					var max = context?.chart?.scales?.x?.ticks?.length
					return {
						// size: w < 512 ? 8 : 10,
						size: max < 15 ? 10 : 8
					};
				},

			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	}), [expandedChartData])



	const exInstantaneousData = {
		labels: expandedChartINSData?.date.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "Instantaneous Success",
				data: expandedChartINSData?.total?.instantaneous_success,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "Instantaneous Fail",
				data: expandedChartINSData?.total?.instantaneous_fail,
				barPercentage: 1.0,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
			}
		],

	};

	const exBATData = {
		labels: expandedChartINSData?.date.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "Battery Percentage(>5)",
				data: expandedChartINSData?.total?.instantaneous_success,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "Battery Percentage(<5)",
				data: expandedChartINSData?.total?.instantaneous_fail,
				barPercentage: 1.0,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
			}
		],

	};

	const exRTCData = {
		labels: expandedChartINSData?.date.map((item) => ([item + ` (${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: "RTC Time(<10)",
				data: expandedChartINSData?.total?.instantaneous_fail,
				barPercentage: 1.0,
				backgroundColor: 'rgb(51, 181, 229)',
				borderRadius: 5,
			},
			{
				label: "RTC Time(>10)",
				data: expandedChartINSData?.total?.instantaneous_success,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			}
		],

	};

	const [zoomInEnable, setzoomInEnable] = useState(false)
	const [zoomOutEnable, setzoomOutEnable] = useState(false)
	const expandChartRef = useRef()
	const myChart = expandChartRef.current;
	let midValue = 0
	if (myChart != undefined || myChart != null) {
		const xMin = myChart?.scales?.x?.min;
		const xMax = myChart?.scales?.x?.max;
		console.log("minMax == ", xMin, xMax)
		midValue = xMax / 2
	}

	const zoomIn = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(1.2);
		}
	}
	const zoomOut = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(0.8);
		}
	}

	const panLeft = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: 400 }, undefined, 'default');
		}
	}
	const panRight = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: -400 }, undefined, 'default');
		}
	}
	useEffect(() => {
		if (myChart != undefined || myChart != null) {
			if (myChart?.config?._config?.type != 'pie') {
				myChart?.zoomScale('x', { min: midValue - 3, max: midValue + 3 }, 'default')
				console.log("midaVAlue in setTimeout == ", Math.round(midValue))
			}
			// myChart.zoom(1.2);
			// myChart.pan({
			// 	x: 500
			// }, undefined, 'default');

			// myChart.pan({
			// 	x: - (Number.MAX_SAFE_INTEGER)
			// }, undefined, 'default');

		}
	}, [myChart])

	const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString })
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString })
	};

	const handleExpandedModal = () => {
		setisExpandedModalOpen(false);
		setisConsumptionModal(false)
		setexpandedChartData({ total: {}, date: [] })

		if (myChart != undefined) {
			myChart.destroy()
		}
		setChartLoader(false)
		setDates({ start: '', end: '' })
	}

	useEffect(() => {
		if (report.start_date !== '' && report.end_date !== '' && reportType != '') {
			setGenrateBtn(false)
		} else {
			setGenrateBtn(true)
		}
	}, [report.start_date, report.end_date, reportType])

	const ongetGenretedAllGSMReport = async () => {
		if (report.start_date !== '' && report.end_date !== '' && reportType != '') {
			const x = new Date(moment(report.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
			const y = new Date(moment(report.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
			console.log(x > y, x, y);
			if (x > y) {
				setReportLoader(false)

				return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
			}
			setReportLoader(true)
			const data = await getMeterGSMChartByDateSlection({ start: moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
			if (data.success === true) {
				const ChartArray = data.data.data;
				var Reportdata = []
				var headers = { "Date": 'Date', "Daily Success": 'Daily Success', "Daily Fail": 'Daily Fail', "Inactive": 'Inactive', "Undermaintanace": 'Undermaintanace', "Pending Configuration": 'Pending Configuration', "Total Consumer": 'Total Consumer', 'Performance(%)': 'Performance(%)', 'Filter': 'Filter' }
				const filter_obj = { CATEGORY0: AccounData.filter((item) => item?.UNIQUE_ID == Profile.account)[0]?.CATEGORY0 || '', CATEGORY1: GaData.filter((item) => item?.CATEGORY1_UNIQUE_ID == Profile.ga)[0]?.CATEGORY1_NAME || '', CATEGORY2: CircleData.filter((item) => item?.CATEGORY2_UNIQUE_ID == Profile.circle)[0]?.CATEGORY2_NAME || '', CATEGORY3: DivisionData.filter((item) => item?.CATEGORY3_UNIQUE_ID == Profile.division)[0]?.CATEGORY3_NAME || '', CATEGORY4: SubdevisionData.filter((item) => item?.CATEGORY4_UNIQUE_ID == Profile.subdivision)[0]?.CATEGORY4_NAME || '', CATEGORY5: AreaData.filter((item) => item?.UNIQUE_ID == Profile.area)[0]?.NAME || '' }
				ChartArray.map((item, id) => {
					var TotalC = item?.daily_success + item?.daily_fail + item?.inactive_count + item?.undermantan_count + item?.pendding_count
					Reportdata.push({ "Date": item.CREATED_AT.split('T')[1] ? moment.utc(item.CREATED_AT).format('DD-MM-YYYY') : item.CREATED_AT, "Daily Success": item?.daily_success || 0, "Daily Fail": item?.daily_fail || 0, "Inactive": item?.inactive_count || 0, "Undermaintanace": item?.undermantan_count || 0, "Pending Configuration": item?.pendding_count || 0, "Total Consumer": TotalC, "Performance(%)": parseFloat((item?.daily_success * 100) / (TotalC - (item?.inactive_count + item?.undermantan_count + item?.pendding_count))).toFixed(2), 'Filter': JSON.stringify(filter_obj) })
					if (id == (data.data.data.length - 1)) {
						if (reportType === 'csv') {
							exportCSVFile(headers, Reportdata, `GSM_Meter_Daily_Data_Count_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')')
							setReportLoader(false)
						} else {
							exportExcelDownload(Reportdata, `GSM_Meter_Daily_Data_Count_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')')
							setReportLoader(false)
						}
					}
				})

				// setChartLoader(false)
			} else {
				setReportLoader(false)

				console.log('something went wrong')
				// setChartLoader(false)
			}
		} else {
			setReportLoader(false)
			return toast.warn('Please Fill Proper Details')
		}
	}

	const Download_Logs = () => {
		var download_array = []
		TableData.map((item, id) => {
			var obj = { "SRNO": id + 1, "Consumer Name": item?.address_compony_name, "Modem Serial No": item?.DEVICE_SERIAL_NO, "Installation Number": item?.INSTALLATION_NUMBER, "EVC Type": ModemData.filter((met) => (met?.METER_UNIQUE_ID == (item?.SCRIPTDECODE_ID?.split('_')[1])))[0]?.METER_PROFILE_NAME || '', ACCOUNT: AllCategory?.category0.filter((inac) => (inac?.UNIQUE_ID === item?.CAT0_ID))[0]?.CATEGORY0, CATEGORY1: AllCategory?.category1.filter((inac) => (inac?.CATEGORY1_UNIQUE_ID === item?.CAT1_ID))[0]?.CATEGORY1_NAME, CATEGORY2: AllCategory?.category2.filter((inac) => (inac?.CATEGORY2_UNIQUE_ID === item?.CAT2_ID))[0]?.CATEGORY2_NAME, CATEGORY3: AllCategory?.category3.filter((inac) => (inac?.CATEGORY3_UNIQUE_ID === item?.CAT3_ID))[0]?.CATEGORY3_NAME, CATEGORY4: AllCategory?.category4.filter((inac) => (inac?.CATEGORY4_UNIQUE_ID === item?.CAT4_ID))[0]?.CATEGORY4_NAME, CATEGORY5: AllCategory?.category5.filter((inac) => (inac?.UNIQUE_ID === item?.CAT5_ID))[0]?.NAME }

			if (ModelId?.split('(')[0] == 'Consumption' || ModelId?.split('(')[0] == '0 Consumption' || ModelId?.split('(')[0] == 'Null Data Count' || ModelId?.split('(')[0] == 'QMIN' || ModelId?.split('(')[0] == '0 QMIN' || ModelId?.split('(')[0] == 'Null Data IN QMIN Count' || ModelId?.split('(')[0] == 'QMAX' || ModelId?.split('(')[0] == '0 QMAX' || ModelId?.split('(')[0] == 'Null Data IN QMAX Count' || ModelId?.split('(')[0] == 'QAVG' || ModelId?.split('(')[0] == '0 QAVG' || ModelId?.split('(')[0] == 'Null Data IN QAVG Count') {
				obj = { ...obj, Consumption: item?.CONSUMPTION }
			}
			download_array.push(obj)
		})
		exportExcelDownload(download_array, ModelId + (new Date().valueOf()))
	}

	const onHandaleChange = (e, data, id) => {
		if (e.target.checked) {
			var dash_info = DashTabs;
			var obj = JSON.parse(data?.view_json || '{}')
			console.log('step1 true', obj)
			// obj = obj[card_id] || { ...obj }
			console.log('step2 true', obj)
			obj[card_id] = { ...obj[card_id] | {}, display: true, bar_pemission: [...CardBar.map(item => item?.name)] };
			dash_info[id].view_json = JSON.stringify(obj)
			setDashTabs([...dash_info])
		} else {
			var dash_info = DashTabs
			var obj = JSON.parse(data?.view_json || '{}')
			console.log('step1 false', obj)
			// obj = obj[card_id] || { ...obj }
			console.log('step2 false', obj)
			obj[card_id] = { ...obj[card_id] || {}, display: false }
			dash_info[id].view_json = JSON.stringify(obj)
			setDashTabs([...dash_info])
		}
	}

	const onClickDownloadImg = async (element, imageFileName, type) => {
		const canvas = await html2canvas(element);
		const image = canvas.toDataURL(type == 'png' ? "image/png" : "image/jpg", 1.0);
		downloadImage(image, type == 'png' ? imageFileName + '.png' : imageFileName + '.jpg');
	};
	const downloadImage = (blob, fileName) => {
		const fakeLink = window.document.createElement("a");
		fakeLink.style = "display:none;";
		fakeLink.download = fileName;

		fakeLink.href = blob;

		document.body.appendChild(fakeLink);
		fakeLink.click();
		document.body.removeChild(fakeLink);

		fakeLink.remove();
	}

	const UpdateCustomDasboard = async () => {
		setDashLoader(true)
		const data = await UpdateDashboardAddCustomCard({ DashTabs, user_id: userInfo?.UNIQUE_ID })
		if (data.success == true) {
			toast.success('Added card in Dashboard successfully')
			setisDashModal(false)
			setcard_id('')
			setCardBar([])
			setDashLoader(false)
			onLoadCosutomDashboardList(permision?.ROLES_OTHER_PERMISSIONS)
		} else {
			toast.error('Server Issue')
			setDashLoader(false)
		}
	}

	const UpdateCustomDasboardInCardDelete = async (card_id) => {
		swal.fire({
			title: "Are you sure?",
			text: "card remove in this dashboard",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				// setCardRemoveLoader(true)
				let data_info = DashTabDetailById
				var viewJson = JSON.parse(data_info?.view_json || '{}');
				if (viewJson[card_id]) {
					viewJson[card_id].display = false
					data_info.view_json = JSON.stringify(viewJson)
				}
				const data = await UpdateDashboardAddCustomCard({ DashTabs: [data_info], user_id: userInfo?.UNIQUE_ID })
				if (data.success == true) {
					toast.success('Remove card in Dashboard successfully')
					// setCardRemoveLoader(false)
					onLoadCosutomDashboardList(permision?.ROLES_OTHER_PERMISSIONS)
				} else {
					toast.error('Server Issue')
					// setCardRemoveLoader(false)
				}
			}
		})
	}

	function findParent(json, targetValue, parentChain = []) {
		for (const node of json) {
			if (node.value === targetValue) {
				return [...parentChain, node.value];
			}

			if (node.children && node.children.length > 0) {
				const result = findParent(node.children, targetValue, [...parentChain, node.value]);
				if (result) {
					return result;
				}
			}
		}
		return null; // Node not found
	}
	const onChangeTreeSelect = (newValue, tree) => {
		const array_parent = findParent(TreeSelection, newValue) || []
		setProfile({ account: array_parent[0] || '', ga: array_parent[1] || '', circle: array_parent[2] || '', division: array_parent[3] || '', subdivision: array_parent[4] || '', area: array_parent[5] || '' })
	};

	const onChangeTreeSelectInDataRece = (newValue, tree) => {
		console.log(newValue, tree)
		var cat_obj = {
			category0: [],
			category1: [],
			category2: [],
			category3: [],
			category4: [],
			category5: [],
		}
		var parantCatList = []
		function findParentName(json, targetValue, parentChain = []) {
			for (const node of json) {
				if (node.value === targetValue) {
					return [...parentChain, node.name];
				}
				if (node.children && node.children.length > 0) {
					const result = findParentName(node.children, targetValue, [...parentChain, node.name]);
					if (result) {
						return result;
					}
				}
			}

			return null; // Node not found
		}
		newValue.map((item) => {
			var parantList = findParentName(TreeSelection, item) || []
			const array_parent = findParent(TreeSelection, item) || []
			parantCatList.push(parantList)
			cat_obj = {
				category0: array_parent[0] ? [...cat_obj?.category0, array_parent[0]].filter(function (value, index, array) {
					return array.indexOf(value) === index;
				}) : [...cat_obj?.category0],
				category1: array_parent[1] ? [...cat_obj?.category1, array_parent[1]].filter(function (value, index, array) {
					return array.indexOf(value) === index;
				}) : [...cat_obj?.category1],
				category2: array_parent[2] ? [...cat_obj?.category2, array_parent[2]].filter(function (value, index, array) {
					return array.indexOf(value) === index;
				}) : [...cat_obj?.category2],
				category3: array_parent[3] ? [...cat_obj?.category3, array_parent[3]].filter(function (value, index, array) {
					return array.indexOf(value) === index;
				}) : [...cat_obj?.category3],
				category4: array_parent[4] ? [...cat_obj?.category4, array_parent[4]].filter(function (value, index, array) {
					return array.indexOf(value) === index;
				}) : [...cat_obj?.category4],
				category5: array_parent[5] ? [...cat_obj?.category5, array_parent[5]].filter(function (value, index, array) {
					return array.indexOf(value) === index;
				}) : [...cat_obj?.category5]
			}
		})
		setCategoryData(cat_obj)
		setCategoryName({ name: tree, ids: newValue, tree_cat: parantCatList })
		// setProfile({ account: array_parent[0] || '', ga: array_parent[1] || '', circle: array_parent[2] || '', division: array_parent[3] || '', subdivision: array_parent[4] || '', area: array_parent[5] || '' })
		console.log(cat_obj)
	};

	const onHandaleBarChange = (e, data, id) => {
		if (e.target.checked) {
			var dash_info = DashTabs;
			var obj = JSON.parse(data?.view_json || '{}')
			console.log('step1 true', obj)
			// obj = obj[card_id] || { ...obj }
			console.log('step2 true', obj)
			obj[card_id] = { ...obj[card_id], bar_pemission: [...obj[card_id]?.bar_pemission || [], e.target.name] };
			dash_info[id].view_json = JSON.stringify(obj)
			setDashTabs([...dash_info])
		} else {
			var dash_info = DashTabs
			var obj = JSON.parse(data?.view_json || '{}')
			console.log('step1 false', obj)
			// obj = obj[card_id] || { ...obj }
			console.log('step2 false', obj)
			obj[card_id] = { ...obj[card_id], bar_pemission: obj[card_id]?.bar_pemission?.filter((item) => (item !== e.target.name)) }
			dash_info[id].view_json = JSON.stringify(obj)
			setDashTabs([...dash_info])
		}
	}

	const onchangeDefaultLoadData = async (e, UID) => {
		setFilterDay(FilterDay?.map((item) => (item.card_id == UID ? { ...item, day_preference: e.target.value } : { ...item })))

		swal.fire({
			title: "Do you want to save the changes?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: "Save",
			denyButtonText: `Don't save`
		}).then(async (result) => {
			if (result.isConfirmed) {
				const data = await UserDashboardCardFilterStore({ user_id: userInfo?.UNIQUE_ID, day_preference: e.target.value, modem_type: 'GSM', card_id: UID })
				if (data.success == true) {
					toast.success('Save Filter Success')
					onGetFilterDaywise()
				} else {
					toast.error('Not Save Filter Server Issue')
				}
			} else if (result.isDenied) {

			}
			onChangesCardWiseFilter('', UID, false, new Number(e.target.value))
			setDefaultShowBar({ ...DefaultShowBar, [UID]: e.target.value })
		})
	}

	const onHandalSearchMulti = (newSearchTerms) => {
		setProfile({ ...Profile, ins_no: newSearchTerms });
	};

	const onItemCleared = (clearedIndex) => {
		const newSearchTerms = Profile?.ins_no?.filter((_, index) => index !== clearedIndex);
		onHandalSearchMulti(newSearchTerms);
	};
	const onClearAllItems = () => {
		onHandalSearchMulti([]);
	};

	const onClickAllImgDownloadPDF = async () => {
		const RefArray = [IMGCARD21, IMGCARD22, IMGCARD23, IMGCARD24, IMGCARD25, IMGCARD26, IMGCARD27, IMGCARD28, IMGCARD29, IMGCARD30, IMGCARD31]
		const RefArrayText = [card_json[21].CARD_NAME, card_json[22].CARD_NAME, card_json[23].CARD_NAME, card_json[24].CARD_NAME, card_json[25].CARD_NAME, card_json[26].CARD_NAME, card_json[27].CARD_NAME, card_json[28].CARD_NAME, card_json[29].CARD_NAME, card_json[30].CARD_NAME, card_json[31].CARD_NAME]
		var doc = new jsPDF('l', 'mm', 'a4');
		console.log(RefArray)
		for (let i = 0; i < RefArray.length; i++) {
			const item = RefArray[i];
			if (item.current) {
				const canvas = await html2canvas(item.current);
				const image = canvas.toDataURL("image/png", 1.0);
				const ratio = canvas.height / canvas.width;
				const contentWidth = doc.internal.pageSize.getWidth(); // make content full-width
				const contentHeight = contentWidth * ratio; // scale height proportionally to width
				doc.text(RefArrayText[i], 60, 22, {
					align: 'center'
				})
				doc.addImage(image, "png", 0, 28, contentWidth, contentHeight);

				if (i < RefArray.length - 1) {
					doc.addPage('l', 'mm', 'a4');
				}
			}
		}

		doc.save('gsm_EVC/MC_Meter_dashboard.pdf');
		setisRefDownload(false);

	}

	useEffect(() => {
		if (isRefDownload && activeTab == 'EVC/MC Meter Dashboard') {
			onClickAllImgDownloadPDF()
		}
	}, [isRefDownload])
	return (
		<>
			<Modal title={"Custom Dashboard permission add " + `(${Modal_card_name})`} visible={isDashModal} footer='' onCancel={() => {
				setisDashModal(false)
				setcard_id('')
				setCardBar([])
			}} width={1000} height={300}>
				<div className="modal-table">
					<table className="table border table-borderless text-center" id="table-1">
						<thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
							<tr>
								<th className="text-center">Dashboard Name</th>
								<th className="text-center">Add Card</th>
								<th className="text-center">Card Other Permission</th>
							</tr>
						</thead>
						<tbody id="tbody">
							<>
								{console.log(DashTabs)}
								{DashTabs.length > 0 ?
									DashTabs.map((data, id) => (
										<tr>
											<td>{data?.name}</td>
											<td><input type="checkbox" className="chk_MSSQL1" name="checkAll" onChange={(e) => { onHandaleChange(e, data, id) }} checked={JSON.parse(data?.view_json || '{}')[card_id]?.display || false} /></td>
											<Popover placement="bottom" title={<span>{data?.name}</span>} content={
												<>
													<table class="table table-borderless border">
														<thead className='border leading-8' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
															<tr >
																<th scope="col" class="thalignleft tw-font-normal text-start" style={{ width: "40%" }}>Lable</th>
																<th scope="col" class="thalign tw-font-normal text-start" style={{ width: "20%" }}>View</th>
															</tr>
														</thead>
														<tbody className='leading-8 ' >
															{CardBar?.map((perm) => (
																<tr >
																	<th scope='row' className='text-start tw-font-normal' style={{ height: "40px" }}>
																		{perm?.name}
																	</th>
																	<th className=' text-start font-normal' style={{ height: "40px" }}>
																		<input className='' type={"checkbox"} name={perm?.name} id={perm?.name}
																			onChange={(e) => (onHandaleBarChange(e, data, id))} checked={JSON.parse(data?.view_json || '{}')[card_id]?.bar_pemission?.includes(perm?.name)}></input>
																	</th>
																</tr>
															))}
														</tbody>
													</table>
												</>
											} trigger="click">
												<th className='tw-cursor-pointer tw-text-blue-600'>More permission</th>
											</Popover>
										</tr>
									)) : <tr></tr>}
							</>
						</tbody>
					</table>
					<div className="text-center">
						<a className={`btn btn-danger ${DashLoader === true ? 'btn-progress disabled' : ''} text-white`} onClick={UpdateCustomDasboard}>Submit</a>
					</div>
				</div>
			</Modal>
			<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={handleCancelIsmodal} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="form-row" style={{ marginLeft: "10px" }}>

									<><div className="form-group col-md-3" id="strdate">
										<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
										<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
											disabledDate={(current) => {
												return current.isAfter(moment())
											}} />
									</div>
										<div className="form-group col-md-3" id="enddate" >
											<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
										</div></>
									<div className="form-group col-md-3" id="grpeui">
										<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
										<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
											<option value="">Select Extension</option>
											<option value="xls">XLS</option>
											<option value="csv">CSV</option>
										</select>
									</div>
								</div>
								<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
								<div className="text-left">
									<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader == true ? 'disabled btn-progress' : ''} `} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
									<Link className="btn btn-light text-dark" onClick={handleCancelIsmodal}>Cancel</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
			<Modal title={expandedModelHeader} visible={isExpandedModalOpen} maskClosable={false} footer='' onCancel={handleExpandedModal} width={1500}>

				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker value={dates?.start !== '' ? moment(dates?.start, "DD-MM-YYYY") : null} onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment.utc(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker value={dates?.end !== '' ? moment(dates?.end, "DD-MM-YYYY") : null} onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment.utc(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
						</Space>

						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetexInstantData())}>Submit</a>
						</div>

					</div>

					{expandedChartData.date.length > 0 ?
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> You can also do Zoom and Scroll using your mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomInEnable} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomOutEnable} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>
						: ''}
				</div>
				{expandedModelHeader == 'GSM EVC/MC Meter Daily Data Count' && expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exInstantData} onClick={onClickEpand} /> </div> : ''}

				{expandedModelHeader == 'GSM EVC/MC Meter instantaneous Count' && expandedChartINSData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exInstantaneousData} onClick={onClickINSEpand} /> </div> : ''}

				{expandedModelHeader == 'Battery Percentage' && expandedChartINSData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exBATData} onClick={onClickBATpand} /> </div> : ''}

				{expandedModelHeader == 'RTC Time Difference' && expandedChartINSData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exRTCData} onClick={onClickRTCpand} /> </div> : ''}

				{expandedChartData.date.length == 0 && expandedChartINSData.date.length == 0 ? <h4 className="text-center">Data Not Found</h4> : ''}
			</Modal>

			<Modal title={expandedModelHeader} visible={isConsumptionModal} maskClosable={false} footer='' onCancel={handleExpandedModal} width={1500}>

				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							{expandedModelHeader != 'GSM EVC/MC Meter Catogarized Consumption' ? expandedModelHeader == 'GSM EVC/MC Hourly Meter Consumption' ? <div className="d-flex flex-column">Start In
								<DatePicker value={dates?.start !== '' ? moment(dates?.start, "DD-MM-YYYY HH") : null} onChange={(date, dateString) => { setDates({ ...dates, start: dateString, end: dateString }) }} format='DD-MM-YYYY HH' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date) >= current) || (moment(expireDate.end_date) <= current);
								}} showTime={{ format: 'HH' }} /></div> :
								<div className="d-flex flex-column">Start In
									<DatePicker value={dates?.start !== '' ? moment(dates?.start, "DD-MM-YYYY") : null} onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
										return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment.utc(expireDate.end_date).add(0, 'days') <= current);
									}} /></div> :
								<div className="d-flex flex-column">Start In
									<DatePicker value={dates?.start !== '' ? moment(dates?.start, "DD-MM-YYYY") : null} onChange={(date, dateString) => { setDates({ ...dates, start: dateString, end: dateString }) }} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
										return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment.utc(expireDate.end_date).add(0, 'days') <= current);
									}} /></div>}
							{expandedModelHeader != 'GSM EVC/MC Meter Catogarized Consumption' ? expandedModelHeader == 'GSM EVC/MC Hourly Meter Consumption' ? <div className="d-flex flex-column" >End In
								<DatePicker value={dates?.end !== '' ? moment(dates?.end, "DD-MM-YYYY HH") : null} onChange={onChanges} name='end' format='DD-MM-YYYY HH' disabledDate={(current) => {
									return (moment(expireDate.start_date) >= current) || (moment(expireDate.end_date) <= current);
								}} showTime={{ format: 'HH' }} /></div> : <div className="d-flex flex-column" >End In
								<DatePicker value={dates?.end !== '' ? moment(dates?.end, "DD-MM-YYYY") : null} onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment.utc(expireDate.end_date).add(0, 'days') <= current);
								}} /></div> : ''}
						</Space>

						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (expandedModelHeader == 'GSM EVC/MC Hourly Meter Consumption' ? onGetexHourlyConsumptionData() : onGetexConsumptionData())}>Submit</a>
						</div>

					</div>

					{expandedChartData.date.length > 0 ?
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> You can also do Zoom and Scroll using your mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomInEnable} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomOutEnable} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>
						: ''}
				</div>

				{expandedModelHeader == 'GSM EVC/MC Meter Consumption' && expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exConsumptionData} onClick={onClickConsumption} /> </div> : ''}
				{expandedModelHeader == 'GSM EVC/MC Hourly Meter Consumption' && expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exConsumptionHourlyData} /> </div> : ''}
				{expandedModelHeader == 'GSM EVC/MC Meter QMAX' && expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exQMAXData} /> </div> : ''}

				{expandedModelHeader == 'GSM EVC/MC Meter QMIN' && expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exQMINData} /> </div> : ''}

				{expandedModelHeader == 'GSM EVC/MC Meter QAVG' && expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exQAVGData} /> </div> : ''}

				{expandedModelHeader == 'GSM EVC/MC Meter Catogarized Consumption' && expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Pie ref={expandChartRef} options={optionsForChartJSPie} data={exConsumeptionAnalitys} /> </div> : ''}

				{expandedChartData.date.length == 0 && expandedChartINSData.date.length == 0 ? <h4 className="text-center">Data Not Found</h4> : ''}
			</Modal>
			<Modal title={<div className="d-flex"><h5 style={{ color: ColorText }}>{ModelId}</h5>{TableData.length > 0 ? <span className='btn btn-primary text-white ml-2' onClick={Download_Logs}><i className='fas fa-download'></i></span> : ''}{TableData.length > 0 ? <Link to={{ pathname: `All-GSM`, state: { detail: TableData.map((data) => (data?.UNIQUE_ID)), title: ModelId } }}><span className='btn btn-primary text-white ml-2'><i className='fas fa-up-right-from-square'></i></span></Link> : ''}</div>} maskClosable={false} className="my-modal-class" visible={isModalVisible} footer='' onCancel={handleCancel} width={1000} style={{ overlay: { zIndex: 1000 } }}>
				<div>
					<div className="modal-table">
						{ModalLoad ? (
							<div className="example">
								<Spin size="large" />
							</div>
						) : <table className="table border table-borderless" id="table-1">
							<thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
								<tr>
									<th className="text-center">SR NO.</th>
									<th >Consumer Name</th>
									<th className="text-center">Group</th>
									<th>Modem Serial No</th>
									<th className="text-center">EVC Type</th>
									<th className="text-center">Installation Number</th>
									{ModelId?.split('(')[0] == 'Consumption' || ModelId?.split('(')[0] == '0 Consumption' || ModelId?.split('(')[0] == 'Null Data Count' ? <th>Consumption</th> : ''}
									{ModelId?.split('(')[0] == 'QMIN' || ModelId?.split('(')[0] == '0 QMIN' || ModelId?.split('(')[0] == 'Null Data IN QMIN Count' ? <th>QMIN</th> : ''}
									{ModelId?.split('(')[0] == 'QMAX' || ModelId?.split('(')[0] == '0 QMAX' || ModelId?.split('(')[0] == 'Null Data IN QMAX Count' ? <><th>QMAX</th><th>Q_MAX_COUNT</th></> : ''}
									{ModelId?.split('(')[0] == 'QAVG' || ModelId?.split('(')[0] == '0 QAVG' || ModelId?.split('(')[0] == 'Null Data IN QAVG Count' ? <th>QAVG</th> : ''}
								</tr>
							</thead>
							<tbody id="tbody">
								<>
									{TableData.length > 0 ?
										TableData.map((data, id) => (
											<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id} onMouseEnter={() => {
												var element = document.getElementById(data?.UNIQUE_ID + 'GSM');
												element.classList.add('within');
												element.classList.remove('display-icon');
											}} onMouseLeave={() => {
												var element = document.getElementById(data?.UNIQUE_ID + 'GSM');
												element.classList.add('display-icon');
												element.classList.remove('within');
											}}>
												<td className="GSM_Link">
													<a className="!tw-text-[#1890ff]" onClick={() => onHandaleViewGSM(data?.UNIQUE_ID)}>{id + 1}</a>
													<a id={data?.UNIQUE_ID + 'GSM'} className='display-icon !tw-text-[#1890ff]' onClick={() => onHandaleViewGSM(data?.UNIQUE_ID)}><img src={exports} style={{ height: "10px" }} /></a></td>
												<td>{data?.address_compony_name}</td>
												<BootstrapTooltip title={<div>
													ACCOUNT:{AllCategory?.category0.filter((inac) => (inac?.UNIQUE_ID === data?.CAT0_ID))[0]?.CATEGORY0}
													<br />
													CATEGORY1:{AllCategory?.category1.filter((inac) => (inac?.CATEGORY1_UNIQUE_ID === data?.CAT1_ID))[0]?.CATEGORY1_NAME}
													<br />
													CATEGORY2:{AllCategory?.category2.filter((inac) => (inac?.CATEGORY2_UNIQUE_ID === data?.CAT2_ID))[0]?.CATEGORY2_NAME}
													<br />
													CATEGORY3:{AllCategory?.category3.filter((inac) => (inac?.CATEGORY3_UNIQUE_ID === data?.CAT3_ID))[0]?.CATEGORY3_NAME}
													<br />
													CATEGORY4:{AllCategory?.category4.filter((inac) => (inac?.CATEGORY4_UNIQUE_ID === data?.CAT4_ID))[0]?.CATEGORY4_NAME}
													<br />
													CATEGORY5:{AllCategory?.category5.filter((inac) => (inac?.UNIQUE_ID === data?.CAT5_ID))[0]?.NAME}
												</div>} placement='top'><td className="text-center"><b className='tw-font-semibold '>ACCOUNT:</b><br />{AllCategory?.category0.filter((inac) => (inac?.UNIQUE_ID === data?.CAT0_ID))[0]?.CATEGORY0}</td></BootstrapTooltip>
												<td>{data?.DEVICE_SERIAL_NO}</td>

												<td>{ModemData.filter((met) => (met?.METER_UNIQUE_ID == (data?.SCRIPTDECODE_ID?.split('_')[1])))[0]?.METER_PROFILE_NAME}</td>
												<td>{data?.INSTALLATION_NUMBER}</td>
												{ModelId?.split('(')[0] == 'Consumption' || ModelId?.split('(')[0] == '0 Consumption' || ModelId?.split('(')[0] == 'Null Data Count' ? <td>{data?.CONSUMPTION}</td> : ''}
												{ModelId?.split('(')[0] == 'QMIN' || ModelId?.split('(')[0] == '0 QMIN' || ModelId?.split('(')[0] == 'Null Data IN QMIN Count' ? <td>{data?.CONSUMPTION}</td> : ''}
												{ModelId?.split('(')[0] == 'QMAX' || ModelId?.split('(')[0] == '0 QMAX' || ModelId?.split('(')[0] == 'Null Data IN QMAX Count' ? <><td>{data?.CONSUMPTION}</td><td>{data?.Q_MAX_COUNT}</td></> : ''}
												{ModelId?.split('(')[0] == 'QAVG' || ModelId?.split('(')[0] == '0 QAVG' || ModelId?.split('(')[0] == 'Null Data IN QAVG Count' ? <td>{data?.CONSUMPTION}</td> : ''}
											</tr>
										)) : <tr></tr>}
								</>
							</tbody>
						</table>}
						{TableData.length == 0 && !ModalLoad && <h4 className="text-center">No Data Found</h4>}

					</div>
				</div>
			</Modal>
			<section class="section">
				<div class="section-body">
					{activeTab != 'Custom Dashboard' ? <div class="row">
						<div class="col-12">
							<div class="card ">
								<div class="card-body tw-flex tw-items-center tw-gap-3" style={{ padding: "2px 12px" }}>
									<Popover trigger="click" overlayStyle={{ width: '300px' }} content={<>
										<TreeSelect
											style={{ width: '100%' }}
											dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
											treeData={TreeSelection}
											placeholder="All Account"
											treeDefaultExpandAll
											allowClear
											onChange={onChangeTreeSelect}
										/>
									</>}>
										<div>
											<button class="btn btn-primary"> <i className="fas fa-filter"></i> </button>
										</div>
									</Popover>
									<div class="tw-flex tw-gap-2"
										style={{

											borderColor: "#e3e6fc !important",
											position: "relative",
											marginTop: "5px",
										}}>

										<div class="tw-w-full tw-min-w-[13rem]">
											<div class="form-group">
												<label htmlFor="inputPassword4">
													Account<span class="text-danger">*</span>
												</label>
												{/* <Select name='Consumertype' isMulti options={AccounData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																		{value:item.UNIQUE_ID,label:item.CATEGORY0}
																	) :{}
																)} onChange={(e) => { setProfile({ ...Profile, account: e.map((item) => (item.value)) }) }} /> */}
												<select
													class="form-control form-select accountoga ga_calueacc"
													name="accountid"
													required=""
													id="accountid"
													onChange={(e) => setProfile({ ...Profile, account: e.target.value, ga: "", circle: "", division: "", subdivision: "", area: "" })}
													value={Profile?.account}

												>
													<option value="">Select Account</option>
													{AccounData &&
														AccounData.map((item, id) =>
															permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																(items) => items === item.UNIQUE_ID
															).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																<option key={id} value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
															) : (
																""
															)
														)}
												</select>
											</div>
										</div>
										{Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] gagrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY1 || 'CATEGORY1'}</label>
													<select
														class="form-control form-select gatocircle ga_calue"
														name="address_location_d"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, ga: e.target.value, circle: "", division: "", subdivision: "", area: "" })}
														value={Profile?.ga}
													>
														<option value="">Select {NameObj?.CATEGORY1 || 'CATEGORY1'}</option>
														{GaData &&
															GaData.filter(item=>item.CATEGORY0==Profile.account).map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
																	(items) => items === item.CATEGORY1_UNIQUE_ID
																).length > 0 ||(item.CATEGORY0==Profile?.account)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																	<option key={id} value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] circlegrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY2 || 'CATEGORY2'}</label>
													<select
														class="form-control circletodivision form-select"
														name="circle_id"
														onChange={(e) => setProfile({ ...Profile, circle: e.target.value, division: "", subdivision: "", area: "" })}
														value={Profile?.circle}
													>
														<option value="">Select {NameObj?.CATEGORY2 || 'CATEGORY2'}</option>
														{CircleData &&
															CircleData.filter(item=>item.CATEGORY1==Profile.ga).map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
																	(items) => items === item.CATEGORY2_UNIQUE_ID
																).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																	<option key={id} value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] divisiongrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY3 || 'CATEGORY3'}</label>
													<select
														class="form-control divisiontosubdivision form-select"
														name="division_id"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, division: e.target.value, subdivision: "", area: "" })}
														value={Profile?.division}
													>
														<option value="">Select {NameObj?.CATEGORY3 || 'CATEGORY3'}</option>
														{DivisionData &&
															DivisionData.filter(item=>item.CATEGORY2==Profile.circle).map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
																	(items) => items === item.CATEGORY3_UNIQUE_ID
																).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																	<option key={id} value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] subdivisiongrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY4 || 'CATEGORY4'}</label>
													<select
														class="form-control subdivisiontoarea form-select"
														name="sub_division_id"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value, area: "" })}
														value={Profile?.subdivision}
													>
														<option value="">Select {NameObj?.CATEGORY4 || 'CATEGORY4'}</option>
														{SubdevisionData &&
															SubdevisionData.filter(item=>item.CATEGORY3==Profile.division).map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
																	(items) => items === item.CATEGORY4_UNIQUE_ID
																).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga&&item.CATEGORY2==Profile?.circle&&item.CATEGORY3==Profile?.division)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																	<option key={id} value={item.CATEGORY4_UNIQUE_ID}>
																		{item.CATEGORY4_NAME}
																	</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.subdivision !== "" &&
											Profile.division !== "" &&
											Profile.circle !== "" &&
											Profile.ga !== "" &&
											Profile.account !== "" && (
												<div class="tw-w-full tw-min-w-[12rem] areagrp">
													<div class="form-group">
														<label htmlFor="inputPassword4">{NameObj?.CATEGORY5 || 'CATEGORY5'}</label>
														<select
															class="form-control form-select lastarea cust_ara"
															name="area_id"
															id="area_id"
															onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
															value={Profile?.area}
														>
															<option value="">Select {NameObj?.CATEGORY5 || 'CATEGORY5'}</option>
															{AreaData &&
																AreaData.filter(item=>item.CATEGORY4==Profile.subdivision).map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga&&item.CATEGORY2==Profile?.circle&&item.CATEGORY3==Profile?.division&&item.CATEGORY4==Profile?.subdivision)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																		<option key={id} value={item.UNIQUE_ID}>{item.NAME}</option>
																	) : (
																		""
																	)
																)}
														</select>
													</div>
												</div>
											)}
										<div class="tw-w-full ">
											<div class="form-group">
												<label>Installation Number</label>
												<MultiInput
													placeholder="Search & enter Installation Number"
													onChange={onHandalSearchMulti}
													maxItems={null} // Allow any number of items
													onClear={onItemCleared}
													onClearAll={onClearAllItems}
												/>
											</div>
										</div>
									</div>
									<div className="d-flex">

										<button class={`btn btn-primary ml-2 ${!isDownloadBtnShow ? 'disabled btn-progress' : ''}`} onClick={onProfileWiseSearchClick}> <i className="fa fa-search"></i> </button>
									</div>
								</div>
							</div>

						</div>
					</div> : ''}
					<div className="row">
						<div className="tw-container tw-mx-[10px] ">
							<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6 mb-4">

								{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[18].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div className="tw-bg-white tw-rounded-lg tw-shadow-custom tw-overflow-hidden border-top !tw-border-t-2 !tw-border-t-red-600">
									<div className="tw-border-b tw-border-gray-200 tw-p-4">
										<div className="tw-flex tw-items-center tw-justify-between">
											<h2 className="tw-text-lg tw-font-semibold tw-text-gray-800">{card_json[18].CARD_NAME}</h2>
											<div className="tw-flex tw-space-x-2">
												{/* <button className="btn btn-info text-white btnedit mr-2">
                  <i class="fa-solid fa-filter"></i>
                </button>
                <button className="btn btn-info text-white btnedit mr-2">
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <button className="btn btn-info text-white btnedit mr-2">
                  <i class="fa-solid fa-gear"></i>
                </button> */}
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[18].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<div
													className="dropdown"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													style={{ padding: '0', float: 'right' }}

												><div className="btn btn-primary"><i className={`fas fa-gear`} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div></div><div
													className="dropdown-menu "
													x-placement="bottom-start"
													style={{
														position: "absolute",
														transform: "translate3d(488px, -50px, 0px)",
														top: "0px",
														left: "-110px",
														willChange: "transform",
													}}
												><div className="form-group mb-0 tw-p-2 " style={{ marginTop: -10 }}>
														<input type="number" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={(e) => (setvarSetting({ ...varSetting, BAT: parseFloat(e.target.value) }))} value={varSetting?.BAT} />
													</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onHandalSearchClick} /></div>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_instantaneous_monitor_graph', 'CREATED_AT', 'Battery Percentage') }} >Expand</a>

												<div class="dropdown d-inline mr-2">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft " x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[18].CARD_NAME)
															setcard_id(card_json[18].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[18].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="tw-p-4">
										<div className="tw-flex tw-justify-around tw-items-center tw-mb-2">
											<div className="tw-text-center">
												<p className="tw-text-2xl tw-font-bold tw-flex tw-items-center tw-justify-center !tw-mb-2 tw-cursor-pointer" onClick={() => onHandalGsmChartBATRTC('INSTANTANEOUS_SUCCESS_LIST', BATRTC?.SRNO, false, moment.utc(BATRTC?.CREATED_AT).format("YYYY-MM-DD"), 'gsm_instantaneous_monitor_graph', 'Greater than 5%', 0, card_json[18]?.U_ID)}>
													<PiBatteryVerticalHighLight className={`tw-mr-2 tw-text-green-500`}  />
													{BATRTC?.BAT_SUCCESS}
												</p>
												<p className="tw-text-sm tw-text-gray-500">Greater than {varSetting?.BAT}%</p>
											</div>
											<div className="tw-h-12 tw-w-px tw-bg-gray-200" />
											<div className="tw-text-center">
												<p className="tw-text-2xl tw-font-bold tw-flex tw-items-center tw-justify-center !tw-mb-2 tw-cursor-pointer" onClick={() => onHandalGsmChartBATRTC('INSTANTANEOUS_SUCCESS_LIST', BATRTC?.SRNO, false, moment.utc(BATRTC?.CREATED_AT).format("YYYY-MM-DD"), 'gsm_instantaneous_monitor_graph', 'Less than 5%', 1, card_json[18]?.U_ID)}>
													<PiBatteryVerticalLowLight className={`tw-mr-2 tw-text-red-500`} />
													{BATRTC?.BAT_FAIL}
												</p>	
												<p className="tw-text-sm tw-text-gray-500">Less than {varSetting?.BAT}%</p>
											</div>
										</div>
										<div className="tw-flex tw-items-center tw-text-sm tw-text-gray-500">
											<FaClock className="tw-mr-2" />
											<BootstrapTooltip title={BATRTC?.CREATED_AT ? moment.utc(BATRTC?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A") : ''}><span>{BATRTC?.CREATED_AT ? moment.utc(BATRTC?.CREATED_AT).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</span></BootstrapTooltip>
										</div>
									</div>
								</div> : ''}

								{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[19].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div className="tw-bg-white tw-rounded-lg tw-shadow-custom tw-overflow-hidden border-top !tw-border-t-2 !tw-border-t-red-600">
									<div className="tw-border-b tw-border-gray-200 tw-p-4">
										<div className="tw-flex tw-items-center tw-justify-between">
											<h2 className="tw-text-lg tw-font-semibold tw-text-gray-800">{card_json[19].CARD_NAME}</h2>
											<div className="tw-flex tw-space-x-2">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[19].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<div
													className="dropdown"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													style={{ padding: '0', float: 'right' }}

												><div className="btn btn-primary"><i className={`fas fa-gear`} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div></div><div
													className="dropdown-menu "
													x-placement="bottom-start"
													style={{
														position: "absolute",
														transform: "translate3d(488px, -50px, 0px)",
														top: "0px",
														left: "-110px",
														willChange: "transform",

													}}
												><div className="form-group mb-0 tw-p-2 " style={{ marginTop: -10 }}>
														<input type="number" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={(e) => (setvarSetting({ ...varSetting, RTC: parseFloat(e.target.value) }))} value={varSetting?.RTC} />
													</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onHandalSearchClick} /></div>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_instantaneous_monitor_graph', 'CREATED_AT', 'RTC Time Difference') }}>Expand</a>
												<div class="dropdown d-inline mr-2">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[19].CARD_NAME)
															setcard_id(card_json[19].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[19].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>


											</div>
										</div>
									</div>
									<div className="tw-p-4">
										<div className="tw-flex tw-justify-around tw-items-center tw-mb-2">
											<div className="tw-text-center">
												<p className="tw-text-2xl tw-font-bold tw-flex tw-items-center tw-justify-center !tw-mb-2 tw-cursor-pointer" onClick={() => onHandalGsmChartRTC('INSTANTANEOUS_SUCCESS_LIST', BATRTC?.SRNO, false, moment.utc(BATRTC?.CREATED_AT).format("YYYY-MM-DD"), 'gsm_instantaneous_monitor_graph', `RTC Time(>${varSetting?.RTC})`, 1, card_json[19]?.U_ID)}>
													<TbClockExclamation className={`tw-mr-2 tw-text-red-500`} />
													{BATRTC?.RTC_SUCCESS}
												</p>
												<p className="tw-text-sm tw-text-gray-500">Difference Greater than {varSetting?.RTC}</p>
											</div>
											<div className="tw-h-12 tw-w-px tw-bg-gray-200" />
											<div className="tw-text-center">
												<p className="tw-text-2xl tw-font-bold tw-flex tw-items-center tw-justify-center !tw-mb-2 tw-cursor-pointer" onClick={() => onHandalGsmChartRTC('INSTANTANEOUS_SUCCESS_LIST', BATRTC?.SRNO, false, moment.utc(BATRTC?.CREATED_AT).format("YYYY-MM-DD"), 'gsm_instantaneous_monitor_graph', `RTC Time(<${varSetting?.RTC})`, 0, card_json[19]?.U_ID)}>
													<IoMdTime  className={`tw-mr-2 tw-text-green-500`} />
													{BATRTC?.RTC_FAIL}
												</p>
												<p className="tw-text-sm tw-text-gray-500">Difference Less than {varSetting?.RTC}</p>
											</div>
										</div>
										<div className="tw-flex tw-items-center tw-text-sm tw-text-gray-500">
											<FaClock className="tw-mr-2" />
											<span><BootstrapTooltip title={BATRTC?.CREATED_AT ? moment.utc(BATRTC?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A") : ''}><span>{BATRTC?.CREATED_AT ? moment.utc(BATRTC?.CREATED_AT).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</span></BootstrapTooltip></span>
										</div>
									</div>
								</div> : ''}

								{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[20].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div className="tw-bg-white tw-rounded-lg tw-shadow-custom tw-overflow-hidden border-top !tw-border-t-2 !tw-border-t-red-600">
									<div className="tw-border-b tw-border-gray-200 tw-p-4">
										<div className="tw-flex tw-items-center tw-justify-between">
											<h2 className="tw-text-lg tw-font-semibold tw-text-gray-800">{card_json[20].CARD_NAME}</h2>
											<div className="tw-flex tw-space-x-2">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[20].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<div class="dropdown d-inline">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[20].CARD_NAME)
															setcard_id(card_json[20].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[20].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>

											</div>
										</div>
									</div>
									<div className="tw-p-4">
										<div className="tw-flex tw-justify-around tw-items-center tw-mb-2">
											<div className="tw-text-center">
												<p className="tw-text-2xl tw-font-bold tw-flex tw-items-center tw-justify-center !tw-mb-2 tw-cursor-pointer" onClick={() => GetConsumerIncolumeName(false, "TAMPER_TS", 'Tamper Today Count', `DATE(TAMPER_TS)='${moment().utcOffset("+05:30").format("YYYY-MM-DD")}' AND (JSON_VALUE(ALERT_DISABLE,'$.TAMPER')=1 OR ALERT_DISABLE IS NULL)`)}>
													<LuAlertTriangle className={`tw-mr-2 tw-text-red-500`} />
													{TAMPER_COUNT?.total}
												</p>
												<p className="tw-text-sm tw-text-gray-500">Today Count</p>
											</div>
											<div className="tw-h-12 tw-w-px tw-bg-gray-200" />
											<div className="tw-text-center">
												<p className="tw-text-2xl tw-font-bold tw-flex tw-items-center tw-justify-center !tw-mb-2 tw-cursor-pointer" onClick={() => GetConsumerIncolumeName(false, "TAMPER_TS", 'Tamper Disable Count', `(JSON_VALUE(ALERT_DISABLE,'$.TAMPER')=0)`)}>
													<TbAlertTriangleOff  className={`tw-mr-2 tw-text-gray-500`} />
													{TAMPER_COUNT?.tampper_disable}
												</p>
												<p className="tw-text-sm tw-text-gray-500">Disable Count</p>
											</div>
										</div>
										<div className="tw-flex tw-items-center tw-text-sm tw-text-gray-500">
										&#10240;
										</div>
									</div>
								</div> : ''}
							</div>
						</div>
						{/* {JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[18].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-4">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[18].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_instantaneous_monitor_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[18].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<div
													className="dropdown"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													style={{ padding: '0', float: 'right' }}

												><div className="btn btn-primary"><i className={`fas fa-gear`} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div></div><div
													className="dropdown-menu "
													x-placement="bottom-start"
													style={{
														position: "absolute",
														transform: "translate3d(488px, -50px, 0px)",
														top: "0px",
														left: "-110px",
														willChange: "transform",
													}}
												><div className="form-group mb-0 tw-p-2 " style={{ marginTop: -10 }}>
														<input type="number" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={(e) => (setvarSetting({ ...varSetting, BAT: parseFloat(e.target.value) }))} value={varSetting?.BAT} />
													</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onHandalSearchClick} /></div>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_instantaneous_monitor_graph', 'CREATED_AT', 'Battery Percentage') }} >Expand</a>

												<div class="dropdown d-inline mr-2">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft " x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[18].CARD_NAME)
															setcard_id(card_json[18].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[18].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>
										<div class="card-body heartbeatspace " style={{ position: "relative" }}>
											<div className="d-flex tw-justify-around"><b>{`Battery Percentage(>${varSetting?.BAT})`}</b><b>{`Battery Percentage(<${varSetting?.BAT})`}</b></div>
											<div className="d-flex tw-justify-around text-center"><span onClick={() => onHandalGsmChartBATRTC('INSTANTANEOUS_SUCCESS_LIST', BATRTC?.SRNO, false, moment.utc(BATRTC?.CREATED_AT).format("YYYY-MM-DD"), 'gsm_instantaneous_monitor_graph', 'Battery Percentage(>5)', 0, card_json[18]?.U_ID)}>{BATRTC?.BAT_SUCCESS}</span><span onClick={() => onHandalGsmChartBATRTC('INSTANTANEOUS_SUCCESS_LIST', BATRTC?.SRNO, false, moment.utc(BATRTC?.CREATED_AT).format("YYYY-MM-DD"), 'gsm_instantaneous_monitor_graph', 'Battery Percentage(>5)', 1, card_json[18]?.U_ID)}>{BATRTC?.BAT_FAIL}</span></div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}><BootstrapTooltip title={BATRTC?.CREATED_AT ? moment.utc(BATRTC?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A") : ''}><span>{BATRTC?.CREATED_AT ? moment.utc(BATRTC?.CREATED_AT).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</span></BootstrapTooltip></p>

									</div>
								</div>
							</div>
						</div> : ""}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[19].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-4">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[19].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_instantaneous_monitor_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[19].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<div
													className="dropdown"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													style={{ padding: '0', float: 'right' }}

												><div className="btn btn-primary"><i className={`fas fa-gear`} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div></div><div
													className="dropdown-menu "
													x-placement="bottom-start"
													style={{
														position: "absolute",
														transform: "translate3d(488px, -50px, 0px)",
														top: "0px",
														left: "-110px",
														willChange: "transform",

													}}
												><div className="form-group mb-0 tw-p-2 " style={{ marginTop: -10 }}>
														<input type="number" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={(e) => (setvarSetting({ ...varSetting, RTC: parseFloat(e.target.value) }))} value={varSetting?.RTC} />
													</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onHandalSearchClick} /></div>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_instantaneous_monitor_graph', 'CREATED_AT', 'RTC Time Difference') }}>Expand</a>
												<div class="dropdown d-inline mr-2">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[19].CARD_NAME)
															setcard_id(card_json[19].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[19].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>

										</div>
										<div class="card-body heartbeatspace " style={{ position: "relative" }}>
											<div className="d-flex tw-justify-around"><b>{`RTC Time(>${varSetting?.RTC})`}</b><b>{`RTC Time(<${varSetting?.RTC})`}</b></div>
											<div className="d-flex tw-justify-around text-center" onClick={onHandalSearchClick}><span onClick={() => onHandalGsmChartRTC('INSTANTANEOUS_SUCCESS_LIST', BATRTC?.SRNO, false, moment.utc(BATRTC?.CREATED_AT).format("YYYY-MM-DD"), 'gsm_instantaneous_monitor_graph', `RTC Time(>${varSetting?.RTC})`, 1, card_json[19]?.U_ID)}>{BATRTC?.RTC_SUCCESS}</span><span onClick={() => onHandalGsmChartRTC('INSTANTANEOUS_SUCCESS_LIST', BATRTC?.SRNO, false, moment.utc(BATRTC?.CREATED_AT).format("YYYY-MM-DD"), 'gsm_instantaneous_monitor_graph', `RTC Time(<${varSetting?.RTC})`, 0, card_json[19]?.U_ID)}>{BATRTC?.RTC_FAIL}</span></div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}><BootstrapTooltip title={BATRTC?.CREATED_AT ? moment.utc(BATRTC?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A") : ''}><span>{BATRTC?.CREATED_AT ? moment.utc(BATRTC?.CREATED_AT).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</span></BootstrapTooltip></p>

									</div>
								</div>
							</div>
						</div> : ""}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[20].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-4">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[20].CARD_NAME} </span><BootstrapTooltip title='Find Data In gsm_consumer_device_index  Coumn TAMPER_TS' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[20].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<div class="dropdown d-inline">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[20].CARD_NAME)
															setcard_id(card_json[20].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[20].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>
										<div class="card-body heartbeatspace " style={{ position: "relative" }}>
											<div className="d-flex tw-justify-around"><b>Today Count</b><b>Disable Count</b></div>
											<div className="d-flex tw-justify-around text-center" ><span onClick={() => GetConsumerIncolumeName(false, "TAMPER_TS", 'Tamper Today Count', `DATE(TAMPER_TS)='${moment().utcOffset("+05:30").format("YYYY-MM-DD")}' AND (JSON_VALUE(ALERT_DISABLE,'$.TAMPER')=1 OR ALERT_DISABLE IS NULL)`)} style={{ cursor: 'pointer' }}>{TAMPER_COUNT?.total}</span>
												<span onClick={() => GetConsumerIncolumeName(false, "TAMPER_TS", 'Tamper Disable Count', `(JSON_VALUE(ALERT_DISABLE,'$.TAMPER')=0)`)} style={{ cursor: 'pointer' }}>{TAMPER_COUNT?.tampper_disable}</span></div>

										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>&#10240;</p>

									</div>
								</div>
							</div>

						</div> : ''} */}

						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[21].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[21].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[21].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												{activeTab == 'Custom Dashboard' ? <select class='mr-2' onChange={(e) => { onchangeDefaultLoadData(e, card_json[21].U_ID) }} value={FilterDay?.filter(item => item?.card_id == card_json[21]?.U_ID)[0]?.day_preference || '7'}><option value='7'>7 Day</option><option value='10'>10 Day</option><option value='15'>15 Day</option></select> : ''}
												<select class='mr-2' onChange={(e) => { setchartType({ ...chartType, [card_json[21].U_ID]: e.target.value }) }}><option value='bar'>Bar Chart</option><option value='line'>Line Chart</option></select>
												<span className="mr-2">{Status == 'Pending' ? 'Script Running' : Status ? moment.utc(Status).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</span>
												<a class="btn btn-primary text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Refresh" onClick={onMonitoringScriptStatus}><i class="fas fa-refresh" aria-hidden="true"></i></a>
												<a class="btn btn-warning text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Run" onClick={onMeterDailyDataScripUpdate}>Run</a>

												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_monitor_day_wise_graph', 'CREATED_AT', 'GSM EVC/MC Meter Daily Data Count') }}>Expand</a>
												{/* <button class="btn btn-info mr-2" onClick={() => (setModel(true))}>
													<i class="fa fa-download" aria-hidden="true"></i>
												</button> */}
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD21.current, card_json[21].CARD_NAME, type) }} newMenu={[{ label: "Generate Report", event: () => setModel(true) }]} />
												<div class="dropdown d-inline ml-2">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[21].CARD_NAME)
															setcard_id(card_json[21].U_ID)
															setCardBar([{ name: 'DailyData Success', val: 'DailyData_Success' }, { name: 'DailyData Fail', val: 'DailyData_Fail' }, { name: 'Inactive', val: 'Inactive' }, { name: 'Under Maintance', val: 'Under_Maintance' }, { name: 'Pending Configuration', val: 'Pending_Configuration' }])
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[21].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " >

											<div style={{ position: "relative", height: "395px" }} ref={IMGCARD21}>
												{AllGSMChartData.daily_fail.length > 0 && AllGSMChartData.daily_success.length > 0 ? chartType[card_json[21].U_ID] == 'line' ? <Line options={{ ...optionsForChartJS, interaction: { mode: chartType[card_json[21].U_ID] == 'line' ? 'index' : 'nearest', intersect: false } }} data={dataForChartJs} /> : <Bar ref={chartRef} onClick={onClick} options={optionsForChartJS} data={dataForChartJs} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>:
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>{chartType[card_json[21].U_ID] == 'line' ? 'Line Char' : 'Bar chart'}</p>
									</div>
								</div>
							</div>
						</div> : ''}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[22].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[22].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_instantaneous_monitor_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[22].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												{activeTab == 'Custom Dashboard' ? <select class='mr-2' onChange={(e) => { onchangeDefaultLoadData(e, card_json[22].U_ID) }} value={FilterDay?.filter(item => item?.card_id == card_json[0]?.U_ID)[0]?.day_preference || '7'}><option value='7'>7 Day</option><option value='10'>10 Day</option><option value='15'>15 Day</option></select> : ''}
												<select class='mr-2' onChange={(e) => { setchartType({ ...chartType, [card_json[22].U_ID]: e.target.value }) }}><option value='bar'>Bar Chart</option><option value='line'>Line Chart</option></select>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_instantaneous_monitor_graph', 'CREATED_AT', 'GSM EVC/MC Meter instantaneous Count') }} >Expand</a>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD22.current, card_json[22].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[22].CARD_NAME)
															setcard_id(card_json[22].U_ID)
															setCardBar([{ name: 'Instantaneous Success', val: 'Instantaneous_Success' }, { name: 'Instantaneous Fail', val: 'Instantaneous_Fail' }])
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[22].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " >

											<div style={{ position: "relative", height: "395px" }} ref={IMGCARD22}>
												{InstantaneousChartData?.instantaneous_success?.length > 0 && InstantaneousChartData?.instantaneous_fail?.length > 0 ? chartType[card_json[22].U_ID] == 'line' ? <Line options={{ ...optionsINS, interaction: { mode: chartType[card_json[22].U_ID] == 'line' ? 'index' : 'nearest', intersect: false } }} data={dataForINS} /> : <Bar ref={chartRefINS} onClick={onClickIns} options={optionsINS} data={dataForINS} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>{chartType[card_json[22].U_ID] == 'line' ? 'Line Char' : 'Bar chart'}</p>
									</div>
								</div>
							</div>
						</div> : ''}


						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[23].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[23].CARD_NAME}*</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[23].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												{activeTab == 'Custom Dashboard' ? <select class='mr-2' onChange={(e) => { onchangeDefaultLoadData(e, card_json[23].U_ID) }} value={FilterDay?.filter(item => item?.card_id == card_json[23]?.U_ID)[0]?.day_preference || '7'}><option value='7'>7 Day</option><option value='10'>10 Day</option><option value='15'>15 Day</option></select> : ''}
												<select class='mr-2' onChange={(e) => { setchartType({ ...chartType, [card_json[23].U_ID]: e.target.value }) }}><option value='bar'>Bar Chart</option><option value='line'>Line Chart</option></select>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandConsumptionButton('gsm_monitor_day_wise_graph', 'CREATED_AT', 'GSM EVC/MC Meter Consumption') }}>Expand</a>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD23.current, card_json[23].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[23].CARD_NAME)
															setcard_id(card_json[23].U_ID)
															setCardBar([{ name: 'Consumption', val: 'Consumption' }, { name: '0 Consumption', val: 'Consumption0' }, { name: 'Null Data', val: 'Null Data' }])
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[23].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " >

											<div style={{ position: "relative", height: "395px" }} ref={IMGCARD23}>
												{ConsumptionChartData?.date?.length > 0 ? chartType[card_json[23].U_ID] == 'line' ? <Line options={{ ...optionsForChartJS, interaction: { mode: chartType[card_json[23].U_ID] == 'line' ? 'index' : 'nearest', intersect: false } }} data={ConsumptionChartJs} /> : <Bar ref={chartConsumptionRef} onClick={onClickStatiConsumption} options={optionsForChartJS} data={ConsumptionChartJs} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>{chartType[card_json[23].U_ID] == 'line' ? 'Line Char' : 'Bar chart'}</p>
									</div>
								</div>
							</div>
						</div> : ''}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[31].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[31].CARD_NAME}*</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[31].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												{activeTab == 'Custom Dashboard' ? <select class='mr-2' onChange={(e) => { onchangeDefaultLoadData(e, card_json[31].U_ID) }} value={FilterDay?.filter(item => item?.card_id == card_json[31]?.U_ID)[0]?.day_preference || '7'}><option value='7'>7 Day</option><option value='10'>10 Day</option><option value='15'>15 Day</option></select> : ''}
												<select class='mr-2' onChange={(e) => { setchartType({ ...chartType, [card_json[31].U_ID]: e.target.value }) }}><option value='bar'>Bar Chart</option><option value='line'>Line Chart</option></select>
												<span className="mr-2">{HourlyStatus == 'Pending' ? 'Script Running' : HourlyStatus ? moment.utc(HourlyStatus).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</span>
												<a class="btn btn-primary text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Refresh" onClick={onHourlyMonitoringScriptStatus}><i class="fas fa-refresh" aria-hidden="true"></i></a>
												<a class="btn btn-warning text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Run" onClick={onMeterHourlyDataScripUpdate}>Run</a>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandConsumptionButton('gsm_monitor_hourly_wise_graph', 'CREATED_AT', 'GSM EVC/MC Hourly Meter Consumption') }}>Expand</a>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD31.current, card_json[31].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[31].CARD_NAME)
															setcard_id(card_json[31].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[31].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " >

											<div style={{ position: "relative", height: "395px" }} ref={IMGCARD31}>
												{!HourConsumptionData?.loader ? HourConsumptionData?.date?.length == 0 ? <div className="d-flex" style={{ justifyContent: 'center' }}>Data Not Found</div> : chartType[card_json[31].U_ID] == 'line' ? <Line options={{ ...optionsForChartJS, interaction: { mode: chartType[card_json[31].U_ID] == 'line' ? 'index' : 'nearest', intersect: false } }} data={ConsumptionHourlyChartJs} /> : <Bar ref={HourlyConsumptionRef} options={optionsForChartJS} data={ConsumptionHourlyChartJs} onClick={onClickStaticHourlyConsumption} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>{chartType[card_json[31].U_ID] == 'line' ? 'Line Char' : 'Bar chart'}</p>
									</div>
								</div>
							</div>
						</div> : ''}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[24].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-6">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header">
											<h4><span className="mr-2">{card_json[24].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[24].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD24.current, card_json[24].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[24].CARD_NAME)
															setcard_id(card_json[24].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[24].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>

											</div>
										</div>

										<div class="card-body heartbeatspace " >
											<div class="tw-w-full">
												<div class="tw-flex  tw-items-center tw-justify-center">
													<button class="tw-bg-transparent tw-transition tw-border-none hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setDailyDataDate(moment(DailyDataDate, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'))
														onLoadDailyData({ start: moment(DailyDataDate, "DD-MM-YYYY").add(-1, 'days').format("YYYY-MM-DD"), end: moment(DailyDataDate, "DD-MM-YYYY").add(-1, 'days').format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ins_no: Profile.ins_no, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left">
															<path d="m15 18-6-6 6-6"></path>
														</svg>
													</button>
													<div class="tw-w-fit tw-px-3">
														<div class="tw-flex">
															<span class="tw-font-semibold"><DatePicker bordered={false} onChange={(date, dateString) => {
																setDailyDataDate(dateString)
																onLoadDailyData({ start: moment(dateString, "DD-MM-YYYY").add(-1, 'days').format("YYYY-MM-DD"), end: moment(dateString, "DD-MM-YYYY").add(-1, 'days').format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
															}} value={moment(DailyDataDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')} format='DD-MM-YYYY' /></span>
														</div>
													</div>
													<button class="tw-bg-transparent tw-border-none tw-transition hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setDailyDataDate(moment(DailyDataDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
														onLoadDailyData({ start: moment(DailyDataDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), end: moment(DailyDataDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right">
															<path d="m9 18 6-6-6-6"></path>
														</svg>
													</button>
													<div></div>
												</div>
											</div>

											<div ref={IMGCARD24} style={{ position: "relative", height: "395px" }}>
												{GSMChartData.daily_fail.length > 0 && GSMChartData.daily_success.length > 0 ? <Pie onClick={onClickDailyPie} options={optionsForChartJSPie} data={dataForChartJsPie} ref={pieDailydata} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>
										<div className="tw-flex tw-justify-between tw-px-3">
											<p>Total Count : {GSMChartData?.daily_success?.at(-1) + GSMChartData?.daily_fail?.at(-1) + GSMChartData?.inactive_count?.at(-1) + GSMChartData?.undermantan_count?.at(-1) + GSMChartData?.pendding_count?.at(-1)}  </p>
											<p>{'Pie chart'} </p>
										</div>
									</div>
								</div>
							</div>
						</div> : ''}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[25].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-6">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[25].CARD_NAME}*</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[25].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD25.current, card_json[25].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[25].CARD_NAME)
															setcard_id(card_json[25].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[25].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " >

											<div class="tw-w-full">
												<div class="tw-flex  tw-items-center tw-justify-center">
													<button class="tw-bg-transparent tw-transition tw-border-none hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setConsumptionDateanality(moment(ConsumptionDateanality, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'))
														onLoadConsumptionDataPieChart({ start: moment(ConsumptionDateanality, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), end: moment(ConsumptionDateanality, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left">
															<path d="m15 18-6-6 6-6"></path>
														</svg>
													</button>
													<div class="tw-w-fit tw-px-3">
														<div class="tw-flex">
															<span class="tw-font-semibold"><DatePicker bordered={false} onChange={(date, dateString) => {
																setConsumptionDateanality(dateString)
																onLoadConsumptionDataPieChart({ start: moment(dateString, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), end: moment(dateString, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
															}} value={moment(ConsumptionDateanality || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')} format='DD-MM-YYYY' /></span>
														</div>
													</div>
													<button class="tw-bg-transparent tw-border-none tw-transition hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setConsumptionDateanality(moment(ConsumptionDateanality, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
														onLoadConsumptionDataPieChart({ start: moment(ConsumptionDateanality, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), end: moment(ConsumptionDateanality, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right">
															<path d="m9 18 6-6-6-6"></path>
														</svg>
													</button>
													<div></div>
												</div>
											</div>

											<div ref={IMGCARD25} style={{ position: "relative", height: "395px" }}>
												{ConsumptionPieanality?.date?.length > 0 ? <Pie onClick={onClickDailyConsumptionPie} options={optionsForChartJSPie} data={ConsumptionChartJsPie} ref={pieDailyConsumptiondata} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>pie chart</p>
									</div>
								</div>
							</div>
						</div> : ""}

						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[30].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-6">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[30].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[30].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<div class="dropdown d-inline">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[30].CARD_NAME)
															setcard_id(card_json[30].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[30].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>
										<div className="card-body">
											<div class="tw-w-full">
												<div class="tw-flex  tw-items-center tw-justify-center">
													<button class="tw-bg-transparent tw-transition tw-border-none hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setsaleDate(moment(saleDate, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'))
														ongetSaleSummaryTableData({ start: moment(moment(saleDate, "DD-MM-YYYY").format('DD-MM-YYYY'), 'DD-MM-YYYY').add(-15, 'day').format('DD-MM-YYYY'), end: moment(saleDate, "DD-MM-YYYY").format('DD-MM-YYYY'), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left">
															<path d="m15 18-6-6 6-6"></path>
														</svg>
													</button>
													<div class="tw-w-fit tw-px-3">
														<div class="tw-flex">
															<span class="tw-font-semibold"><DatePicker bordered={false} onChange={(date, dateString) => {
																setsaleDate(dateString)
																ongetSaleSummaryTableData({ start: moment(moment(dateString).add(1, 'day').format('DD-MM-YYYY'), 'DD-MM-YYYY').add(-15, 'day').format('DD-MM-YYYY'), end: moment(dateString).add(1, 'day').format('DD-MM-YYYY'), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
															}} value={moment(saleDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')} format='DD-MM-YYYY' /></span>
														</div>
													</div>
													<button class="tw-bg-transparent tw-border-none tw-transition hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setsaleDate(moment(saleDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
														ongetSaleSummaryTableData({ start: moment(moment(saleDate, "DD-MM-YYYY").add(2, 'day').format('DD-MM-YYYY'), 'DD-MM-YYYY').add(-15, 'day').format('DD-MM-YYYY'), end: moment(saleDate, "DD-MM-YYYY").add(2, 'day').format('DD-MM-YYYY'), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right">
															<path d="m9 18 6-6-6-6"></path>
														</svg>
													</button>
													<div></div>
												</div>
											</div>
											{Object.keys(saleData).length > 0 ? <><div class="tw-flex tw-gap-2 tw-items-center tw-text-yellow-600 hover:tw-bg-yellow-50 hover:tw-rounded tw-border-b tw-p-2" style={{ borderBottomStyle: 'dashed' }}>

												<label class="m-0 tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70">Total AMR Connected (Count)</label>
												<div class="tw-ml-auto tw-bg-yellow-500 tw-text-white tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-sm tw-font-bold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1">{saleData?.total_consumer}</div>
											</div>
												<div class="tw-flex tw-gap-2 tw-items-center tw-text-blue-600 hover:tw-bg-blue-50 hover:tw-rounded tw-border-b tw-p-2" style={{ borderBottomStyle: 'dashed' }}>

													<label class="m-0 tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70">Daily Data Available (Count)</label>
													<div class="tw-ml-auto tw-bg-blue-500 tw-text-white tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-sm tw-font-bold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1">{saleData?.total_data_avilable}</div>
												</div>
												<div class="tw-flex tw-gap-2 tw-items-center tw-text-green-600 hover:tw-bg-green-50 hover:tw-rounded tw-border-b tw-p-2" style={{ borderBottomStyle: 'dashed' }}>

													<label class="m-0 tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70">Est. Sales (Last 15 Days Avg from Selected Date) (in SCM) </label>
													<div class="tw-ml-auto tw-bg-green-500 tw-text-white tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-sm tw-font-bold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1">{(saleData?.EST_sale / 15).toFixed(2)}</div>
												</div>
												<div class="tw-flex tw-gap-2 tw-items-center tw-text-indigo-600 hover:tw-bg-indigo-50 hover:tw-rounded tw-border-b tw-p-2" style={{ borderBottomStyle: 'dashed' }}>

													<label class="m-0 tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70">Actual Sale (on Selected Date) (in SCM)</label>
													<div class="tw-ml-auto tw-bg-indigo-500 tw-text-white tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-sm tw-font-bold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1">{parseFloat(saleData?.ctual_sale).toFixed(2)}</div>
												</div>
												<div class={`tw-flex tw-gap-2 tw-items-center ${(parseFloat(saleData?.ctual_sale).toFixed(2) - (saleData?.EST_sale / 15).toFixed(2)) / ((saleData?.EST_sale / 15).toFixed(2)) > 0 ? 'tw-text-green-600 hover:tw-bg-green-50' : 'tw-text-red-600 hover:tw-bg-red-50'} hover:tw-rounded tw-border-b tw-p-2`} style={{ borderBottomStyle: 'dashed' }}>

													<label class="m-0 tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70">Percentage of Est. Sale vs Actual Sale</label>
													<div class={`tw-ml-auto ${(parseFloat(saleData?.ctual_sale).toFixed(2) - (saleData?.EST_sale / 15).toFixed(2)) / ((saleData?.EST_sale / 15).toFixed(2)) > 0 ? 'tw-bg-green-600 tw-text-white' : 'tw-bg-red-600 tw-text-white'}  tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-sm tw-font-bold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1`}>{((parseFloat(saleData?.ctual_sale).toFixed(2) - (saleData?.EST_sale / 15).toFixed(2)) / ((saleData?.EST_sale / 15).toFixed(2))).toFixed(2)}%</div>
												</div></> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
										</div>
									</div>
								</div>
							</div>
						</div> : ''}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[32].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[32].CARD_NAME}*</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={onChangeTreeSelectInDataRece}
														treeCheckable={true}
														multiple
													/>
													<button class={`btn btn-danger mt-2 ${dataRecepLoader ? 'disabled' : ''}`} onClick={() => {
														ongetDataReceptionAnalyticsData({ start: moment(DataReceDate, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: CategoryData?.category0, CATEGORY1: CategoryData?.category1, CATEGORY2: CategoryData?.category2, CATEGORY3: CategoryData?.category3, CATEGORY4: CategoryData?.category4, CATEGORY5: CategoryData?.category5, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, ids: CategoryName?.ids })
													}}>Search</button>
												</>}>
													<div>
														<button class="btn btn-primary"> <i className="fas fa-filter"></i> </button>
													</div>
												</Popover>
												<div class="dropdown d-inline ml-2">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[32].CARD_NAME)
															setcard_id(card_json[32].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[30].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>
										<div className="card-body" style={{ height: '469px', overflow: 'auto' }}>
											<div class="tw-w-full">
												<div class="tw-flex  tw-items-center tw-justify-center">
													<button class="tw-bg-transparent tw-transition tw-border-none hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setDataReceDate(moment(DataReceDate, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'))
														ongetDataReceptionAnalyticsData({ start: moment(DataReceDate, "DD-MM-YYYY").add(-1, 'days').format("YYYY-MM-DD"), CATEGORY0: CategoryData?.category0, CATEGORY1: CategoryData?.category1, CATEGORY2: CategoryData?.category2, CATEGORY3: CategoryData?.category3, CATEGORY4: CategoryData?.category4, CATEGORY5: CategoryData?.category5, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, ids: CategoryName?.ids })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left">
															<path d="m15 18-6-6 6-6"></path>
														</svg>
													</button>
													<div class="tw-w-fit tw-px-3">
														<div class="tw-flex">
															<span class="tw-font-semibold"><DatePicker bordered={false} onChange={(date, dateString) => {
																setDataReceDate(dateString)
																ongetDataReceptionAnalyticsData({ start: moment(dateString, 'DD-MM-YYYY').add(1, 'days').format('YYYY-MM-DD'), CATEGORY0: CategoryData?.category0, CATEGORY1: CategoryData?.category1, CATEGORY2: CategoryData?.category2, CATEGORY3: CategoryData?.category3, CATEGORY4: CategoryData?.category4, CATEGORY5: CategoryData?.category5, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, ids: CategoryName?.ids })
															}} value={moment(DataReceDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')} format='DD-MM-YYYY' /></span>
														</div>
													</div>
													<button class="tw-bg-transparent tw-border-none tw-transition hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setDataReceDate(moment(DataReceDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
														ongetDataReceptionAnalyticsData({ start: moment(DataReceDate, 'DD-MM-YYYY').add(1, 'days').format('YYYY-MM-DD'), CATEGORY0: CategoryData?.category0, CATEGORY1: CategoryData?.category1, CATEGORY2: CategoryData?.category2, CATEGORY3: CategoryData?.category3, CATEGORY4: CategoryData?.category4, CATEGORY5: CategoryData?.category5, ROLES: userInfo?.ROLE, serach: true, ins_no: Profile.ins_no, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view }, ids: CategoryName?.ids })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right">
															<path d="m9 18 6-6-6-6"></path>
														</svg>
													</button>
													<div></div>
												</div>
											</div>
											{!dataRecepLoader ? Object.keys(CateDataRece || {}).length > 0 ? <div className="table-responsive">
												<div class="" id="lorawan_total_consumer_count">
													<table class="table table-borderless table-striped text-center">
														<thead>
															<tr style={{ position: 'sticky', top: '0', background: '#fff' }}>
																<th className="text-center" style={{ width: '350px' }}>Parant Categorys Name</th>
																<th className="text-center border" style={{ width: '250px' }}>Category Name</th>
																<th className="text-center ">Total Installation (till selected date)</th>
																<th className="text-center ">Active</th>
																<th className="text-center ">Other</th>
																<th className="text-center ">Daily Data Found</th>
																<th className="text-center ">% Of AMR</th>
																<th className="text-center">Data Sent to SAP</th>
																<th className="text-center">% Of API</th>
															</tr>
														</thead>
														<tbody>
															{Object.entries(CateDataRece || {})?.map(([key, value]) => (
																<tr>
																	<td>{CategorySearch?.tree_cat && CategorySearch?.tree_cat[CategorySearch?.ids?.findIndex((id) => key == id)]?.join(' -> ')}</td>
																	<td class='border'><h6>{CategorySearch?.name[CategorySearch?.ids?.findIndex((id) => key == id)]}</h6></td>
																	<td>{value?.daily_success + value?.daily_fail + value?.inactive_count + value?.undermantan_count + value?.pendding_count}</td>
																	<td>{value?.daily_success + value?.daily_fail}</td>
																	<td>{value?.inactive_count + value?.undermantan_count + value?.pendding_count}</td>
																	<td>{value?.daily_success}</td>
																	<td>{value?.daily_success ? (value?.daily_success / (value?.daily_success + value?.daily_fail) * 100).toFixed(2) : 0}%</td>
																	<td>-</td>
																	<td>-</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div> : CategoryName?.ids?.length > 0 ? <><h6 className='text-center mt-3'>No Data Found</h6></> : <><div className="text-center mt-3">Please Select Filter</div></> : <h6 className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></h6>}
										</div>
									</div>
								</div>
							</div>
						</div> : ''}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[26].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header">
											<h4><span className="mr-2">{card_json[26].CARD_NAME} *</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[26].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												<Popover trigger="click" content={<>
													<div class="form-group !tw-my-1 row" >
														<label for="firstcontectname0" class="col-sm-3 col-form-label">Consumption More Then:</label>
														<div class="col-sm-9">
															<input type="number" class="form-control" placeholder="First Name" id="installation_person_fname" name="installation_person_fname" onChange={(e) => (setValueCharCon({ ...ValueCharCon, isGreter: e.target.value.replace(/[\\"`\t\n]/g, "") }))} value={ValueCharCon.isGreter} />
														</div>
													</div>
													<div class="form-group !tw-my-1 row" >
														<label for="firstcontectname0" class="col-sm-3 col-form-label">Consumption Between:</label>
														<div class="col-sm-3">
															<input type="number" class="form-control" placeholder="From" id="installation_person_fname" name="installation_person_fname" onChange={(e) => (setValueCharCon({ ...ValueCharCon, Between1: { ...ValueCharCon.Between1, from: e.target.value.replace(/[\\"`\t\n]/g, "") } }))} value={ValueCharCon.Between1.from} />
														</div>
														<div class="col-sm-3">
															<input type="number" class="form-control" placeholder="To" id="installation_person_fname" name="installation_person_fname" onChange={(e) => (setValueCharCon({ ...ValueCharCon, Between1: { ...ValueCharCon.Between1, to: e.target.value.replace(/[\\"`\t\n]/g, "") } }))} value={ValueCharCon.Between1.to} />
														</div>
													</div>
													<div class="form-group !tw-my-1 row" >
														<label for="firstcontectname0" class="col-sm-3 col-form-label">Consumption Between :</label>
														<div class="col-sm-3">
															<input type="number" class="form-control" placeholder="From" id="installation_person_fname" name="installation_person_fname" onChange={(e) => (setValueCharCon({ ...ValueCharCon, Between2: { ...ValueCharCon.Between2, from: e.target.value.replace(/[\\"`\t\n]/g, "") } }))} value={ValueCharCon.Between2?.from} />
														</div>
														<div class="col-sm-3">
															<input type="number" class="form-control" placeholder="to" id="installation_person_fname" name="installation_person_fname" onChange={(e) => (setValueCharCon({ ...ValueCharCon, Between2: { ...ValueCharCon.Between2, to: e.target.value.replace(/[\\"`\t\n]/g, "") } }))} value={ValueCharCon.Between2.to} />
														</div>
													</div>
													<input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={() => onLoadConsumptionData({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })} />
												</>} title="">
													<div className="btn btn-primary mr-2"><i className={`fas fa-gear`} style={{ fontSize: '10px', cursor: 'pointer' }}></i></div>
												</Popover>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD26.current, card_json[26].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[26].CARD_NAME)
															setcard_id(card_json[26].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[26].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " style={{ position: "relative", height: "395px" }}>

											<div class="tw-w-full">
												<div class="tw-flex  tw-items-center tw-justify-center">
													<button class="tw-bg-transparent tw-transition tw-border-none hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setConsumptionDate(moment(ConsumptionDate, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'))
														onLoadConsumptionData({ start: moment(ConsumptionDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), end: moment(ConsumptionDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left">
															<path d="m15 18-6-6 6-6"></path>
														</svg>
													</button>
													<div class="tw-w-fit tw-px-3">
														<div class="tw-flex">
															<span class="tw-font-semibold"><DatePicker bordered={false} onChange={(date, dateString) => {
																setConsumptionDate(dateString)
																onLoadConsumptionData({ start: dateString ? moment(dateString, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'), end: dateString ? moment(dateString, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
															}} value={moment(ConsumptionDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')} format='DD-MM-YYYY' /></span>
														</div>
													</div>
													<button class="tw-bg-transparent tw-border-none tw-transition hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
														setConsumptionDate(moment(ConsumptionDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
														onLoadConsumptionData({ start: moment(ConsumptionDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), end: moment(ConsumptionDate, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, ins_no: Profile.ins_no, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
													}}>
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right">
															<path d="m9 18 6-6-6-6"></path>
														</svg>
													</button>
													<div></div>
												</div>
											</div>

											<div className="d-flex" ref={IMGCARD26}>
												<div className="col-6" style={{ height: '337px' }}>
													{ConsumptionPie?.date?.length > 0 ? <Pie onClick={onClickdailyConsmptionCategory} options={optionsForChartJSPie} data={ConanalitChartJsPie} ref={dailyConsmptionCategory} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
												</div>
												<div className="col-6" style={{ height: '337px' }}>
													{ConsumptionPie?.date?.length > 0 ? <Bar options={optionsForChartJS} data={ChartJsConsumptionCategory} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
												</div>
											</div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}></p>
									</div>
								</div>
							</div>
						</div> : ''}
						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[27].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[27].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[27].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												{activeTab == 'Custom Dashboard' ? <select class='mr-2' onChange={(e) => { onchangeDefaultLoadData(e, card_json[27].U_ID) }} value={FilterDay?.filter(item => item?.card_id == card_json[27]?.U_ID)[0]?.day_preference || '7'}><option value='7'>7 Day</option><option value='10'>10 Day</option><option value='15'>15 Day</option></select> : ''}
												<select class='mr-2' onChange={(e) => { setchartType({ ...chartType, [card_json[27].U_ID]: e.target.value }) }}><option value='bar'>Bar Chart</option><option value='line'>Line Chart</option></select>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandConsumptionButton('gsm_monitor_day_wise_graph', 'CREATED_AT', 'GSM EVC/MC Meter QMAX') }}>Expand</a>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD27.current, card_json[27].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[27].CARD_NAME)
															setcard_id(card_json[27].U_ID)
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[27].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " >

											<div style={{ position: "relative", height: "395px" }} ref={IMGCARD27}>
												{QChartData?.date?.length > 0 ? chartType[card_json[27].U_ID] == 'line' ? <Line options={{ ...optionsForChartJS, interaction: { mode: chartType[card_json[27].U_ID] == 'line' ? 'index' : 'nearest', intersect: false } }} data={QChartJs} /> : <Bar ref={chartQRef} onClick={onClickStatiCQ} options={optionsForChartJS} data={QChartJs} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>{chartType[card_json[27].U_ID] == 'line' ? 'Line Char' : 'Bar chart'}</p>
									</div>
								</div>
							</div>
						</div> : ''}

						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[28].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											{activeTab == 'Custom Dashboard' ? <select class='mr-2' onChange={(e) => { onchangeDefaultLoadData(e, card_json[28].U_ID) }} value={FilterDay?.filter(item => item?.card_id == card_json[28]?.U_ID)[0]?.day_preference || '7'}><option value='7'>7 Day</option><option value='10'>10 Day</option><option value='15'>15 Day</option></select> : ''}
											<h4><span className="mr-2">{card_json[28].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<select class='mr-2' onChange={(e) => { setchartType({ ...chartType, [card_json[28].U_ID]: e.target.value }) }}><option value='bar'>Bar Chart</option><option value='line'>Line Chart</option></select>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandConsumptionButton('gsm_monitor_day_wise_graph', 'CREATED_AT', 'GSM EVC/MC Meter QMIN') }}>Expand</a>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD28.current, card_json[28].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[28].CARD_NAME)
															setcard_id(card_json[28].U_ID)
															setCardBar([{ name: 'QMIN', val: 'QMIN' }, { name: '0 QMIN', val: '0 QMIN' }, { name: 'Null Data IN QMIN', val: 'Null Data IN QMIN' }])
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[28].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " >

											<div style={{ position: "relative", height: "395px" }} ref={IMGCARD28}>
												{QChartData?.date?.length > 0 ? chartType[card_json[28].U_ID] == 'line' ? <Line options={{ ...optionsForChartJS, interaction: { mode: chartType[card_json[28].U_ID] == 'line' ? 'index' : 'nearest', intersect: false } }} data={QMAXChartJs} /> : <Bar ref={chartQMINRef} onClick={onClickStatiCQMIN} options={optionsForChartJS} data={QMAXChartJs} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>{chartType[card_json[28].U_ID] == 'line' ? 'Line Char' : 'Bar chart'}</p>
									</div>
								</div>
							</div>
						</div> : ''}

						{JSON.parse(DashTabDetailById?.view_json || '{}')[card_json[29].U_ID]?.display == true || activeTab != 'Custom Dashboard' ? <div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">{card_json[29].CARD_NAME}</span><BootstrapTooltip title='Find Data In gsm_monitor_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action d-flex">
												<Popover trigger="click" placement="bottom" overlayStyle={{ width: '300px' }} content={<>
													<TreeSelect
														style={{ width: '100%' }}
														dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
														treeData={TreeSelection}
														placeholder="All Account"
														treeDefaultExpandAll
														allowClear
														onChange={(info) => onChangesCardWiseFilter(info, card_json[29].U_ID)}
													/>
												</>}>
													<span>
														<button class="btn btn-primary mr-2"> <i className="fas fa-filter"></i> </button>
													</span>
												</Popover>
												{activeTab == 'Custom Dashboard' ? <select class='mr-2' onChange={(e) => { onchangeDefaultLoadData(e, card_json[29].U_ID) }} value={FilterDay?.filter(item => item?.card_id == card_json[29]?.U_ID)[0]?.day_preference || '7'}><option value='7'>7 Day</option><option value='10'>10 Day</option><option value='15'>15 Day</option></select> : ''}
												<select class='mr-2' onChange={(e) => { setchartType({ ...chartType, [card_json[29].U_ID]: e.target.value }) }}><option value='bar'>Bar Chart</option><option value='line'>Line Chart</option></select>
												<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandConsumptionButton('gsm_monitor_day_wise_graph', 'CREATED_AT', 'GSM EVC/MC Meter QAVG') }}>Expand</a>
												<DownloadGraphImageComponet onClickDownloadImg={(type) => { onClickDownloadImg(IMGCARD29.current, card_json[29].CARD_NAME, type) }} />
												<div class="dropdown d-inline ml-1">
													<button class="btn btn-primary " type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<i className="fas fa-ellipsis-v" />
													</button>
													<div class="dropdown-menu dropleft" x-placement="left-start" style={{ position: "absolute", transform: 'translate3d(0px, 28px, 0px)', willChange: "transform" }}>
														{activeTab == 'Custom Dashboard' ? '' : permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard == true || userInfo?.ROLE == 'ROLE1650614500708' ? <a class="dropdown-item has-icon" onClick={() => {
															setisDashModal(true)
															setModal_card_name(card_json[29].CARD_NAME)
															setcard_id(card_json[29].U_ID)
															setCardBar([{ name: 'QAVG', val: 'QAVG' }, { name: '0 QAVG', val: '0 QAVG' }, { name: 'Null Data IN QAVG', val: 'Null Data IN QAVG' }])
														}}><i className="fas fa-plus " style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>Add Card</a> : ""}
														{activeTab == 'Custom Dashboard' ? <a class="dropdown-item has-icon" onClick={() => UpdateCustomDasboardInCardDelete(card_json[29].U_ID)}><i class="fa fa-trash "></i><span className='ml-2'>Remove Card</span></a> : ''}
													</div>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace ">

											<div style={{ position: "relative", height: "395px" }} ref={IMGCARD29}>
												{QChartData?.date?.length > 0 ? chartType[card_json[29].U_ID] == 'line' ? <Line options={{ ...optionsForChartJS, interaction: { mode: chartType[card_json[29].U_ID] == 'line' ? 'index' : 'nearest', intersect: false } }} data={QAVGChartJs} /> : <Bar ref={chartQAVGRef} onClick={onClickStatiCQAVG} options={optionsForChartJS} data={QAVGChartJs} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
											</div>
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>{chartType[card_json[29].U_ID] == 'line' ? 'Line Char' : 'Bar chart'}</p>
									</div>
								</div>
							</div>
						</div> : ''}

					</div>
				</div>
			</section >
		</>
	)
}