import React, { useEffect, useState } from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';

const DailyCron = (props) => {
   const [state, setState] = useState({hour: 0, minute: 0, every: false});

   useEffect(() => {
       setState({...state, every: props.value[3] !== '?'});
   }, []);

   const onDayChange = (e) => {
       if(props.disabled) { return }
       if(!e.target.value || (parseInt(e.target.value) > 0 && parseInt(e.target.value) < 32 )) {
           onValueChange(3, (e.target.value ? `1/${e.target.value}` : e.target.value));
       }
   }

   /**
    * If value is * return 0 else return value
    * @param {number} index - position in array
    * @returns {string}
    */
   const getValueByIndex = (index) => {
       return props.value[index] === '*' ? '0' : props.value[index];
   }

   const onAtHourChange = (e) => {
       if(props.disabled) { return }
       onValueChange(2, e.target.value);
   }

   const onAtMinuteChange = (e) => {
       if(props.disabled) { return }
       onValueChange(1, e.target.value);
   }

   const onValueChange = (cronPosition, value) => {
       let val = props.value;
       val[cronPosition] = value;
       props.onChange(val);
   }

   const translateFn = props.translate;
   
   return (
       <div className="tab-pane">
           <div className="well well-small">
               <input 
                   type="radio" 
                   onChange={(e) => { 
                       if (props.disabled) { return }
                       setState({ ...state, every: true }); 
                       props.onChange();
                   }} 
                   value="1" 
                   name="DailyRadio" 
                   checked={state.every} 
                   disabled={props.disabled} 
               />
               <span>{translateFn('Every')}</span>
               <input 
                   disabled={!state.every || props.disabled} 
                   type="Number" 
                   maxLength={2} 
                   onChange={onDayChange} 
                   value={props.value[3].split('/')[1] ? props.value[3].split('/')[1] : ''} 
               />
               <span>{translateFn('day(s)')}</span>
           </div>
           <div className="well well-small">
               <input 
                   onChange={(e) => {
                       if (props.disabled) { return }
                       setState({ ...state, every: false }); 
                       props.onChange(['0', props.value[1], props.value[2],'?','*', 'MON-FRI','*'])
                   }} 
                   type="radio" 
                   value="2" 
                   name="DailyRadio" 
                   checked={!state.every} 
                   disabled={props.disabled}
               />
               <span>{translateFn('Every week day')}</span>
           </div>
           <span>{translateFn('Start time')}</span>
           <Hour 
               onChange={onAtHourChange} 
               value={props.value[2]} 
               disabled={props.disabled}
           />
           <Minutes 
               onChange={onAtMinuteChange} 
               value={props.value[1]} 
               disabled={props.disabled}
           />
       </div>
   );
}

export default DailyCron;